import { Box, Grid, Hidden, Link, Typography, useTheme } from "@mui/material";
import LinkIcon from "../../../common/buttons/icons/LinkIcon";
import { Launch } from "@mui/icons-material";
import { ContactRow } from "../../../../data/Contact/models";
import { getStringFromOptionSet } from "../../../../data/common/fieldToString";
import { contactOptionSetsChoices } from "../../../../data/Contact/optionSetChoices";

type Props = {
  contact: ContactRow;
};

const AccountContactsCardItem = ({ contact }: Props) => {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={12}
      borderRadius={2}
      bgcolor="bg.light"
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginX={"10px"}
        paddingY={"6px"}
      >
        <Typography
          color={theme.palette.secondary.main}
          fontWeight={"600"}
          flexBasis={0}
          flexGrow={1}
        >
          {contact.firstname + " " + contact.lastname}
        </Typography>
        <Hidden smDown>
          <Typography
            color="text.secondary"
            fontWeight={"bold"}
            flexBasis={0}
            flexGrow={1}
          >
            {getStringFromOptionSet(contact.vdl_fonction, contactOptionSetsChoices.vdl_fonction)}
          </Typography>
        </Hidden>

        <LinkIcon
          icon={<Launch />}
          to={`/contacts/${contact.contactid}`}
          backgroundColor={"icon.bgLight"}
        />
      </Box>
    </Grid>
  );
};

export default AccountContactsCardItem;
