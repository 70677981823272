import { Box, Typography } from "@mui/material";
import { QueryError } from "../../../types";
import QueryErrorMessageInner from "./QueryErrorMessageInner";

type Props = {
  error?: QueryError | null | undefined;
};

const QueryErrorMessage = ({ error }: Props) => {

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap="8px"
      textAlign="center"
      boxSizing="border-box"
      padding="28px 20px"
    >
      <Box>
        <Typography variant="h4" color="primary" fontWeight="bold">
          Une erreur inattendue s'est produite.
        </Typography>
        <Typography variant="h5" color="primary" fontWeight="bold">
          Rafraichir la page pour réessayer.
        </Typography>
      </Box>
      <QueryErrorMessageInner error={error}/>
    </Box>
  );
};

export default QueryErrorMessage;
