import { Card, CardContent, Grid, Typography } from "@mui/material";
import { FieldNameValue, TableFields } from "../../../../types";
import DisplayField from "../DisplayField";
import CardTitle from "../CardTitle";

type Props = {
  title: string;
  displayFields: TableFields;
  icon?: JSX.Element;
  emptyTextPlaceHolder?: string;
};

const DataCard = ({ title, icon, displayFields, emptyTextPlaceHolder }: Props) => {
  return (
    <Card elevation={0} sx={{ marginBottom: 2 }}>
      <CardContent>
        <Grid container>
          <Grid item xs>
            <Grid item xs={12} minHeight={"45px"} paddingBottom={"5px"}>
              <CardTitle title={title} />
            </Grid>
            <Grid container spacing={"5px"}>
              {Object.entries(displayFields).map(([key, value]) => (
                <Grid item xs={12} md={6} key={key} sx={{minWidth: { xs: "250px", md: "300px"}}}>
                  <DisplayField
                    emptyPlaceHolder={emptyTextPlaceHolder}
                    displayName={value.displayName}
                    value={value.value??""}
                    fontSize="0.9rem"
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          {icon ? (
            <Grid item>
              {icon}
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DataCard;
