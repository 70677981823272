import React, { useEffect, useState } from "react";
import { NewDemandFields } from "../../../../data/Incident/forms/dialog";
import EditDemandLayout from "../EditDemandLayout";
import {
  EditDemandStandardEquipment,
  editDemandStandardEquipmentFields,
  editDemandStandardEquipmentLivraisonSection,
  editDemandStandardEquipmentRecuperationSection,
} from "../../../../data/Incident/forms/standardEquipment";
import { Box, styled } from "@mui/material";
import ProductsSection from "./Products/ProductsSection";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import { validateDateString } from "../../../../data/common/validateDateString";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import { buildFormObj } from "../../../form/SimpleForm/buildForm";
import {
  IncidentPatch,
  IncidentStandardEquipmentPatch,
  IncidentStandardEquipmentRow,
} from "../../../../data/Incident/models";
import { IncidentProductRowEdit } from "../../../../data/Incident/types/standardEquipment";
import { ApiKeyManager } from "@esri/arcgis-rest-request";
import { IGeocodeResponse, geocode } from "@esri/arcgis-rest-geocoding";
import handleSimpleFormSrollToError from "../../../form/SimpleForm/handleSimpleFormSubmitAndScroll";

const CardsLayout = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: "1rem",
  gridTemplateColumns: "1fr 1fr",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr",
  },
}));

type Props = {
  demand: IncidentStandardEquipmentRow;
  generalFields: NewDemandFields;
  update: (incident: IncidentPatch) => Promise<void>;
};

const StandardEquipment = ({ demand, generalFields, update }: Props) => {
  const [fieldsGeneral, setFieldsGeneral] =
    useState<NewDemandFields>(generalFields);
  const [fields, setFields] = useState<EditDemandStandardEquipment>(
    buildFormObj(editDemandStandardEquipmentFields, {
      vdl_adressecompletelivraison: {
        value: demand.vdl_detailsdelademande.vdl_adressecompletelivraison
          ? {
              text: demand.vdl_detailsdelademande.vdl_adressecompletelivraison,
              magicKey: "",
            }
          : null,
      },
      vdl_adressecompleterecuperation: {
        value: demand.vdl_detailsdelademande.vdl_adressecompleterecuperation
          ? {
              text: demand.vdl_detailsdelademande.vdl_adressecompleterecuperation,
              magicKey: "",
            }
          : null,
      },
      vdl_datedelivraison: {
        value: validateDateString(demand.vdl_detailsdelademande.vdl_datedelivraison),
      },
      vdl_datederecuperation: {
        value: validateDateString(demand.vdl_detailsdelademande.vdl_datederecuperation),
      },
      vdl_nomdubatimentlivraison: {
        value: demand.vdl_detailsdelademande.vdl_nomdubatimentlivraison ?? "",
      },
      vdl_nomdubatimentrecuperation: {
        value: demand.vdl_detailsdelademande.vdl_nomdubatimentrecuperation ?? "",
      },
      vdl_identiquealadressedelivraison: {
        value: demand.vdl_detailsdelademande.vdl_identiquealadressedelivraison ?? false,
      },
      vdl_secteurdeladressedelivraison: {
        value: demand.vdl_detailsdelademande.vdl_secteurdeladressedelivraison,
      },
      vdl_secteurdeladressederecuperation: {
        value: demand.vdl_detailsdelademande.vdl_secteurdeladressederecuperation,
      },
      vdl_horaire: { value: demand.vdl_detailsdelademande.vdl_horaire },
      vdl_horairerecuperation: { value: demand.vdl_detailsdelademande.vdl_horairerecuperation },
      vdl_remarqueoucommentaire: {
        value: demand.vdl_detailsdelademande.vdl_remarqueoucommentaire ?? "",
      },
      vdl_remarqueoucommentairerecuperation: {
        value: demand.vdl_detailsdelademande.vdl_remarqueoucommentairerecuperation ?? "",
      },
    })
  );
  const [demandProducts, setDemandProducts] = useState<
    IncidentProductRowEdit[]
  >(demand.vdl_detailsdelademande.products);
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      await handleSimpleFormSrollToError(errors[errors.length - 1]);
      return;
    }

    const _demand: IncidentStandardEquipmentPatch = {
      title: fieldsGeneral.title.value,
      _vdl_contactprincipal_value: fieldsGeneral.vdl_contactprincipal.value,
      idtypedeservice: demand.idtypedeservice,
      vdl_detailsdelademande: {
        vdl_identiquealadressedelivraison:
          fields.vdl_identiquealadressedelivraison.value,
        vdl_adressecompletelivraison:
          fields.vdl_adressecompletelivraison.value?.text,
        vdl_adressecompleterecuperation:
          fields.vdl_adressecompleterecuperation.value?.text,
        vdl_nomdubatimentrecuperation:
          fields.vdl_nomdubatimentrecuperation.value,
        vdl_nomdubatimentlivraison: fields.vdl_nomdubatimentlivraison.value,
        vdl_datedelivraison: fields.vdl_datedelivraison.value?.toISOString(),
        vdl_datederecuperation:
          fields.vdl_datederecuperation.value?.toISOString(),
        vdl_secteurdeladressedelivraison:
          fields.vdl_secteurdeladressedelivraison.value,
        vdl_remarqueoucommentaire: fields.vdl_remarqueoucommentaire.value,
        vdl_remarqueoucommentairerecuperation:
          fields.vdl_remarqueoucommentairerecuperation.value,
        vdl_horaire: fields.vdl_horaire.value,
        vdl_horairerecuperation:
          fields.vdl_horairerecuperation.value,
        products: [],
      },
    };

    const sameAddress = fields.vdl_identiquealadressedelivraison.value;

    if (fields.vdl_adressecompletelivraison.value) {
      if (
        demand.vdl_detailsdelademande.vdl_adressecompletelivraison !==
          fields.vdl_adressecompletelivraison.value.text ||
        (sameAddress &&
          demand.vdl_detailsdelademande.vdl_adressecompleterecuperation !==
            fields.vdl_adressecompletelivraison.value.text)
      ) {
        const res: IGeocodeResponse = await geocode({
          singleLine: fields.vdl_adressecompletelivraison.value.text,
          authentication: ApiKeyManager.fromKey(
            process.env.REACT_APP_ESRI_API_KEY ??
              "AAPK7c5fa65817c147d9a7ab69f8ed0a8d756PnvGTJR8fKlrIzP8zDo_aNO88cRPYdCcCQiuS1d1rweL8K-EjEErAi5Jf2gtV_-"
          ),
          outFields: "*",
        });

        if (res.candidates.length > 0 && res.candidates[0].location.x) {
          if (
            demand.vdl_detailsdelademande.vdl_adressecompletelivraison !==
            fields.vdl_adressecompletelivraison.value.text
          ) {
            _demand.vdl_detailsdelademande.vdl_adressecompletelivraison =
              fields.vdl_adressecompletelivraison.value.text;
            _demand.vdl_detailsdelademande.vdl_longitudelivraison =
              res.candidates[0].location.x;
            _demand.vdl_detailsdelademande.vdl_latitudelivraison =
              res.candidates[0].location.y;
          }

          if (
            sameAddress &&
            demand.vdl_detailsdelademande.vdl_adressecompleterecuperation !==
              fields.vdl_adressecompletelivraison.value.text
          ) {
            _demand.vdl_detailsdelademande.vdl_adressecompleterecuperation =
              fields.vdl_adressecompletelivraison.value.text;
            _demand.vdl_detailsdelademande.vdl_longituderecuperation =
              res.candidates[0].location.x;
            _demand.vdl_detailsdelademande.vdl_latituderecuperation =
              res.candidates[0].location.y;
          }
        }
      }
    }

    if (sameAddress) {
      _demand.vdl_detailsdelademande.vdl_nomdubatimentrecuperation =
        fields.vdl_nomdubatimentlivraison.value;
      _demand.vdl_detailsdelademande.vdl_secteurdeladressederecuperation =
        fields.vdl_secteurdeladressedelivraison.value;
    } else {
      _demand.vdl_detailsdelademande.vdl_secteurdeladressederecuperation =
        fields.vdl_secteurdeladressederecuperation.value;
      if (
        fields.vdl_adressecompleterecuperation.value &&
        demand.vdl_detailsdelademande.vdl_adressecompleterecuperation !==
          fields.vdl_adressecompleterecuperation.value.text
      ) {
        const res: IGeocodeResponse = await geocode({
          singleLine: fields.vdl_adressecompleterecuperation.value.text,
          authentication: ApiKeyManager.fromKey(
            process.env.REACT_APP_ESRI_API_KEY ??
              "AAPK7c5fa65817c147d9a7ab69f8ed0a8d756PnvGTJR8fKlrIzP8zDo_aNO88cRPYdCcCQiuS1d1rweL8K-EjEErAi5Jf2gtV_-"
          ),
          outFields: "*",
        });
        if (res.candidates.length > 0 && res.candidates[0].location.x) {
          _demand.vdl_detailsdelademande.vdl_adressecompleterecuperation =
            fields.vdl_adressecompleterecuperation.value.text;
          _demand.vdl_detailsdelademande.vdl_longituderecuperation =
            res.candidates[0].location.x;
          _demand.vdl_detailsdelademande.vdl_latituderecuperation =
            res.candidates[0].location.y;
        }
      }
    }

    _demand.vdl_detailsdelademande.products = demandProducts.map((p) => {
      if ("vdl_equipementdelademandeid" in p) {
        if ("delete" in p && p.delete === true) {
          return {
            vdl_equipementdelademandeid: p.vdl_equipementdelademandeid,
            delete: true,
          };
        }
        return {
          vdl_equipementdelademandeid: p.vdl_equipementdelademandeid,
          vdl_quantite: p.vdl_quantite,
        };
      }
      return {
        vdl_quantite: p.vdl_quantite,
        _vdl_produit_value: p.vdl_Produit.productid,
      };
    });

    await update(_demand);
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_typededemande.vdl_lienhautduformulaireportail}
      parentIncidentId={demand._parentcaseid_value}

    >
      <>
        <CardsLayout>
          <SimpleFields
            fields={fields}
            setFields={setFields}
            sections={[editDemandStandardEquipmentLivraisonSection]}
            sectionsBackground="background.paper"
            columns={{ count: 2, minWidth: 260 }}
          />
          <SimpleFields
            fields={fields}
            setFields={setFields}
            sections={[editDemandStandardEquipmentRecuperationSection]}
            sectionsBackground="background.paper"
            columns={{ count: 2, minWidth: 260 }}
          />
          <Box
            sx={{
              gridColumn: {
                lg: "span 2",
              },
            }}
            bgcolor="common.white"
            padding="16px"
          >
            <ProductsSection
              demandProducts={demandProducts}
              setDemandProducts={setDemandProducts}
              productTypes={
                demand.vdl_detailsdelademande._vdl_typedeproduit_value
              }
            />
          </Box>
        </CardsLayout>
      </>
    </EditDemandLayout>
  );
};

export default StandardEquipment;
