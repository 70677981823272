import React, { useEffect, useState } from "react";
import {
  IncidentPatch,
  IncidentSpecialEventPatch,
  IncidentSpecialEventRow,
} from "../../../../../data/Incident/models";
import { NewDemandFields } from "../../../../../data/Incident/forms/dialog";
import EditDemandLayout from "../../EditDemandLayout";
import SimpleFields from "../../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../../hooks/useTabs";
import {
  EditDemandSpecialEventsGeneralFields,
  editDemandSpecialEventsGeneralFields,
} from "../../../../../data/Incident/forms/specialEvents/specialEventsGeneral";
import {
  EditDemandSpecialEventFields,
  editDemandSpecialEventFields,
  editDemandSpecialEventSections,
} from "../../../../../data/Incident/forms/specialEvents/specialEvent/specialEvent";
import { validateDateString } from "../../../../../data/common/validateDateString";
import SpecialEvents from "../GeneralFields/SpecialEvents";
import { useSnackbar } from "../../../../../hooks/useSnackbar";
import {
  IncidentSpecialEventsDateEditing,
  IncidentSpecialEventsDatePatch,
} from "../../../../../data/Incident/types/specialEvents/dates";
import { buildForm } from "../../../../form/SimpleForm/buildForm";
import handleSimpleFormSrollToError from "../../../../form/SimpleForm/handleSimpleFormSubmitAndScroll";
import { parseAddress, buildAddress } from "../../../../../data/addressHelper";

type Props = {
  demand: IncidentSpecialEventRow;
  generalFields: NewDemandFields;
  update: (incident: IncidentPatch) => Promise<void>;
};

const SpecialEvent = ({ demand, generalFields, update }: Props) => {
  const details = demand.vdl_detailsdelademande;

  const [fieldsGeneral, setFieldsGeneral] =
    useState<NewDemandFields>(generalFields);

  const [specialEventFields, setSpecialEventFields] =
    useState<EditDemandSpecialEventsGeneralFields>(
      buildForm(editDemandSpecialEventsGeneralFields, {
        vdl_lieudelevenement: details.vdl_lieudelevenement
        ? {
            text: details.vdl_lieudelevenement,
            magicKey: "",
          }
        : null,
        vdl_precisionsurlelieu: details.vdl_precisionsurlelieu,
        vdl_siautreprecisezlieu: details.vdl_siautreprecisezlieu ?? "",
        vdl_encasdintemperieslevenementest:
          details.vdl_encasdintemperieslevenementest ?? [],
        vdl_siremisle: validateDateString(details.vdl_siremisle),
      })
    );

  const [fields, setFields] = useState<EditDemandSpecialEventFields>(
    buildForm(editDemandSpecialEventFields, {
      vdl_nomdelevenement: details.vdl_nomdelevenement ?? "",
      vdl_naturedelevenement: details.vdl_naturedelevenement ?? [],
      vdl_siautreprecisez: details.vdl_siautreprecisez ?? "",
      vdl_descriptiondelevenement: details.vdl_descriptiondelevenement ?? "",
      vdl_rayonnementdelevenement: details.vdl_rayonnementdelevenement ?? [],
      vdl_nouvelledemande: details.vdl_nouvelledemande ?? false,
      vdl_demanderecurrente: details.vdl_demanderecurrente ?? false,
      vdl_sireccurentdatedeladerniereedition: validateDateString(details.vdl_sireccurentdatedeladerniereedition),
      vdl_nombredeparticipantsetdevisiteursattendus: details.vdl_nombredeparticipantsetdevisiteursattendus,
      vdl_clienteleviseeparlevenement:
        details.vdl_clienteleviseeparlevenement ?? [],
      vdl_sispecifiqueprecisez: details.vdl_sispecifiqueprecisez ?? "",
      vdl_engagementloisetreglement:
        details.vdl_engagementloisetreglement ?? false,
      vdl_engagementequiteetinclusion:
        details.vdl_engagementequiteetinclusion ?? false,
      vdl_engagementdeveloppementdurable:
        details.vdl_engagementdeveloppementdurable ?? false,
      vdl_responsabilitedumaterielprete:
        details.vdl_responsabilitedumaterielprete ?? false,
      vdl_engagementhabitudessaines:
        details.vdl_engagementhabitudessaines ?? false,
    })
  );
  const [dates, setDates] = useState<IncidentSpecialEventsDateEditing[]>(
    details.dates
  );
  const { activeTab, changeTab } = useTabs();
  const { showSnackbar } = useSnackbar();

  const onSubmit = async () => {
    const errors = [
      ...handleSimpleFormSubmit(fields, setFields),
      ...handleSimpleFormSubmit(specialEventFields, setSpecialEventFields)
    ];
    
    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      await handleSimpleFormSrollToError(errors[errors.length - 1]);
      return;
    }

    const addressText = specialEventFields.vdl_lieudelevenement.value?.text;
    if (!addressText) {
      showSnackbar("L'adresse est invalide.", "error");
      return;
    }


    const parsedAddress = parseAddress(addressText);
    if (!parsedAddress) {
      showSnackbar("L'adresse est invalide.", "error");
      return;
    }

    console.log(parsedAddress, "parsedAddress");

    const _demand: IncidentSpecialEventPatch = {
      title: fieldsGeneral.title.value,
      _vdl_contactprincipal_value: fieldsGeneral.vdl_contactprincipal.value,
      idtypedeservice: demand.idtypedeservice,
      vdl_detailsdelademande: {

        vdl_lieudelevenement: addressText,

        vdl_rue1: parsedAddress.street,
        vdl_rue2: parsedAddress.apartment,
        vdl_ville: parsedAddress.city, 
        vdl_province: parsedAddress.province,
        vdl_codepostal: parsedAddress.postalCode,
        vdl_pays: parsedAddress.country,
  
        
        vdl_precisionsurlelieu: specialEventFields.vdl_precisionsurlelieu.value,
        vdl_siautreprecisezlieu:
          specialEventFields.vdl_siautreprecisezlieu.value,
        vdl_encasdintemperieslevenementest:
          specialEventFields.vdl_encasdintemperieslevenementest.value,
        vdl_siremisle: specialEventFields.vdl_siremisle.value?.toISOString(),

        vdl_nomdelevenement: fields.vdl_nomdelevenement.value,
        vdl_naturedelevenement: fields.vdl_naturedelevenement.value,
        vdl_siautreprecisez: fields.vdl_siautreprecisez.value,
        vdl_descriptiondelevenement: fields.vdl_descriptiondelevenement.value,
        vdl_rayonnementdelevenement: fields.vdl_rayonnementdelevenement.value,
        vdl_nouvelledemande: fields.vdl_nouvelledemande.value,
        vdl_demanderecurrente: fields.vdl_demanderecurrente.value,

        vdl_sireccurentdatedeladerniereedition:
          fields.vdl_sireccurentdatedeladerniereedition.value?.toISOString(),

        dates: [],

        vdl_nombredeparticipantsetdevisiteursattendus:
          fields.vdl_nombredeparticipantsetdevisiteursattendus.value,
        vdl_clienteleviseeparlevenement:
          fields.vdl_clienteleviseeparlevenement.value,
        vdl_sispecifiqueprecisez: fields.vdl_sispecifiqueprecisez.value,

        vdl_engagementloisetreglement:
          fields.vdl_engagementloisetreglement.value,
        vdl_engagementequiteetinclusion:
          fields.vdl_engagementequiteetinclusion.value,
        vdl_engagementdeveloppementdurable:
          fields.vdl_engagementdeveloppementdurable.value,
        vdl_responsabilitedumaterielprete:
          fields.vdl_responsabilitedumaterielprete.value,
        vdl_engagementhabitudessaines:
          fields.vdl_engagementhabitudessaines.value,
      },
    };

    const datesUpdate: IncidentSpecialEventsDatePatch[] = [];

    let validDatesCount = 0;

    dates.forEach((date) => {
      if ("vdl_datesid" in date) {
        if ("delete" in date) {
          datesUpdate.push({
            vdl_datesid: date.vdl_datesid,
            delete: true,
          });
        } else {
          validDatesCount++;
        }
      } else {
        validDatesCount++;
        datesUpdate.push({
          ...date,
        });
      }
    });

    if (validDatesCount === 0) {
      showSnackbar(
        "Veuillez entrer au moins une date avant d'enregistrer la demande",
        "error"
      );
      return;
    }

    _demand.vdl_detailsdelademande.dates = datesUpdate;
    console.log('Demand object:', _demand);
    await update(_demand);
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_typededemande.vdl_lienhautduformulaireportail}
      parentIncidentId={demand._parentcaseid_value}

    >
      <SpecialEvents
        fields={specialEventFields}
        setFields={setSpecialEventFields}
        dates={dates}
        setDates={setDates}
      />
      <SimpleFields
        fields={fields}
        setFields={setFields}
        sections={editDemandSpecialEventSections}
        sectionsBackground="background.paper"
      />
    </EditDemandLayout>
  );
};

export default SpecialEvent;
