import contactDisplayNames from "../displayNames";
import {
  CheckBoxInput,
  DropDownOptionSetInput,
  FormFields,
  FormSection,
  PhoneInput,
  TextInput,
  DropDownLookUpInput,
  RadioInput,
  OptionSetInput,
} from "../../../types";
import {
  vdl_accessPortailChoices,
  vdl_fonctionChoices,
  vdl_salutationsChoices,
} from "./optionSetChoices";
import { contactOptionSetsChoices } from "../optionSetChoices";

export type EditContactFields = {
  vdl_salutation: OptionSetInput;
  firstname: TextInput;
  lastname: TextInput;
  vdl_fonction: OptionSetInput;
  telephone1: PhoneInput;
  telephone2: PhoneInput;
  vdl_accesauportail: RadioInput;
  vdl_roledesecurite: DropDownLookUpInput;
};

const sectionInfos: FormSection<EditContactFields> = {
  fields: [
    "vdl_salutation",
    "firstname",
    "lastname",
    "telephone1",
    "telephone2",
    "vdl_fonction",
  ],
  title: "Informations personnelles",
};

const sectionAccess: FormSection<EditContactFields> = {
  fields: ["vdl_accesauportail", "vdl_roledesecurite"],
  title: "Accès au portail",
};

const editContactSections: FormSection<EditContactFields>[] = [
  sectionInfos,
  sectionAccess,
];

const editContactFields: EditContactFields = {
  vdl_salutation: {
    type: "options",
    error: "",
    value: null,
    choices: contactOptionSetsChoices.vdl_salutation,
    required: false,
    fillLine: true,
    label: contactDisplayNames.vdl_salutation,
  },
  firstname: {
    type: "text",
    value: "",
    error: "",
    required: true,
    label: contactDisplayNames.firstname,
  },
  lastname: {
    type: "text",
    value: "",
    error: "",
    required: true,
    label: contactDisplayNames.lastname,
  },
  vdl_fonction: {
    type: "options",
    value: null,
    error: "",
    choices: contactOptionSetsChoices.vdl_fonction,
    required: true,
    fillLine: true,
    label: contactDisplayNames.vdl_fonction,
  },
  telephone1: {
    type: "phone",
    value: "",
    error: "",
    required: true,
    label: contactDisplayNames.telephone1,
  },
  telephone2: {
    type: "phone",
    value: "",
    error: "",
    label: contactDisplayNames.telephone2,
  },
  vdl_accesauportail: {
    type: "radio",
    error: "",
    value: "",
    choices: vdl_accessPortailChoices,
    required: false,
    fillLine: true,
    label: contactDisplayNames.vdl_accesauportail,
    onChangeEvent: (fields) =>
      onAccessauportailChange(fields as EditContactFields),
  },
  vdl_roledesecurite: {
    type: "lookup",
    value: "",
    error: "",
    choices: [],
    label: contactDisplayNames._vdl_roledesecurite_value,
    requiredCondition: {
      validator: (fields: FormFields) =>
        fields.vdl_accesauportail.value === "Oui",
      error: "Vous devez donner un rôle à au contact s'il a accès au portail",
    },
    disabledCondition: (fields: FormFields) =>
      fields.vdl_accesauportail.value !== "Oui",
  },
};

function onAccessauportailChange(
  fields: EditContactFields
): Partial<EditContactFields> | undefined {
  try {
    if (fields.vdl_accesauportail.value === "Oui") return undefined;

    return {
      vdl_roledesecurite: {
        ...fields.vdl_roledesecurite,
        value: null,
      },
    };
  } catch (e) {
    return undefined;
  }
}

const editContactForm = {
  fields: editContactFields,
  sections: editContactSections,
};

export { editContactForm, editContactFields, editContactSections };
