import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Logo from "../../../../../common/images/laval-logo-no-text.svg";
import { dateStringToLongString } from "../../../../../../data/common/fieldToString";
import { HistoryRow } from "../../../../../../data/History/models";
import ActivityCardMessage from "./ActivityCardMessage";
import ActivityCardStatus from "./ActivityCardStatus";

type Props = {
  activity: HistoryRow;
};

const ActivityCard = ({ activity }: Props) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="auto 1fr"
      alignItems="center"
      columnGap="16px"
    >
      {activity.type === "message" && activity.vdl_viaportail ? (
        <AccountCircleIcon
          sx={{ width: "3rem", height: "3rem" }}
          color="primary"
        />
      ) : (
        <Box
          width="3rem"
          height="3rem"
          bgcolor="grey.300"
          borderRadius="50%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding="6px"
          marginY="4px"
        >
          <img
            src={Logo}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              height: "calc(3rem - 12px)",
            }}
            alt="Ville de Laval"
          />
        </Box>
      )}

      <Typography color="text.secondary" fontWeight="bold">
        {dateStringToLongString(activity.createdon)}
      </Typography>
      <Box display="flex" justifyContent="center" height="100%">
        <Box height="100%" width="2px" bgcolor="grey.300"></Box>
      </Box>

      <Box paddingBottom="32px" overflow="hidden">
        {activity.type === "message" ? (
          <ActivityCardMessage activity={activity} />
        ) : (
          <ActivityCardStatus activity={activity} />
        )}
      </Box>
    </Box>
  );
};

export default ActivityCard;
