import { Box } from "@mui/material";
import {
  IncidentParentRow,
  IncidentReferenceRow,
} from "../../../../data/Incident/models";
import DemandeCardNew from "../../Demands/DemandCard/DemandeCardNew";
import CustomButton from "../../../common/buttons/CustomButton";
import NewDemandDialog from "./NewDemandDialog";
import { useState } from "react";
import { ContactRow } from "../../../../data/Contact/models";
import { ServiceRow } from "../../../../data/Service/models";
import { ProgramRow } from "../../../../data/Program/models";
import { isIncidentEditable } from "../../../../data/Incident/rules";

type Props = {
  demand: IncidentParentRow;
  contacts: ContactRow[];
  programs: ProgramRow[];
};

const RegroupmentTab = ({ demand, contacts, programs }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      {isIncidentEditable(demand) ? (
        <>
          <NewDemandDialog
            open={dialogOpen}
            setOpen={setDialogOpen}
            contacts={contacts}
            programs={programs}
            demand={demand}
          />
          <Box display="flex" justifyContent="end">
            <CustomButton primary onClick={() => setDialogOpen(true)}>
              Ajouter
            </CustomButton>
          </Box>
        </>
      ) : null}

      {demand.incident_parent_incident.map((d, idx) => (
        <DemandeCardNew key={idx} demand={d} />
      ))}
    </Box>
  );
};

export default RegroupmentTab;
