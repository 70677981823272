import { Card, CardContent, Grid, Skeleton, Typography } from "@mui/material";

type Props = {
  displayFieldsCount: number;
  icon?: boolean;
};

const DataCardSkeleton = ({ icon, displayFieldsCount }: Props) => {

  return (
    <Card elevation={0} sx={{ marginBottom: 2 }}>
      <CardContent>
        <Grid container height={"45px"}>
          <Grid item xs maxHeight={"100%"}>
            <Skeleton
              variant="text"
              width={"50%"}
              sx={{ fontSize: "1.8rem" }}
            />
          </Grid>
          {icon ? (
            <Grid
              item
            >
              <Skeleton
                variant="circular"
                width={"calc(1.5rem + 16px)"}
                height={"calc(1.5rem + 16px)"}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid container spacing={"5px"}>
          {Array(displayFieldsCount)
            .fill(0)
            .map((field, i) => (
              <Grid item xs={5} key={i} minWidth={"300px"}>
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "0.9rem" }}
                  width={"30%"}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "0.9rem" }}
                  width={"50%"}
                />
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DataCardSkeleton;
