import React from "react";
import { Box, Typography } from "@mui/material";
import ProductCard from "./ProductCard";
import { IncidentStandardEquipmentProductRow } from "../../../../../../data/Incident/types/standardEquipment";

type Props = {
  products: IncidentStandardEquipmentProductRow[];
};

const ProductsSection = ({ products }: Props) => {
  return (
    <Box display="grid" gap="16px">
      <Typography variant="h6" fontWeight="bold" color="primary" paddingBottom="4px">
        Matériel
      </Typography>
      <Box display="grid" gap="16px">
        {products.length > 0 ? (
          products.map((product) => (
            <ProductCard key={product.vdl_Produit.productid} product={product} />
          ))
        ) : (
          <Box bgcolor="background.default" padding="16px">
            <Typography>Il n'y a aucun produit dans la demande</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProductsSection;
