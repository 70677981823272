import React from "react";
import { ProductRow } from "../../../../../../data/Product/models";
import { Box, Typography, styled, useTheme } from "@mui/material";
import ProductCard from "./ProductDialogCard/ProductDialogCard";
import { ProductDictionary } from "../ProductsSection";
import { IncidentProductRowNew } from "../../../../../../data/Incident/types/standardEquipment";

const CardsLayout = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: "1rem",
  gridTemplateColumns: "1fr 1fr",
  padding: "0 16px 0 16px",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr",
  },
}));

type Props = {
  category: string;
  products: ProductRow[];
  productDictionary: ProductDictionary;
  handleAddProduct: (product: IncidentProductRowNew) => void;
};

const ProductsCategory = ({
  category,
  products,
  productDictionary,
  handleAddProduct,
}: Props) => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap="16px" marginBottom="32px">
      <Box display="flex" alignItems="center" gap="12px">
        <Typography
          variant="h5"
          fontWeight="bold"
          maxWidth="70%"
          color={theme.palette.secondary.main}
        >
          {category}
        </Typography>
        <Box bgcolor={theme.palette.secondary.main} flexGrow={1} height="2px" />
      </Box>
      <CardsLayout>
        {products.map((product) => (
          <ProductCard
            key={product.productid}
            product={product}
            handleAddProduct={handleAddProduct}
            qtyInCart={productDictionary[product.productid]}
          />
        ))}
      </CardsLayout>
    </Box>
  );
};

export default ProductsCategory;
