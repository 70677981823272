import SectionLoading from "../components/common/loading/SectionLoading";
import { DropDownChoiceWithLabel, ErrorType, QueryError } from "../types";
import useUser from "../hooks/useUserData";
import { useSnackbar } from "../hooks/useSnackbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import { newDemandFields } from "../data/Incident/forms/dialog";
import InfoRequest from "../components/pageSpecific/EditDemand/InfoRequest/InfoRequest";
import { routes } from "../routes/routes";
import StandardEquipment from "../components/pageSpecific/EditDemand/StandardEquipment/StandardEquipment";

import { IncidentPatch, IncidentRow } from "../data/Incident/models";
import {
  Query,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { getIncident, updateIncident } from "../data/Incident/queries";
import { AccountRow } from "../data/Account/models";
import { getAccount } from "../data/Account/queries";
import { buildFormObj } from "../components/form/SimpleForm/buildForm";
import QueryErrorMessage from "../components/common/error/QueryErrorMessage";
import WebsiteDisplay from "../components/pageSpecific/EditDemand/WebsiteDisplay/WebsiteDisplay";
import TelevisionDisplay from "../components/pageSpecific/EditDemand/TelevisionDisplay/TelevisionDisplay";
import { Box } from "@mui/material";
import LoadingOverlay from "../components/common/loading/LoadingOverlay";
import ActivitiesCalendar from "../components/pageSpecific/EditDemand/ActivitiesCalendar/ActivitiesCalendar";
import Fundraiser from "../components/pageSpecific/EditDemand/Fundraiser/Fundraiser";
import Admissibility from "../components/pageSpecific/EditDemand/Admissibility/Admissibility";
import SpecialEvent from "../components/pageSpecific/EditDemand/SpecialEvents/SpecialEvent/SpecialEvent";
import Filming from "../components/pageSpecific/EditDemand/SpecialEvents/Filming/Filiming";
import Prisme from "../components/pageSpecific/EditDemand/Prisme/Prisme";
import ElectronicPanel from "../components/pageSpecific/EditDemand/ElectronicPanel/ElectronicPanel";
import PublicSpace from "../components/pageSpecific/EditDemand/PublicSpace/PublicSpace";

const EditDemand = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const demandQuery = useQuery<IncidentRow, QueryError>({
    queryKey: ["Demand", id],
    queryFn: () => getIncident(id),
  });

  const accountQuery = useQuery<AccountRow, QueryError>({
    queryKey: ["Account"],
    queryFn: () => getAccount(),
  });

  const mutation = useMutation<
    void,
    QueryError,
    { incidentid: string; incident: IncidentPatch }
  >({
    mutationFn: ({ incidentid, incident }) =>
      updateIncident(incidentid, incident),
    onSuccess: (data: void, variables) => {
      const redirectString = queryParams.get("redirect");
      const _parentcaseid_value = demandQuery.data?._parentcaseid_value;

      queryClient.invalidateQueries({ queryKey: ["Demand", id] });

      showSnackbar("Demande modifiée", "success");

      if (redirectString === "accueil") {
        navigate(`${routes.demands.path}`);
      } else {
        navigate(
          `${routes.demands.path}/${
            redirectString === "parent" && _parentcaseid_value
              ? _parentcaseid_value + "?tab=2"
              : id
          }`
        );
      }
    },
    onError: (error: QueryError) => {
      showErrorSnackbar(
        error.type === ErrorType.FORBIDDEN ||
          error.type === ErrorType.UNAUTHORIZED
          ? "Vous n'êtes pas autorisé à modifier cette demande."
          : "Erreur dans la modification de la demande.",
        "error",
        error
      );
    },
  });
  const isLoading = demandQuery.isFetching || accountQuery.isFetching;
  const error = demandQuery.error ?? accountQuery.error;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isNewDemand = queryParams.get("nouvelle") === "oui";
  const { userData } = useUser();
  const { showSnackbar, showErrorSnackbar } = useSnackbar();
  const navigate = useNavigate();

  if (isLoading)
    return (
      <PageTitleLayout title="Modifier demande">
        <SectionLoading />
      </PageTitleLayout>
    );

  if (error || !demandQuery.data || !accountQuery.data)
    return <QueryErrorMessage error={error} />;

  const update = async (incident: IncidentPatch) => {
    try {
      await mutation.mutateAsync({
        incidentid: demandQuery.data.incidentid,
        incident: incident,
      });
    } catch (error) {}
  };

  const renderDemandType = (demand: IncidentRow) => {
    let contactChoices: DropDownChoiceWithLabel[] = [];
    accountQuery.data.contacts.forEach((contact) => {
      if (contact.statecode === 0) {
        contactChoices.push({
          label: `${contact.firstname} ${contact.lastname}${
            contact.contactid === userData.contactid ? " (vous)" : ""
          }`,
          value: contact.contactid,
        });
      }
    });
    const general = buildFormObj(newDemandFields, {
      vdl_contactprincipal: {
        value: demandQuery.data._vdl_contactprincipal_value,
        choices: contactChoices,
      },
      title: {
        value: demandQuery.data.title,
      },
    });

    switch (demand.idtypedeservice) {
      case 1:
        return (
          <ActivitiesCalendar
            generalFields={general}
            demand={demand}
            update={update}
          />
        );
      case 2:
        return (
          <Fundraiser generalFields={general} demand={demand} update={update} />
        );
      case 3:
        return (
          <InfoRequest
            generalFields={general}
            demand={demand}
            update={update}
          />
        );
      case 4:
      case 24:
        return (
          <Admissibility
            generalFields={general}
            demand={demand}
            update={update}
          />
        );
      case 5:
      case 6:
        return (
          <StandardEquipment
            generalFields={general}
            demand={demand}
            update={update}
          />
        );
      case 7:
        return (
          <SpecialEvent
            generalFields={general}
            demand={demand}
            update={update}
          />
        );

      case 10:
        return (
          <PublicSpace
            generalFields={general}
            demand={demand}
            update={update}
          />
        );
      case 11:
        return (
          <ElectronicPanel
            generalFields={general}
            demand={demand}
            update={update}
          />
        );
      case 12:
        return (
          <Prisme generalFields={general} demand={demand} update={update} />
        );      
        case 13:
        return (
          <WebsiteDisplay
            generalFields={general}
            demand={demand}
            update={update}
          />
        );
      case 14:
        return (
          <TelevisionDisplay
            generalFields={general}
            demand={demand}
            update={update}
          />
        );
      case 15:
        return (
          <Filming generalFields={general} demand={demand} update={update} />
        );
      default:
        return null;
    }
  };

  return renderDemandType(demandQuery.data);
};

export default EditDemand;
