import {
  CheckBoxInput,
  CommentInput,
  DateInput,
  DropDownChoice,
  DropDownMultipleInput,
  DropDownOptionSetInput,
  FormFields,
  FormSection,
  MultilineInput,
  MultipleOptionSetInput,
  OptionSetInput,
  TextInput,
  TimeInput,
} from "../../../../../types";
import { specialEventDisplayNames } from "../../../displayNames";
import { incidentOptionSetsChoices } from "../../../optionSetChoices";

export type EditDemandSpecialEventFields = {
  vdl_nomdelevenement: TextInput;
  vdl_naturedelevenement: MultipleOptionSetInput;
  vdl_siautreprecisez: TextInput;
  vdl_descriptiondelevenement: MultilineInput;
  vdl_rayonnementdelevenement: MultipleOptionSetInput;
  vdl_nouvelledemande: CheckBoxInput;
  vdl_demanderecurrente: CheckBoxInput;
  vdl_sireccurentdatedeladerniereedition: DateInput;
  vdl_nombredeparticipantsetdevisiteursattendus: OptionSetInput;
  vdl_clienteleviseeparlevenement: MultipleOptionSetInput;
  vdl_sispecifiqueprecisez: TextInput;

  vdl_engagementloisetreglement: CheckBoxInput;
  vdl_engagementequiteetinclusion: CheckBoxInput;
  vdl_engagementdeveloppementdurable: CheckBoxInput;
  vdl_responsabilitedumaterielprete: CheckBoxInput;
  vdl_engagementhabitudessaines: CheckBoxInput;
};

const editDemandSpecialEventFields: EditDemandSpecialEventFields = {
  vdl_nomdelevenement: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: specialEventDisplayNames.vdl_nomdelevenement,
  },
  vdl_naturedelevenement: {
    type: "multipleoptions",
    error: "",
    value: [],
    required: true,
    fillLine: true,
    choices: incidentOptionSetsChoices.vdl_naturedelevenement,
    label: specialEventDisplayNames.vdl_naturedelevenement,
  },
  vdl_siautreprecisez: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    disabledCondition: (fields: FormFields) =>
      !(fields.vdl_naturedelevenement.value as number[]).includes(948300006), //Autre
    label: specialEventDisplayNames.vdl_siautreprecisez,
  },
  vdl_descriptiondelevenement: {
    type: "multiline",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    rows: { min: 4, max: 6 },
    maxLength: 3000,
    label: specialEventDisplayNames.vdl_descriptiondelevenement,
  },
  vdl_rayonnementdelevenement: {
    type: "multipleoptions",
    error: "",
    value: [],
    required: true,
    fillLine: true,
    choices: incidentOptionSetsChoices.vdl_rayonnementdelevenement,
    label: specialEventDisplayNames.vdl_rayonnementdelevenement,
  },
  vdl_nouvelledemande: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: specialEventDisplayNames.vdl_nouvelledemande,
  },
  vdl_demanderecurrente: {
    type: "checkbox",
    error: "",
    value: false,
    required: false,
    fillLine: true,
    label: specialEventDisplayNames.vdl_demanderecurrente,
    onChangeEvent: (fields: FormFields) => {
      try {
        const fields2 = <EditDemandSpecialEventFields>fields;
        if (!fields2.vdl_demanderecurrente.value) {
          return {
            vdl_sireccurentdatedeladerniereedition: {
              ...fields2.vdl_sireccurentdatedeladerniereedition,
              value: null,
            },
          };
        }
      } catch (error) {
        return undefined;
      }
    },
  },
  vdl_sireccurentdatedeladerniereedition: {
    type: "date",
    error: "",
    value: null,
    required: false,
    fillLine: true,
    disableFuture: true,
    disabledCondition: (fields: FormFields) =>
      !fields.vdl_demanderecurrente.value,
    label: specialEventDisplayNames.vdl_sireccurentdatedeladerniereedition,
  },
  vdl_nombredeparticipantsetdevisiteursattendus: {
    type: "options",
    error: "",
    value: null,
    required: true,
    fillLine: true,
    choices: incidentOptionSetsChoices.vdl_nombredeparticipantsetdevisiteursattendus,
    label:
      specialEventDisplayNames.vdl_nombredeparticipantsetdevisiteursattendus,
  },
  vdl_clienteleviseeparlevenement: {
    type: "multipleoptions",
    error: "",
    value: [],
    required: true,
    fillLine: true,
    choices: incidentOptionSetsChoices.vdl_clienteleviseeparlevenement,
    label: specialEventDisplayNames.vdl_clienteleviseeparlevenement,
  },
  vdl_sispecifiqueprecisez: {
    type: "text",
    error: "",
    value: "",
    required: false,
    fillLine: true,
    disabledCondition: (fields: FormFields) =>
      !(fields.vdl_clienteleviseeparlevenement.value as number[]).includes(
        948300005 //"Clientèle spécifique"
      ),
    label: specialEventDisplayNames.vdl_sispecifiqueprecisez,
  },
  vdl_engagementloisetreglement: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: specialEventDisplayNames.vdl_engagementloisetreglement,
  },
  vdl_engagementequiteetinclusion: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: specialEventDisplayNames.vdl_engagementequiteetinclusion,
  },
  vdl_engagementdeveloppementdurable: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: specialEventDisplayNames.vdl_engagementdeveloppementdurable,
  },
  vdl_responsabilitedumaterielprete: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: specialEventDisplayNames.vdl_responsabilitedumaterielprete,
  },
  vdl_engagementhabitudessaines: {
    type: "checkbox",
    error: "",
    value: false,
    required: true,
    fillLine: true,
    label: specialEventDisplayNames.vdl_engagementhabitudessaines,
  },
};

const editDemandSpecialEventSection: FormSection<EditDemandSpecialEventFields> =
  {
    fields: [
      "vdl_nomdelevenement",
      "vdl_naturedelevenement",
      "vdl_siautreprecisez",
      "vdl_descriptiondelevenement",
      "vdl_rayonnementdelevenement",
      "vdl_nouvelledemande",

      "vdl_demanderecurrente",
      "vdl_sireccurentdatedeladerniereedition",
      "vdl_nombredeparticipantsetdevisiteursattendus",
      "vdl_clienteleviseeparlevenement",
      "vdl_sispecifiqueprecisez",
    ],
    title: "Informations sur l'évènement",
  };

const editDemandSpecialEventEngagementSection: FormSection<EditDemandSpecialEventFields> =
  {
    fields: [
      "vdl_engagementloisetreglement",
      "vdl_engagementequiteetinclusion",
      "vdl_engagementdeveloppementdurable",
      "vdl_responsabilitedumaterielprete",
      "vdl_engagementhabitudessaines",
    ],
    title: "Engagements de l'organisateur",
  };
const editDemandSpecialEventSections: FormSection<EditDemandSpecialEventFields>[] =
  [editDemandSpecialEventSection, editDemandSpecialEventEngagementSection];

export { editDemandSpecialEventFields, editDemandSpecialEventSections };
