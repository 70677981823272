import { Dayjs } from "dayjs";
import {
  AutoCompleteInput,
  CheckBoxInput,
  DateInput,
  DateTimeInput,
  DropDownChoice,
  DropDownOptionSetInput,
  FormFields,
  FormSection,
  MultilineInput,
  NumberInput,
  OptionSetInput,
  TextInput,
} from "../../../types";
import { demandDisplayNames, fundraiserDisplayNames } from "../displayNames";
import { incidentOptionSetsChoices } from "../optionSetChoices";

export type EditDemandFundraiserFields = {
  vdl_projetdefinancement: TextInput;
  vdl_nombredejeunesparticipants18ansetmoins: NumberInput;
  vdl_nombredadultesparticipants5min: NumberInput;
  vdl_erjour: DateInput;
  vdl_emejour: DateInput;
  vdl_3emejour: DateInput;
  vdl_4emejour: DateInput;
  vdl_5emejour: DateInput;
  vdl_chomedeycartier: OptionSetInput;
  vdl_jeanberauddanieljohnson: OptionSetInput;
  vdl_notredamelavatere: OptionSetInput;
  vdl_stmartinboisdeboulogne: OptionSetInput;
  vdl_engagementaurespectdesproceduresetdirectives: CheckBoxInput;
  vdl_jemengageasuivrelaformationobligatoire: CheckBoxInput;
  vdl_jaidejasuivilaformationobligatoireannee: DateInput;
};

const isNotSaturdaySunday = (date: Dayjs): boolean => {
  const day = date.day();
  return day !== 0 && day !== 6;
};
const isNotBetweenAprilOctober = (date: Dayjs): boolean => {
  const month = date.month() + 1;
  return month < 4 || month > 10;
};
const dateProps = {
  shouldDisabledMonth: isNotBetweenAprilOctober,
  shouldDisableDate: isNotSaturdaySunday,
  disablePast: true,
};

const editDemandFundraiser: EditDemandFundraiserFields = {
  vdl_projetdefinancement: {
    type: "text",
    error: "",
    value: "",
    required: true,
    maxLength: 500,
    label: fundraiserDisplayNames.vdl_projetdefinancement,
  },
  vdl_nombredejeunesparticipants18ansetmoins: {
    type: "number",
    error: "",
    value: null,
    required: true,
    helperText: "18 ans et moins",
    step: 1,
    max: 10000,
    min: 0,
    label: fundraiserDisplayNames.vdl_nombredejeunesparticipants18ansetmoins,
  },
  vdl_nombredadultesparticipants5min: {
    type: "number",
    error: "",
    value: null,
    required: true,
    helperText: "Minimum 5",
    step: 1,
    max: 10000,
    min: 5,
    label: fundraiserDisplayNames.vdl_nombredadultesparticipants5min,
  },
  vdl_erjour: {
    type: "date",
    error: "",
    value: null,
    required: true,
    ...dateProps,
    label: fundraiserDisplayNames.vdl_erjour,
  },
  vdl_emejour: {
    type: "date",
    error: "",
    value: null,
    required: true,
    ...dateProps,
    label: fundraiserDisplayNames.vdl_emejour,
  },
  vdl_3emejour: {
    type: "date",
    error: "",
    value: null,
    required: true,
    ...dateProps,
    label: fundraiserDisplayNames.vdl_3emejour,
  },
  vdl_4emejour: {
    type: "date",
    error: "",
    value: null,
    required: true,
    ...dateProps,
    label: fundraiserDisplayNames.vdl_4emejour,
  },
  vdl_5emejour: {
    type: "date",
    error: "",
    value: null,
    required: true,
    ...dateProps,
    label: fundraiserDisplayNames.vdl_5emejour,
  },
  vdl_stmartinboisdeboulogne: {
    type: "options",
    error: "",
    value: null,
    choices: incidentOptionSetsChoices.fundraiserNumberChoice,
    required: true,
    label: fundraiserDisplayNames.vdl_stmartinboisdeboulogne,
  },
  vdl_jeanberauddanieljohnson: {
    type: "options",
    error: "",
    value: null,
    choices: incidentOptionSetsChoices.fundraiserNumberChoice,
    required: true,
    label: fundraiserDisplayNames.vdl_jeanberauddanieljohnson,
  },
  vdl_chomedeycartier: {
    type: "options",
    error: "",
    value: null,
    choices: incidentOptionSetsChoices.fundraiserNumberChoice,
    required: true,
    label: fundraiserDisplayNames.vdl_chomedeycartier,
  },
  vdl_notredamelavatere: {
    type: "options",
    error: "",
    value: null,
    choices: incidentOptionSetsChoices.fundraiserNumberChoice,
    required: true,
    label: fundraiserDisplayNames.vdl_notredamelavatere,
  },
  vdl_engagementaurespectdesproceduresetdirectives: {
    type: "checkbox",
    error: "",
    value: false,
    fillLine: true,
    required: true,
    label:
      fundraiserDisplayNames.vdl_engagementaurespectdesproceduresetdirectives,
  },
  vdl_jemengageasuivrelaformationobligatoire: {
    type: "checkbox",
    error: "",
    value: false,
    onChangeEvent: onFormationChange,
    label: fundraiserDisplayNames.vdl_jemengageasuivrelaformationobligatoire,
  },
  vdl_jaidejasuivilaformationobligatoireannee: {
    type: "date",
    error: "",
    value: null,
    required: false,
    disableFuture: true,
    views: ["year"],
    format: "YYYY",
    disabledCondition: isAnneFormationDisabled,
    label: fundraiserDisplayNames.vdl_jaidejasuivilaformationobligatoireannee,
  },
};

function onFormationChange(fields: FormFields): FormFields | undefined {
  try {
    const _fields2 = <EditDemandFundraiserFields>fields;
    if (_fields2.vdl_jemengageasuivrelaformationobligatoire.value) {
      let _fields: Partial<EditDemandFundraiserFields> = {};
      _fields.vdl_jaidejasuivilaformationobligatoireannee = {
        ..._fields2.vdl_jaidejasuivilaformationobligatoireannee,
        value: null
      };
      return _fields;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
}

function isAnneFormationDisabled(fields: FormFields) {
  try {
    const _fields2 = <EditDemandFundraiserFields>fields;
    return _fields2.vdl_jemengageasuivrelaformationobligatoire.value;
  } catch (e) {
    return false;
  }
}

const editDemandFundraiserGeneralSection: FormSection<EditDemandFundraiserFields> = {
  fields: ["vdl_projetdefinancement"],
};

const editDemandFundraiserParticipantsSection: FormSection<EditDemandFundraiserFields> =
  {
    fields: [
      "vdl_nombredejeunesparticipants18ansetmoins",
      "vdl_nombredadultesparticipants5min",
    ],
    title: "Participants",
  };

const editDemandFundraiserCrossroadsSection: FormSection<EditDemandFundraiserFields> =
  {
    fields: [
      "vdl_stmartinboisdeboulogne",
      "vdl_jeanberauddanieljohnson",
      "vdl_chomedeycartier",
      "vdl_notredamelavatere",
    ],
    title: "Lieu de l'Intersection",
    description: "Identifier du 1er au 4e choix",
  };

const editDemandFundraiserDateSection: FormSection<EditDemandFundraiserFields> = {
  fields: [
    "vdl_erjour",
    "vdl_emejour",
    "vdl_3emejour",
    "vdl_4emejour",
    "vdl_5emejour",
  ],
  title: "Date de l'évènement",
  description:
    "Entre le début avril et la fin octobre, Samedi ou Dimanche. Inscrire 5 choix",
};

const editDemandFundraiserEngagementSection: FormSection<EditDemandFundraiserFields> =
  {
    fields: [
      "vdl_engagementaurespectdesproceduresetdirectives",
      "vdl_jemengageasuivrelaformationobligatoire",
      "vdl_jaidejasuivilaformationobligatoireannee",
    ],
    title: "Engagement",
  };

const editDemandFundraiserSections: FormSection<EditDemandFundraiserFields>[] = [
  editDemandFundraiserGeneralSection,
  editDemandFundraiserCrossroadsSection,
  editDemandFundraiserParticipantsSection,
  editDemandFundraiserDateSection,
  editDemandFundraiserEngagementSection,
];

export { editDemandFundraiser, editDemandFundraiserSections };
