import React, { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabContent } from "../../../types";
import TabPanel from "./TabPanel";
import { useSearchParams } from "react-router-dom";
import { AppDrawerContext } from "../AppDrawer/AppDrawer";

interface BasicTabsProps {
  tabs: TabContent[];
  scrollable?: boolean;
}

const BasicTabs: React.FC<BasicTabsProps> = ({ tabs, scrollable }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const drawerContext = useContext(AppDrawerContext);

  const drawerWidth = drawerContext?.width;

  const defaultTabString = searchParams.get("tab");
  const defaultTab = defaultTabString === null ? 0 : parseInt(defaultTabString) ?? 0;

  const [activeTab, setActiveTab] = React.useState(defaultTab);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      width="100%"
      sx={{
        maxWidth: {
          xs: "100vw",
          md: drawerWidth ? `calc(100vw - ${drawerWidth}px)` : "100vw",
        },
      }}
    >
      <Box bgcolor="common.white">
        <Tabs
          value={activeTab > tabs.length - 1 ? tabs.length - 1 : activeTab}
          onChange={handleChange}
          variant={scrollable ? "scrollable" : undefined}
          scrollButtons={scrollable}
          allowScrollButtonsMobile
          sx={{
            "& .MuiTab-root": {
              padding: "0px 5px",
              margin: "0px 10px",
              fontWeight: "regular",
              textTransform: "none",
              fontSize: "0.9rem",
            },

            "& .MuiTab-root.Mui-selected": {
              fontWeight: "bold",
            },

            "& .MuiTabs-indicator": {
              height: "3px",
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel
          key={index}
          value={activeTab > tabs.length - 1 ? tabs.length - 1 : activeTab}
          index={index}
        >
          {tab.component}
        </TabPanel>
      ))}
    </Box>
  );
};

export default BasicTabs;