import {
  Box,
  Card,
  CardContent,
  Collapse,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CustomLinkButton from "../../../common/buttons/CustomLinkButton";
import ClickableIcon from "../../../common/buttons/icons/ClickableIcon";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import HelpIcon from "@mui/icons-material/Help";
import { RouterRoutes } from "../../../../routes/routes";
import { ProgramRow } from "../../../../data/Program/models";
import CustomButton from "../../../common/buttons/CustomButton";
import { ServiceRow } from "../../../../data/Service/models";
import CustomExternalLinkButton from "../../../common/buttons/CustomExternalLinkButton";

type Props = {
  program: ProgramRow;
  handleTypeClick: (type: ServiceRow) => void;
};

const ProgramCard = ({ program, handleTypeClick }: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <Card elevation={0} sx={{ bgcolor: "white" }}>
      <CardContent>
        <Box display="flex" flexDirection="column" gap="16px">
          <Box
            display="grid"
            gridAutoFlow="column"
            justifyContent="flex-start"
            alignItems="center"
            rowGap="4px"
            columnGap="8px"
          >
            <ClickableIcon
              icon={
                open ? (
                  <RemoveIcon color="primary" />
                ) : (
                  <AddIcon color="primary" />
                )
              }
              handleClick={() => {
                setOpen(!open);
              }}
            />
            <Typography
              gridColumn="2"
              gridRow="1"
              variant="h5"
              fontWeight="bold"
            >
              {program.vdl_name}
            </Typography>
            <Box gridColumn="3" gridRow="1">
              <ClickableIcon
                size="small"
                icon={<HelpIcon />}
                handleClick={() => {}}
              />
            </Box>

            <Box
              gridColumn="2 / span 2"
              justifySelf="start"
              gridRow="2"
              bgcolor="bg.light"
              borderRadius="23px"
              paddingX="16px"
            >
              <Typography color="primary" fontSize="0.9rem">
                {program.vdl_motscles}&nbsp;
              </Typography>
            </Box>
          </Box>

          <Box
            height="2px"
            bgcolor="bg.light"
            width="100%"
          />
          <Collapse in={open}>
            <Box display="flex" flexDirection="column" gap="8px">
              <Typography
                fontWeight="500"
                sx={{ textWrap: "balance" }}
                paddingLeft="2px"
              >
                {program.vdl_descriptionduprogramme}
              </Typography>
              <Box display="flex" gap="8px" flexWrap="wrap">
                {program.services.map((service) => {
                  return service.vdl_urlderedirection ? (
                    <CustomExternalLinkButton
                      key={service.vdl_typedeserviceid}
                      primary
                      href={service.vdl_urlderedirection}
                      target="_blank"
                    >
                      {service.vdl_name}
                    </CustomExternalLinkButton>
                  ) : (
                    <CustomButton
                      onClick={() => handleTypeClick(service)}
                      key={service.vdl_typedeserviceid}
                      primary
                    >
                      {service.vdl_name}
                    </CustomButton>
                  );
                })}
              </Box>
            </Box>
          </Collapse>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProgramCard;
