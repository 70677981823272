import { Box } from "@mui/material";
import React, { useContext, useState } from "react";
import StepIndicator from "./StepIndicator";
import { AccountTypeRow } from "../../../data/AccountType/models";
import {
  buildFormObj,
  buildPartialFormObj,
} from "../../form/SimpleForm/buildForm";
import { newUserFields } from "../../../data/Contact/forms/newUser";
import SimpleFormWithSectionsWithState from "../../form/SimpleForm/SimpleFormWithSectionsWithState";
import SimpleFields from "../../form/SimpleForm/SimpleFields";
import { useMsal } from "@azure/msal-react";
import CustomButton from "../../common/buttons/CustomButton";
import handleSimpleFormSubmit from "../../form/SimpleForm/handleSimpleFormSubmit";
import Step3 from "./Step3";
import {
  newAccountFields,
  newAccountSections,
} from "../../../data/Account/forms/newAccount";
import { useMutation } from "@tanstack/react-query";
import { UpdateUserContext } from "../../../providers/UserProvider";
import { createUser } from "../../../data/Contact/queries";
import { ContactPost, CreateUser } from "../../../data/Contact/models";
import { AccountPost } from "../../../data/Account/models";
import { parseAddress } from "../../../data/addressHelper";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { ErrorType, QueryError } from "../../../types";

type Props = {
  accountTypes: AccountTypeRow[];
  email: string;
};

const Steps = ({ accountTypes, email }: Props) => {
  const { instance } = useMsal();
  const userContext = useContext(UpdateUserContext);
  const { showSnackbar, showErrorSnackbar } = useSnackbar();

  const [step, setStep] = useState(0);
  const [accountFields, setAccountFields] = useState(
    buildPartialFormObj(newAccountFields, {
      vdl_typedorganisation: {
        value: null,
        choices: accountTypes.map((type) => {
          return {
            label: type.vdl_typedorganisation,
            value: type.vdl_typeorganisationid,
          };
        }),
      },
    })
  );

  const [contactFields, setContactFields] = useState(
    buildPartialFormObj(newUserFields, {
      emailaddress1: {
        value: email,
        disabledCondition() {
          return true;
        },
      },
    })
  );

  const mutation = useMutation<string, QueryError, { contact: CreateUser }>({
    mutationFn: ({ contact }) => createUser(contact),
    onSuccess: async (data: string, variables) => {
      await userContext?.updateUser();showSnackbar("Votre organisation a été créée");
      
    },
    onError: (error: QueryError) => {
      showErrorSnackbar(
        "Erreur dans la création de l'organisation",
        "error",
        error
      );
    },
  });
  async function handleLeftButtonClick() {
    switch (step) {
      case 0:
        instance.logout();
        break;
      case 1:
        setStep(0);
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;
      case 2:
        setStep(1);
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;
    }
  }

  async function handleRightButtonClick() {
    switch (step) {
      case 0:
        const errors0 = handleSimpleFormSubmit(contactFields, setContactFields);
        if (errors0.length === 0) {
          setStep(1);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }

        break;
      case 1:
        const errors1 = handleSimpleFormSubmit(accountFields, setAccountFields);
        if (errors1.length === 0) {
          setStep(2);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        break;
      case 2:
        try {
          const _contact: ContactPost = {
            lastname: contactFields.lastname.value,
            firstname: contactFields.firstname.value,
            vdl_fonction: contactFields.vdl_fonction.value ?? 948300006,
            emailaddress1: contactFields.emailaddress1.value,
            telephone1: contactFields.telephone1.value,
            vdl_salutation: contactFields.vdl_salutation.value,
          };

          if (
            !accountFields.siegeSocialAddress.value ||
            (!accountFields.vdl_adresseidentique &&
              !accountFields.correspondanceAddress.value) ||
            !accountFields.vdl_typedorganisation.value ||
            !accountFields.name.value ||
            !accountFields.vdl_secteursiegesocial.value
          ) {
            showSnackbar("Certains champs sont manquants.", "warning");
            return;
          }
          const siegeSocialAddress = parseAddress(
            accountFields.siegeSocialAddress.value.text
          );
          const correspondanceAddress = parseAddress(
            accountFields.vdl_adresseidentique
              ? accountFields.siegeSocialAddress.value.text
              : accountFields.correspondanceAddress.value
              ? accountFields.correspondanceAddress.value.text
              : ""
          );

          if (!siegeSocialAddress || !correspondanceAddress) {
            showSnackbar("Erreur dans la récupération des adresses", "warning");
            return;
          }
          const _account: AccountPost = {
            name: accountFields.name.value,
            vdl_autresnomorganisme: accountFields.vdl_autresnomorganisme.value,
            _vdl_typedorganisation_value:
              accountFields.vdl_typedorganisation.value,
            telephone1: accountFields.telephone1.value,
            vdl_neq: accountFields.vdl_neq.value,
            vdl_secteursiegesocial: accountFields.vdl_secteursiegesocial.value,
            address1_city: siegeSocialAddress.city,
            address1_line1: siegeSocialAddress.street,
            address1_postalcode: siegeSocialAddress.postalCode,
            address1_stateorprovince: siegeSocialAddress.province,
            address1_country: siegeSocialAddress.country,
            address1_line2: siegeSocialAddress.apartment,
            vdl_adresseidentique: accountFields.vdl_adresseidentique.value,
            address2_city: correspondanceAddress.city,
            address2_country: correspondanceAddress.country,
            address2_line1: correspondanceAddress.street,
            address2_postalcode: correspondanceAddress.postalCode,
            address2_stateorprovince: correspondanceAddress.province,
            address2_line2: correspondanceAddress.apartment,
            websiteurl: accountFields.websiteurl.value,
            vdl_pagefacebook: accountFields.vdl_pagefacebook.value,
            emailaddress1: accountFields.emailaddress1.value,
          };

          mutation.mutate({
            contact: { contact: _contact, account: _account },
          });
        } catch (error) {
          showSnackbar("Erreur inconnue.", "error");
        }
        break;
    }
  }

  return (
    <Box
      marginTop="24px"
      width="100%"
      display="flex"
      alignItems="center"
      flexDirection="column"
      gap="16px"
    >
      <StepIndicator activeStep={step} />
      {step === 0 && (
        <SimpleFields
          setFields={setContactFields}
          fields={contactFields}
          columns={{ count: 2, minWidth: 260 }}
        />
      )}
      {step === 1 && (
        <SimpleFields
          setFields={setAccountFields}
          fields={accountFields}
          sections={newAccountSections}
          columns={{ count: 2, minWidth: 260 }}
        />
      )}
      {step === 2 && (
        <Step3 contactFields={contactFields} accountFields={accountFields} />
      )}
      <Box display="flex" gap="16px" marginTop="8px" >
        <CustomButton secondary onClick={handleLeftButtonClick} disabled={mutation.isPending}>
          {step === 0 ? "Annuler" : "Précédent"}
        </CustomButton>

        <CustomButton primary onClick={handleRightButtonClick} disabled={mutation.isPending}>
          {mutation.isPending ? "Création..." : step === 0 || step === 1 ? "Continuer" : "Terminer"}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default Steps;
