import { Box, Typography, styled } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ClickableIcon from "../../../../common/buttons/icons/ClickableIcon";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CustomTextField from "./CustomTextField";
import { IncidentProductRowWithError } from "../../../../../data/Incident/types/standardEquipment";

const CardLayout = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "grid",
  gap: "32px",
  gridTemplateColumns: "150px 2fr minmax(150px,1fr) 6.5rem",
  padding: "16px",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "150px 1fr",
  },
}));

type Props = {
  product: IncidentProductRowWithError;
  handleDeleteProduct: (product: IncidentProductRowWithError) => void;
  handleChangeProductQty: (productid: string, newQty: number) => void;
};

const ProductCard = ({
  product,
  handleDeleteProduct,
  handleChangeProductQty,
}: Props) => {
  const handleQtyChange = (newQty: number) => {
    handleChangeProductQty(product.vdl_Produit.productid, newQty);
  };

  return (
    <CardLayout>
      <Box
        maxHeight="220px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          component="img"
          src={"data:image/png;base64," + product.vdl_Produit.vdl_photo}
          sx={{ objectFit: "contain" }}
          maxWidth="100%"
          height="100%"
          maxHeight="100%"
          minWidth="0"
        />
      </Box>
      <Box>
        <Typography fontWeight="bold" fontSize="0.9rem">
          {product.vdl_Produit.name}
        </Typography>
        <Typography fontSize="0.9rem">Ref: {product.vdl_Produit.vdl_deserie}</Typography>
        <Typography fontSize="0.9rem">
          Description: {product.vdl_Produit.description}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <CustomTextField
          label="Quantité"
          error={product.error !== undefined}
          helperText={product.error}
          onQtyChange={handleQtyChange}
          inputProps={{ min: "1", max: "10000", step: "1" }}
          value={product.vdl_quantite}
          sx={{
            width: "150px",
          }}
        />
      </Box>
      <Box display="flex" gap="16px">
        <ClickableIcon
          backgroundColor="background.paper"
          icon={<InfoIcon />}
          handleClick={() => {}}
        />
        <ClickableIcon
          backgroundColor="background.paper"
          icon={<DeleteOutlineIcon />}
          handleClick={() => {
            handleDeleteProduct(product);
          }}
        />
      </Box>
    </CardLayout>
  );
};

export default ProductCard;
