import React, { useState } from "react";
import { NewDemandFields } from "../../../../data/Incident/forms/dialog";
import EditDemandLayout from "../EditDemandLayout";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import {
  EditDemandPrismeFields,
  editDemandPrismeFields,
  editDemandPrismeSections,
} from "../../../../data/Incident/forms/prisme";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import { validateDateString } from "../../../../data/common/validateDateString";
import { dynamicsFileToFileType } from "../../../../data/common/buildFile";
import { fileToBase64 } from "../../../../data/common/fileToBase64";
import {buildForm} from "../../../form/SimpleForm/buildForm";
import { IncidentPatch, IncidentPrismePatch, IncidentPrismeRow } from "../../../../data/Incident/models";
import handleSimpleFormSrollToError from "../../../form/SimpleForm/handleSimpleFormSubmitAndScroll";

type Props = {
  demand: IncidentPrismeRow;
  generalFields: NewDemandFields;
  update: (incident: IncidentPatch) => Promise<void>
};

const Prisme = ({
  demand,
  generalFields,
  update
}: Props) => {

  const [fieldsGeneral, setFieldsGeneral] =
  useState<NewDemandFields>(generalFields);
  
  const [fields, setFields] = useState<EditDemandPrismeFields>(
    buildForm(editDemandPrismeFields, {
      vdl_activiteapromouvoir: demand.vdl_detailsdelademande.vdl_activiteapromouvoir ?? "",
      vdl_nombredepanneaux: demand.vdl_detailsdelademande.vdl_nombredepanneaux??null,
      vdl_piecejointe: dynamicsFileToFileType(demand.vdl_detailsdelademande.vdl_piecejointe),
      vdl_datedelactivite: validateDateString(demand.vdl_detailsdelademande.vdl_datedelactivite),
      vdl_datededebutdelevenement: validateDateString(
        demand.vdl_detailsdelademande.vdl_datededebutdelevenement
      ),
      vdl_datedefindelevenement: validateDateString(
        demand.vdl_detailsdelademande.vdl_datedefindelevenement
      ),
      vdl_autoroute25bouldelaconcordeestsite3:
        demand.vdl_detailsdelademande.vdl_autoroute25bouldelaconcordeestsite3 ?? false,
      vdl_monteedumoulinleparcdumoulinsite10:
        demand.vdl_detailsdelademande.vdl_monteedumoulinleparcdumoulinsite10 ?? false,
      vdl_bouldeslaurentidesboulsaintmartinsite1:
        demand.vdl_detailsdelademande.vdl_bouldeslaurentidesboulsaintmartinsite1 ?? false,
      vdl_bouldeslaurentidesboulcartiersite2:
        demand.vdl_detailsdelademande.vdl_bouldeslaurentidesboulcartiersite2 ?? false,
      vdl_boullecorbusierbouldusouvenirsite8:
        demand.vdl_detailsdelademande.vdl_boullecorbusierbouldusouvenirsite8 ?? false,
      vdl_bouldelaconcordebouldelavenirsite9:
        demand.vdl_detailsdelademande.vdl_bouldelaconcordebouldelavenirsite9 ?? false,
      vdl_boulcurelabelleboullevesquesite4:
        demand.vdl_detailsdelademande.vdl_boulcurelabelleboullevesquesite4 ?? false,
      vdl_boularthursauvebouldagenaisouestsite6:
        demand.vdl_detailsdelademande.vdl_boularthursauvebouldagenaisouestsite6 ?? false,
      vdl_boulsamsonavjosaphatdemerssite11:
        demand.vdl_detailsdelademande.vdl_boulsamsonavjosaphatdemerssite11 ?? false,
      vdl_bouldeslaurentidesbouldagenaisestsite5:
        demand.vdl_detailsdelademande.vdl_bouldeslaurentidesbouldagenaisestsite5 ?? false,
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      await handleSimpleFormSrollToError(errors[errors.length - 1]);
      return;
    }

    const _demand: IncidentPrismePatch = {
      title: fieldsGeneral.title.value,
      _vdl_contactprincipal_value: fieldsGeneral.vdl_contactprincipal.value,
      idtypedeservice: demand.idtypedeservice,
      vdl_detailsdelademande: {
        vdl_activiteapromouvoir: fields.vdl_activiteapromouvoir.value,
        vdl_nombredepanneaux: fields.vdl_nombredepanneaux.value,
        vdl_datedelactivite: fields.vdl_datedelactivite.value?.toISOString(),
        vdl_datededebutdelevenement:
          fields.vdl_datededebutdelevenement.value?.toISOString(),
        vdl_datedefindelevenement:
          fields.vdl_datedefindelevenement.value?.toISOString(),
        vdl_autoroute25bouldelaconcordeestsite3:
          fields.vdl_autoroute25bouldelaconcordeestsite3.value,
        vdl_monteedumoulinleparcdumoulinsite10:
          fields.vdl_monteedumoulinleparcdumoulinsite10.value,
        vdl_bouldeslaurentidesboulsaintmartinsite1:
          fields.vdl_bouldeslaurentidesboulsaintmartinsite1.value,
        vdl_bouldeslaurentidesboulcartiersite2:
          fields.vdl_bouldeslaurentidesboulcartiersite2.value,
        vdl_boullecorbusierbouldusouvenirsite8:
          fields.vdl_boullecorbusierbouldusouvenirsite8.value,
        vdl_bouldelaconcordebouldelavenirsite9:
          fields.vdl_bouldelaconcordebouldelavenirsite9.value,
        vdl_boulcurelabelleboullevesquesite4:
          fields.vdl_boulcurelabelleboullevesquesite4.value,
        vdl_boularthursauvebouldagenaisouestsite6:
          fields.vdl_boularthursauvebouldagenaisouestsite6.value,
        vdl_boulsamsonavjosaphatdemerssite11:
          fields.vdl_boulsamsonavjosaphatdemerssite11.value,
        vdl_bouldeslaurentidesbouldagenaisestsite5:
          fields.vdl_bouldeslaurentidesbouldagenaisestsite5.value,
      },
    };
    if (fields.vdl_piecejointe.changed) {
      if (fields.vdl_piecejointe.value) {
        const pieceJointeToBase = await fileToBase64(
          fields.vdl_piecejointe.value
        );
        if (pieceJointeToBase) {
          _demand.vdl_detailsdelademande.vdl_piecejointe = {
            filename: fields.vdl_piecejointe.value.name,
            content: pieceJointeToBase,
          };
        }
      }
    }

    await update(_demand);
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_typededemande.vdl_lienhautduformulaireportail}
      parentIncidentId={demand._parentcaseid_value}

    >
      <SimpleFields
        updateRefs
        fields={fields}
        setFields={setFields}
        sectionsBackground="common.white"
        sections={editDemandPrismeSections}
        columns={{ count: 2, minWidth: 280 }}
      />
    </EditDemandLayout>
  );
};

export default Prisme;
