import { EntityOptionSetChoices } from "../types";
type GlobalEntityChoices = EntityOptionSetChoices<
  | "vdl_territoiresdesservisparlorganisme" | "vdl_domainedactivite" | "vdl_typedeclientele" | "vdl_secteursiegesocial"
>;

export const globalOptionSetsChoices: GlobalEntityChoices = {

    vdl_territoiresdesservisparlorganisme: {
    948300000: "Sec 1 (Duvernay, Saint-François, Saint-Vincent-de-Paul)",
    948300001: "Sec 2 (Laval-des-Rapides, Pont-Viau, Renaud-Coursol) ",
    948300002: "Sec 3 (Chomedey)",
    948300003:
      "Sec 4 (Fabreville-Ouest, Laval-Ouest, Laval-sur-le-Lac, Les Îles-Laal, Sainte-Dorothée)",
    948300004: "Sec 5 (Fabreville-Est, Sainte-Rose)",
    948300005: "Sec 6 ( Auteuil, Vimont)",
    948300006: "Régional (Partout à Laval)",
    948300007: "Ailleurs qu'à Laval (spécifiez)",
  },
  vdl_secteursiegesocial: {
    948300000: "Sec 1 (Duvernay, Saint-François, Saint-Vincent-de-Paul)",
    948300001: "Sec 2 (Laval-des-Rapides, Pont-Viau, Renaud-Coursol) ",
    948300002: "Sec 3 (Chomedey)",
    948300003:
      "Sec 4 (Fabreville-Ouest, Laval-Ouest, Laval-sur-le-Lac, Les Îles-Laal, Sainte-Dorothée)",
    948300004: "Sec 5 (Fabreville-Est, Sainte-Rose)",
    948300005: "Sec 6 ( Auteuil, Vimont)",
  },
  vdl_domainedactivite: {
    948300000: "Sport",
    948300001: "Loisirs",
    948300002: "Développement social",
    948300003: "Culture",
    948300004: "Plein-air et environnement",
    948300005: "Autre (précisez)",
  },
  vdl_typedeclientele : {
    948300000: "Individus seulement",
    948300001: "Organismes seulement",
    948300002: "Individus et organismes",
  }
};
