import { Box, Step, StepLabel, Stepper } from "@mui/material";
import React from "react";

const StepIndicator = ({ activeStep }: { activeStep: number }) => {
  const steps = ["Mes informations", "Mon organisation", "Validation"];
  return (
    <Box width="100%" maxWidth="600px">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              sx={{ ".MuiStepLabel-iconContainer svg": { fontSize: "2rem" } }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepIndicator;
