import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import {
  createTheme,
  ThemeProvider,
  Palette,
  PaletteOptions,
} from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { frFR } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from "@mui/x-date-pickers";

interface MuiPallete {
  icon: { bgLight: string; bgLighter: string };
  bg: {light: string, default: string}
}

declare module "@mui/material/styles" {
  interface Palette extends MuiPallete {}
  interface PaletteOptions extends MuiPallete {}
  interface Theme {
    status: {
      danger: string;
    };
    custom: {
      loading: {
        backgroundColor: string;
      };
      header: {
        backgroundColor: string;
        color: string;
        height: string;
      };
      drawer: {
        backgroundColor: string;
        color: string;
        iconColor: string;
      };
      appLayout: {
        backgroundColor: string;
      };
      icon: {
        backgroundColor: string;
        color: string;
      };
      simpleLink: {
        color: string;
      };
      text: {
        semiDark: string;
      };
      pageCardContainer: {
        padding: string;
        "@media (max-width:600px)": {
          padding: string;
        };
      };
    };
  }
  interface ThemeOptions extends Partial<Theme> {}
}

const theme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: { color: "#A81B8D" },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: "#A81B8D" },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: { whiteSpace: "pre-line" },
      },
    },
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    h4: {
      "@media (max-width:600px)": {
        fontSize: "1.9rem",
      },
    },
  },
  palette: {
    primary: {
      main: "#0656a5",
    },
    secondary: {
      main: "#222D65",
    },
    background: {
      default: "#e8e8e8",
    },
    bg: {
      light: "#F7F7F8",
      default: "#e8e8e8"
    },
    text: {
      primary: "rgba(0,0,0, 0.7)",
      secondary: "#5C434B"
    },
    icon: {
      bgLight: "#E6E7E8",
      bgLighter: "#F7F7F8",
    },
  },
  custom: {
    loading: {
      backgroundColor: "#E6E7E8",
    },
    header: {
      backgroundColor: "#E6E7E8",
      color: "#666666",
      height: "60px",
    },
    drawer: {
      backgroundColor: "#ffffff",
      color: "#222D65",
      iconColor: "#8DB7E1",
    },
    appLayout: {
      backgroundColor: "#F7F7F8",
    },
    icon: {
      backgroundColor: "#E6E7E8",
      color: "#5C434B",
    },
    simpleLink: {
      color: "#0656a5",
    },
    text: {
      semiDark: "#5C434B",
    },
    pageCardContainer: {
      padding: "20px",
      "@media (max-width:600px)": {
        padding: "10px",
      },
    },
  },
  //status: {
  //  danger: orange[500],
  //},
});

const Provider = ({ children }: IChildrenProps) => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}
      adapterLocale="fr"
      localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        {children}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default Provider;
