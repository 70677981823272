import { EntityOptionSetChoices } from "../../types";
type IncidentEntityChoices = EntityOptionSetChoices<
  | "statecode"
  | "statuscode"
  | "vdl_horaire"
  | "vdl_secteurdeladressedelivraison"
  | "vdl_nombredepanneaux"
  | "vdl_clientelesvisees"
  | "vdl_cout"
  | "fundraiserNumberChoice"
  | "vdl_admissibiliterenouvellement"
  | "vdl_clienteleviseeparlevenement"
  | "vdl_nombredeparticipantsetdevisiteursattendus"
  | "vdl_rayonnementdelevenement"
  | "vdl_naturedelevenement"
  | "vdl_encasdintemperieslevenementest"
  | "vdl_precisionsurlelieu"
  | "vdl_naturedutournage"
  | "vdl_titre"
>;

export const incidentOptionSetsChoices: IncidentEntityChoices = {
  statecode: {
    0: "Actif",
    1: "Résolu",
    2: "Annulé(e)",
  },
  statuscode: {
    1: "En cours",
    2: "Soumise",
    3: "Incomplète",
    4: "Recherche en cours",
    5: "Fermée",
    6: "Annulée",
    1000: "Informations fournies",
    2000: "Fusionné(e)",
    744610001: "En création",
    948300001: "En traitement",
    948300002: "Approuvée",
    948300003: "Refusée",
    948300004: "Inutilisée",
    948300005: "Conditionnel",
  },
  vdl_horaire: {
    948300000: "AM",
    948300001: "PM",
  },
  vdl_secteurdeladressedelivraison: {
    948300000: "Sec 1 (Duvernay, Saint-François, Saint-Vincent-de-Paul)",
    948300001: "Sec 2 (Laval-des-Rapides, Pont-Viau, Renaud-Coursol)",
    948300002: "Sec 3 (Chomedey)",
    948300003:
      "Sec 4 (Fabreville-Ouest, Laval-Ouest, Laval-sur-le-Lac, Les Îles-Laal, Sainte-Dorothée)",
    948300004: "Sec 5 (Fabreville-Est, Sainte-Rose)",
    948300005: "Sec 6 (Auteuil, Vimont)",
  },
  vdl_nombredepanneaux: {
    948300000: "1",
    948300001: "2",
    948300002: "3",
    948300003: "4",
    948300004: "5",
    948300005: "6",
    948300006: "7",
    948300007: "8",
    948300008: "9",
    948300009: "10",
  },
  vdl_clientelesvisees: {
    948300000: "Tous",
    948300001: "Familles",
    948300002: "Adolescents",
    948300003: "Aînés",
    948300004: "Personnes handicapées",
  },
  vdl_cout: {
    948300000: "Événement gratuit",
    948300001: "Événement payant",
  },
  fundraiserNumberChoice: {
    1: "1",
    2: "2",
    3: "3",
    4: "4"
  },
  vdl_admissibiliterenouvellement: {
    948300000: "Admissibilité",
    948300001: "Renouvellement",
  },
  vdl_clienteleviseeparlevenement: {
    948300000: "Enfants",
    948300001: "Adolescents",
    948300002: "Adultes",
    948300003: "Aînés",
    948300004: "Familles",
    948300005: "Clientèle spécifique"
  },
  vdl_nombredeparticipantsetdevisiteursattendus: {
    948300000: "1 à 50",
    948300001: "51 à 150",
    948300002: "151 à 300",
    948300003: "301 à 500",
    948300004: "501 à 1000",
    948300005: "1001 à 3000",
    948300006: "3001 à 5000",
    948300007: "5001 à 10 000",
    948300008: "10 000 et plus",
  },
  vdl_rayonnementdelevenement: {
    948300000: "Local",
    948300001: "Régional",
    948300002: "Provincial",
    948300003: "National",
  },
  vdl_naturedelevenement: {
    948300000: "Communautaire",
    948300001: "Sportif",
    948300002: "Loisir",
    948300003: "Culturel",
    948300004: "Commercial",
    948300005: "Institutionnel",
    948300006: "Autre",
  },
  vdl_encasdintemperieslevenementest: {
    948300000: "Maintenu",
    948300001: "Annulé",
    948300002: "Remis",
  },
  vdl_precisionsurlelieu: {
    948300000: "Plateau sportif extérieur",
    948300001: "Centre communautaire",
    948300002: "Aréna",
    948300003: "Stationnement",
    948300004: "Voie Publique",
    948300005: "Espace vert",
    948300006: "Autre",
  },
  vdl_naturedutournage: {
    948300000: "Long métrage",
    948300001: "Documentaire",
    948300002: "Court métrage",
    948300003: "Corporatif",
    948300004: "Télésérie",
    948300005: "Emission télé",
    948300006: "Vidéoclip",
    948300007: "Publicité télé",
    948300008: "Websérie",
    948300009: "Session Photo",
  }, 
  vdl_titre: {
    948300000: "Monsieur",
    948300001: "Madame",
  }
};
