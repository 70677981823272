import React from "react";
import {
  IconButton,
  IconButtonProps,
  SvgIconTypeMap,
  Tooltip,
  useTheme,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

type Props = {
  color?: string;
  backgroundColor?: string;
  tooltip?: string;
  icon: React.ReactElement;
  size?: "small" | "medium" | "large" | number | string;
  handleClick:
    | (() => void)
    | ((e: React.MouseEvent<HTMLButtonElement>) => void);
  disabled?: boolean;
  ariaLabel?: string;
  iconButtonProps?: IconButtonProps;
};

/**
 *
 * @param {string} color: color of the Icon / default: inherit
 * @param {String} backgroundColor: backgroundColor if roundedBackground is true / default: contrasts color
 * @param {React.ReactElement} icon: icon component
 * @param {string | number} size: MUI Icon size OR pixel size of Icon
 */
const ClickableIcon = ({
  color,
  backgroundColor,
  icon,
  tooltip,
  ariaLabel,
  disabled,
  handleClick,
  iconButtonProps,
  size,
}: Props) => {
  const iconColor = color;

  let pxSize: number | undefined;
  let stringSize: "small" | "medium" | "large" | undefined;
  let fontSize: string | undefined;

  if (typeof size === "number") {
    pxSize = size;
  } else if (size === "small" || size === "medium" || size === "large") {
    stringSize = size;
  } else if (size) {
    fontSize = size;
  }

  const base = (
    <IconButton
      aria-label={ariaLabel}
      onClick={handleClick}
      disabled={disabled}
      size={stringSize ? stringSize : undefined}
      {...(iconButtonProps && { ...iconButtonProps, sx: undefined })}
      sx={{
        fontSize: fontSize ? fontSize : undefined,
        backgroundColor: backgroundColor ? backgroundColor : undefined,
        color: iconColor ? iconColor : undefined,
        opacity: disabled ? "50%" : "100%",
        pointerEvents: "auto",
        "&.Mui-disabled": {
          backgroundColor: backgroundColor ? backgroundColor : undefined,
        },
        "&:hover": {
          backgroundColor: backgroundColor ? backgroundColor : undefined,
          opacity: "0.8",
        },
        ...iconButtonProps?.sx,
      }}
    >
      {pxSize
        ? React.cloneElement(icon, {
            fontSize: "inherit",
            sx: { width: pxSize, height: pxSize },
          })
        : React.cloneElement(icon, { fontSize: "inherit" })}
    </IconButton>
  );

  if (tooltip) {
    return <Tooltip title={tooltip}>{base}</Tooltip>;
  }

  return <>{base}</>;
};

export default ClickableIcon;
