import { AddressInput, CheckBoxInput, DropDownLookUpInput, EmailInput, FormFields, FormSection, OptionSetInput, PhoneInput, TextInput } from "../../../types";
import { globalOptionSetsChoices } from "../../globalOptionSetChoices";
import accountDisplayNames from "../displayNames";


export type NewAccountDisplayFields = {
  name: TextInput;
  vdl_autresnomorganisme: TextInput;
  vdl_typedorganisation: DropDownLookUpInput;
  vdl_neq: TextInput;
  telephone1: PhoneInput;
  emailaddress1: EmailInput;
  websiteurl: TextInput;
  vdl_pagefacebook: TextInput;

  vdl_secteursiegesocial: OptionSetInput;
  siegeSocialAddress: AddressInput; 

  vdl_adresseidentique: CheckBoxInput;
  correspondanceAddress: AddressInput; //TextInput;
};

const sectionGeneralInformations: FormSection<NewAccountDisplayFields> = {
  fields: [
    "name",
    "vdl_autresnomorganisme",
    "vdl_typedorganisation",
    "vdl_neq",
    "telephone1",
    "emailaddress1",
    "websiteurl",
    "vdl_pagefacebook",
  ],
  title: "Informations générales",
};

const sectionRegisteredAddress: FormSection<NewAccountDisplayFields> = {
  fields: ["vdl_secteursiegesocial", "siegeSocialAddress"],
  title: "Adresse du siège social",
};

const sectionMailingAddress: FormSection<NewAccountDisplayFields> = {
  fields: ["vdl_adresseidentique", "correspondanceAddress"],
  title: "Adresse de correspondance",
};

const newAccountSections: FormSection<NewAccountDisplayFields>[] = [
  sectionGeneralInformations,
  sectionRegisteredAddress,
  sectionMailingAddress,
];

const newAccountFields: NewAccountDisplayFields = {
  name: {
    type: "text",
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.name,
  },
  vdl_autresnomorganisme: {
    type: "text",
    value: "",
    error: "",
    required: false,
    label: accountDisplayNames.vdl_autresnomorganisme,
  },
  vdl_typedorganisation: {
    type: "lookup",
    value: "",
    error: "",
    choices: [],
    required: true,
    label: accountDisplayNames._vdl_typedorganisation_value,
  },
  vdl_neq: {
    type: "text",
    value: "",
    error: "",
    required: false,
    label: accountDisplayNames.vdl_neq,
  },
  telephone1: {
    type: "phone",
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.telephone1,
  },
  emailaddress1: {
    type: "email",
    value: "",
    error: "",
    required: true,
    label: accountDisplayNames.emailaddress1,
  },
  websiteurl: {
    type: "text",
    value: "",
    error: "",
    required: false,
    label: accountDisplayNames.websiteurl,
  },
  vdl_pagefacebook: {
    type: "text",
    value: "",
    error: "",
    required: false,
    label: accountDisplayNames.vdl_pagefacebook,
  },

  vdl_secteursiegesocial: {
    type: "options",
    error: "",
    value: null,
    choices: globalOptionSetsChoices.vdl_secteursiegesocial ,
    required: true,
    fillLine: true,
    label: accountDisplayNames.vdl_secteursiegesocial,
  },
  siegeSocialAddress: {
    type: "address", //"text",
    value: null, //"",
    error: "",
    required: true,
    fillLine: true,
    label: "Recherche d'adresse",
  },
  vdl_adresseidentique: {
    type: "checkbox",
    value: false,
    error: "",
    required: false,
    fillLine: true,
    label: accountDisplayNames.vdl_adresseidentique,
    onChangeEvent: onSameAddressChange,
  },
  correspondanceAddress: {
    type: "address", //"text",
    value: null, //"",
    error: "",
    required: false,
    fillLine: true,
    label: "Recherche d'adresse",
    disabledCondition: isSameAddress,
    requiredCondition: {
      validator: (fields) => !isSameAddress(fields),
      error: "Utiliser l'adresse du siège social ou entrer une addresse"
    }
  },
};

function isSameAddress(fields: FormFields): boolean {
  const editFields = fields as NewAccountDisplayFields;
  return editFields.vdl_adresseidentique.value;
}

function onSameAddressChange(fields: FormFields): FormFields | undefined {
  try {
    const addressFields = <NewAccountDisplayFields>fields;
    let _fields: Partial<NewAccountDisplayFields> = {};
    _fields.correspondanceAddress = {
      ...addressFields.correspondanceAddress,
      error: "",
      value: null,
    };

    return _fields;
  } catch (e) {
    return undefined;
  }
}

export { newAccountSections, newAccountFields };
