import { Box, Checkbox, Typography, useTheme } from "@mui/material";
import React from "react";
import { CheckBoxInput, FormField, SectionCheckBoxInput } from "../../../types";

type Props = {
  title?: string;
  description?: string;

};
const SimpleFormSectionTitle = ({ title, description }: Props) => {
  return (
    <Box flexBasis="100%" display="flex" flexWrap="wrap">
      <Box display="flex" alignItems="center" gap="8px">
        <Typography color="primary" variant="h5">
          {title}
        </Typography>
       
      </Box>

      <Typography flexBasis="100%">{description}</Typography>
    </Box>
  );
};

export default SimpleFormSectionTitle;
