import { Box, styled } from "@mui/material";

export const LoadingBox = styled(Box)(({ theme }) => ({
    zIndex: "5",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    background: "radial-gradient(ellipse at center, rgba(0,0,0,0.2) -100%, rgba(0,0,0,0) 100%);",
    backgroundSize: "100% 100%"
  }));