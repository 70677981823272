import { useEffect, useState, useContext } from "react";
import { Box, CssBaseline, useMediaQuery, useTheme } from "@mui/material";
import { AuthenticatedTemplate } from "@azure/msal-react";

export default function AuthenticatedEmptyLayout({ children }: IChildrenProps) {
  return (
    <AuthenticatedTemplate>
      <Box
        sx={{
          height: "100%",
          backgroundColor: "bg.light",
          width: "100%",
          overflow: "auto"
        }}
      >

        {children}
      </Box>
    </AuthenticatedTemplate>
  );
}
