import React, { useEffect, useState } from "react";
import SimpleFormWithSections from "../components/form/SimpleForm/SimpleFormWithSections";
import { Box } from "@mui/material";
import SectionLoading from "../components/common/loading/SectionLoading";
import { DropDownChoiceWithLabel, ErrorType, QueryError } from "../types";
import useUser from "../hooks/useUserData";
import { useSnackbar } from "../hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import { RouterRoutes } from "../routes/routes";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import { editProfileForm } from "../data/Contact/forms/editProfile";
import {
  editAccountForm,
  EditAccountFields,
} from "../data/Account/forms/editAccount";
import ErrorMessage from "../components/common/error/ErrorMessage";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAccount, updateAccount } from "../data/Account/queries";
import QueryErrorMessage from "../components/common/error/QueryErrorMessage";
import { buildForm } from "../components/form/SimpleForm/buildForm";
import { buildAddress, parseAddress } from "../data/addressHelper";
import { AccountPatch, AccountRow } from "../data/Account/models";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";

const EditAccount = () => {
  const { userData, updateUser } = useUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showSnackbar, showErrorSnackbar } = useSnackbar();
  const { data, error, isLoading, isFetching } = useQuery<
    AccountRow,
    QueryError
  >({
    queryKey: ["Account"],
    queryFn: () => getAccount(),
  });

  const mutation = useMutation<void, QueryError, AccountPatch>({
    mutationFn: (account) => updateAccount(account),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["Account"] });
      showSnackbar("Organisation modifiée", "success");
      navigate(`/${RouterRoutes.Account}`);
    },
    onError: (error: QueryError) => {
      showErrorSnackbar(
        error.type === ErrorType.FORBIDDEN ||
          error.type === ErrorType.UNAUTHORIZED
          ? "Vous n'êtes pas autorisé à modifier l'organisation."
          : "Erreur dans la modification de l'organisation.",
        "error",
        error
      );
    },
  });
  const handleSuccess = async (fields: EditAccountFields) => {
    if (!data) return;
    if (
      !fields.siegeSocialAddress.value ||
      (!fields.vdl_adresseidentique && !fields.correspondanceAddress.value) ||
      !fields.name.value ||
      !fields.vdl_secteursiegesocial.value
    ) {
      showSnackbar("Certains champs sont manquants.", "warning");
      return;
    }
    let missingField: string | null = null;
    try {
      if (!fields.siegeSocialAddress.value) {
        missingField = fields.siegeSocialAddress.label;
        throw new Error("Ce champ est requis");
      }
      const siegeSocialAddress = fields.siegeSocialAddress.changed
        ? parseAddress(fields.siegeSocialAddress.value.text)
        : {
            street: data.address1_line1,
            apartment: data.address1_line2,
            city: data.address1_city,
            province: data.address1_stateorprovince,
            postalCode: data.address1_postalcode,
            country: data.address1_country,
          };

      const correspondanceAddress = fields.vdl_adresseidentique.value
        ? siegeSocialAddress
        : fields.correspondanceAddress.value &&
          fields.correspondanceAddress.changed
        ? parseAddress(fields.correspondanceAddress.value.text)
        : {
            street: data.address2_line1,
            apartment: data.address2_line2,
            city: data.address2_city,
            province: data.address2_stateorprovince,
            postalCode: data.address2_postalcode,
            country: data.address2_country,
          };

      if(!siegeSocialAddress) {
        missingField = fields.siegeSocialAddress.label;
        throw new Error("Ce champ est requis");
      }

      if(!correspondanceAddress) {
        missingField = fields.correspondanceAddress.label;
        throw new Error("Ce champ est requis");
      }

      const _account: AccountPatch = {
        name: fields.name.value,
        vdl_autresnomorganisme: fields.vdl_autresnomorganisme.value,
        telephone1: fields.telephone1.value,
        emailaddress1: fields.emailaddress1.value,
        vdl_secteursiegesocial: fields.vdl_secteursiegesocial.value,
        vdl_pagefacebook: fields.vdl_pagefacebook.value,
        websiteurl: fields.websiteurl.value,
        address1_city: siegeSocialAddress.city,
        address1_line1: siegeSocialAddress.street,
        address1_postalcode: siegeSocialAddress.postalCode,
        address1_stateorprovince: siegeSocialAddress.province,
        address1_country: siegeSocialAddress.country,
        address1_line2: siegeSocialAddress.apartment,
        vdl_adresseidentique: fields.vdl_adresseidentique.value,
        address2_city: correspondanceAddress.city,
        address2_country: correspondanceAddress.country,
        address2_line1: correspondanceAddress.street,
        address2_postalcode: correspondanceAddress.postalCode,
        address2_stateorprovince: correspondanceAddress.province,
        address2_line2: correspondanceAddress.apartment,
      };

      mutation.mutate(_account);
    } catch (error) {
      showSnackbar(
        "Erreur dans la modification." + missingField ? " (" + missingField + ")" : "",
        "error"
      );
      return;
    }
  };

  if (isLoading || isFetching)
    return (
      <PageTitleLayout title="Modifier mon organisation" isLoading={true}>
        <SectionLoading />
      </PageTitleLayout>
    );

  if (error || !data) return <QueryErrorMessage error={error} />;
  return (
    <PageTitleLayout title="Modifier mon organisation">
      <BoxResponsivePadding>
        <SimpleFormWithSections
          isFormLoading={mutation.isPending}
          maxWidth={900}
          fields={buildForm(editAccountForm.fields, {
            name: data.name,
            vdl_autresnomorganisme: data.vdl_autresnomorganisme ?? "",
            telephone1: data.telephone1,
            emailaddress1: data.emailaddress1 ?? "",
            websiteurl: data.websiteurl ?? "",
            vdl_pagefacebook: data.vdl_pagefacebook ?? "",
            vdl_secteursiegesocial: data.vdl_secteursiegesocial,
            siegeSocialAddress: {
              text: buildAddress({
                street: data.address1_line1,
                apartment: data.address1_line2,
                city: data.address1_city,
                province: data.address1_stateorprovince,
                postalCode: data.address1_postalcode,
                country: data.address1_country,
              }),
            },
            vdl_adresseidentique: data.vdl_adresseidentique,
            correspondanceAddress: {
              text: buildAddress({
                street: data.address2_line1,
                apartment: data.address2_line2,
                city: data.address2_city,
                province: data.address2_stateorprovince,
                postalCode: data.address2_postalcode,
                country: data.address2_country,
              }),
            },
          })}
          handleSuccess={handleSuccess}
          submitText={"Enregistrer"}
          sectionsBackground="common.white"
          sections={editAccountForm.sections}
          columns={{ minWidth: 220, count: 2 }}
          removeFormPadding
        />
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};

export default EditAccount;
