import { Box, Typography, styled } from "@mui/material";
import React from "react";
import ClickableIcon from "../../../../../common/buttons/icons/ClickableIcon";
import { Info } from "@mui/icons-material";
import { IncidentStandardEquipmentProductRow } from "../../../../../../data/Incident/types/standardEquipment";

const CardLayout = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "grid",
  gap: "32px",
  gridTemplateColumns: "150px 1fr auto 3rem",
  padding: "16px",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "150px 1fr",
  },
}));

type Props = {
  product: IncidentStandardEquipmentProductRow;
};

const ProductCard = ({ product }: Props) => {
  return (
    <CardLayout>
      <Box
        maxHeight="220px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          component="img"
          src={"data:image/png;base64," + product.vdl_Produit.vdl_photo}
          sx={{ objectFit: "contain" }}
          maxWidth="100%"
          height="100%"
          maxHeight="100%"
          minWidth="0"
        />
      </Box>
      <Box>
        <Typography fontWeight="bold" fontSize="0.9rem">
          {product.vdl_Produit.name}
        </Typography>
        <Typography fontSize="0.9rem">Ref: {product.vdl_Produit.vdl_deserie}</Typography>
        <Typography fontSize="0.9rem">
          Description: {product.vdl_Produit.description}
        </Typography>
      </Box>
      <Box display="flex" gap="6px">
        <Typography fontSize="0.9rem" fontWeight="bold">
          Quantité:
        </Typography>
        <Typography fontSize="0.9rem">
          {product.vdl_quantite}
        </Typography>
      </Box>
      <Box display="flex" gap="16px">
        <ClickableIcon
          backgroundColor="background.paper"
          icon={<Info/>}
          handleClick={() => {}}
        />
      </Box>
    </CardLayout>
  );
};

export default ProductCard;
