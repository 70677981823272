import { TextField, InputAdornment } from '@mui/material'
import { ClearIcon } from '@mui/x-date-pickers'
import React, { useEffect, useState } from 'react'
import SearchIcon from "@mui/icons-material/Search";

type Props = {
    setDebouncedSearchValue: React.Dispatch<React.SetStateAction<string>>
}
const SearchField = ({setDebouncedSearchValue} : Props) => {
    const [searchValue, setSearchValue] = useState("");
    
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedSearchValue(searchValue);
      }, 1000);
  
      return () => {
        clearTimeout(handler);
      };
    }, [searchValue]);
  
    const handleClear = () => {
      setSearchValue("");
      setDebouncedSearchValue("");
    };
  
    const handleSearchValue = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const value = e.target.value;
      setSearchValue(value);
    };
  
  return (
    <TextField
    size="small"
    variant="outlined"
    value={searchValue}
    onChange={handleSearchValue}
    placeholder="Rechercher"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment
          position="end"
          style={{ display: searchValue !== "" ? "flex" : "none" }}
          onClick={handleClear}
          sx={{ cursor: "pointer" }}
        >
          <ClearIcon />
        </InputAdornment>
      ),
    }}
  />
  )
}

export default SearchField