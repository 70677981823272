import { Box } from "@mui/material";
import React from "react";
import InfoRequest from "./InfoRequest/InfoRequest";
import StandardEquipment from "./StandardEquipment/StandardEquipment";
import Fundraiser from "./Fundraiser/Fundraiser";
import Prisme from "./Prisme/Prisme";
import WebsiteDisplay from "./WebsiteDisplay/WebsiteDisplay";
import TelevisionDisplay from "./TelevisionDisplay/TelevisionDisplay";
import ElectronicPanel from "./ElectronicPanel/ElectronicPanel";
import ActivitiesCalendar from "./ActivitiesCalendar/ActivitiesCalendar";
import PublicSpace from "./PublicSpace/PublicSpace";
import Admissibility from "./Admissibility/Admissibility";
import SpecialEvent from "./SpecialEvents/SpecialEvent/SpecialEvent";
import Filming from "./SpecialEvents/Filming/Filming";
import { IncidentRow } from "../../../../data/Incident/models";

type Props = {
  demand: IncidentRow;
};

const SpecificTab = ({ demand }: Props) => {
  function renderDemandType() {
    switch (demand.idtypedeservice) {
      case 1:
        return <ActivitiesCalendar demand={demand} />;
      case 2:
        return <Fundraiser demand={demand} />;
      case 3:
        return <InfoRequest demand={demand} />;
      case 4:
      case 24:
        return <Admissibility demand={demand} />;
      case 5:
      case 6:
        return <StandardEquipment demand={demand} />;
      case 7:
        return <SpecialEvent demand={demand} />;
      case 10:
        return <PublicSpace demand={demand} />;
      case 11:
        return <ElectronicPanel demand={demand} />;
      case 12:
        return <Prisme demand={demand} />;
      case 13:
        return <WebsiteDisplay demand={demand} />;
      case 14:
        return <TelevisionDisplay demand={demand} />;
      case 15:
        return <Filming demand={demand} />;
    }
  }

  return <Box>{renderDemandType()}</Box>;
};

export default SpecificTab;
