import { GetResponse } from "../common/types";
import usefetchWithMsal from "../fetchWithMsal";
import { ProgramRow } from "./models";
import { ServiceRow } from "../Service/models";
import { errorHandler } from "../../components/common/error/errorHandler";
import { ErrorType, OperationType, QueryError } from "../../types";
import fetchMsal from "../fetchMsal";


export const getPrograms = async (): Promise<ProgramRow[]> => {
    try {
      const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_programs/`;
      const result = await fetchMsal("GET", endpoint);
      if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");
  
      if (result.status !== 200) {
        throw new QueryError(ErrorType.INTERNAL, await result.text());
      }
  
      return await result.json();
    } catch (e) {
      throw QueryError.TransformUnknown(
        e,
        "Demande",
        OperationType.RETRIEVEMULTIPLE
      );
    }
  };