import React from "react";
import { TableFields } from "../../../../types";
import { Grid, Typography } from "@mui/material";
import CardTitle from "../../../common/cards/CardTitle";
import DisplayField from "../../../common/cards/DisplayField";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

type Props = {
  title: string;
  emptyTextPlaceHolder?: string;
  adress1: TableFields;
  adress2: TableFields;
  sameAddress: boolean;
};
const AccountAdressCardColumn2 = ({
  title,
  adress1,
  adress2,
  sameAddress,
  emptyTextPlaceHolder,
}: Props) => {
  const toDisplay = sameAddress
    ? Object.entries(adress1).filter(([key]) => key != "vdl_secteursiegesocial")
    : Object.entries(adress2);
  return (
    <Grid
      container
      item
      xs={12}
      md={6}
      sx={{ minWidth: { xs: "250px", md: "300px" } }}
    >
      <Grid item xs={12} minHeight={"45px"} paddingBottom={"5px"}>
        <CardTitle title={title} />
      </Grid>
      <Grid container spacing={"5px"}>
        <Grid
          item
          container
          xs={12}
          gap={"6px"}
          alignItems={"center"}
          paddingBottom={"1.2rem"}
        >
          {sameAddress ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          <Typography fontWeight={"bold"} fontSize="0.9rem" color="inherit">
            Indicateur même adresse
          </Typography>
        </Grid>
        {toDisplay.map(([key, value]) => (
          <Grid item xs={12} key={key}>
            <DisplayField
              disabled={sameAddress}
              emptyPlaceHolder={emptyTextPlaceHolder}
              displayName={value.displayName}
              value={value.value??""}
              fontSize="0.9rem"
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default AccountAdressCardColumn2;
