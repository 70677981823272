import { DisplayNames } from "../../types";
import { IncidentRow } from "./models";
import { IncidentWebsiteDisplayDetailsRow } from "./types/websiteDisplay";
import { IncidentActivitiesCalendarDetailsRow } from "./types/activitesCalendar";
import { IncidentElectronicPanelsDetailsRow } from "./types/electronicPanels";
import { IncidentFundraiserDetailsRow } from "./types/fundraiser";
import { IncidentInfoRequestDetailsRow } from "./types/infoRequest";
import { IncidentPrismeDetailsRow } from "./types/prisme";
import { IncidentTelevisionDisplayDetailsRow } from "./types/televisionDisplay";
import { IncidentAdmissibilityDetailsRow } from "./types/admissibility";
import { IncidentPublicSpaceDetailsRow } from "./types/publicSpace/publicSpace";
import { IncidentStandardEquipmentDetailsRow } from "./types/standardEquipment";
import { IncidentFilmingDetailsRow } from "./types/specialEvents/filming/filming";
import { IncidentSpecialEventsDetailsGeneralRow } from "./types/specialEvents/specialEventsGeneral";
import { IncidentSpecialEventDetailsRow } from "./types/specialEvents/specialEvent/specialEvent";
import { IncidentSpecialEventsDateBase } from "./types/specialEvents/dates";
import { IncidentPublicSpaceReservationRowBase } from "./types/publicSpace/reservation";

const demandDisplayNames: DisplayNames<IncidentRow> = {
  title: "Sujet de la demande",
  vdl_contactprincipal: "Contact principal",
  vdl_typededemande: "Type de demande",
  vdl_detailsdelademande: "Détails",
  idtypedeservice: "Type de demande",
  incidentid: "",
  ticketnumber: "Numéro de la demande",
  statuscode: "Status",
  modifiedon: "Date de modification",
  createdon: "Date de création",
  _vdl_contactprincipal_value_FormattedValue: "",
  _vdl_contactprincipal_value: "Contact principal",
  _vdl_typededemande_value: "Type de demande",
  _vdl_typededemande_value_FormattedValue: "",
  _parentcaseid_value: "Demande parent",
  incident_parent_incident: "",
};

const infoRequestDisplayNames: DisplayNames<IncidentInfoRequestDetailsRow> = {
  vdl_demandeinformationid: "",
  vdl_sujetdelademande: "Sujet de la demande",
  vdl_name: "",
  vdl_description: "Description de la demande",
};

const filmingDisplayNames: DisplayNames<
  Omit<IncidentFilmingDetailsRow, keyof IncidentSpecialEventsDetailsGeneralRow>
> = {
  vdl_titredelaproduction: "Titre de la production",
  vdl_naturedutournage: "Nature du tournage",
  vdl_tournageetudiant: "Tournage étudiant",
  vdl_resumeduscenarioenquelquesmots: "Résumé du scénario en quelques mots",
  vdl_nombredevehiculesdeproductionetparticuliers: "Nombre de véhicules de production et particuliers",
  vdl_cubes: "Cube(s)",
  vdl_roulottes: "Roulotte(s)",
  vdl_voituresparticuliers: "Voitures particuliers",
  vdl_autresprecisez: "Autres (précisez)",
  vdl_generatricerequise: "Génératrice requise",
  vdl_descriptiondesequipementsaetreinstalles: "Description des équipements à être installés",
  vdl_cascadesoueffetsspeciaux: "Cascades ou effets spéciaux",
  vdl_descriptiondetailleedescascadeseffetsspeciaux: "Description détaillée des cascades/effets spéciaux",
  vdl_engagementloisetreglementtournage: "Le requérant s'engage à respecter les lois et la règlementation en vigueur ainsi qu'à tout mettre en œuvre afin de se conformer aux exigences des procédures et directives encadrant la tenue d'un tournage sur le territoire lavallois.",
  vdl_respectdesconditionsdusoutienmunicipal: "Le requérant atteste avoir pris connaissance du Guide de soutien logistique municipal à l'intention des maisons de productions et s'engage à respecter les conditions qui encadrent le soutien municipal aux tournages.",
  vdl_envoidesdocuments2semmaximumavantletournage: "Le document suivant, le certificat d'assurance, la lettre d'avis, le résumé du scénario, ainsi que le plan du site doivent être acheminé au bureau des événements spéciaux et transports un minimum de 2 semaines avant la date prévue du tournage à l'adresse suivante : tournage@laval.ca",
  vdl_engagementapayerlafacture: "Le requérant s'engage à payer leurs factures avant la date prévue du tournage selon la règlementation en vigueur. La Ville de Laval accepte les paiements seulement par chèque.",
  vdl_sengageafournirlesjustificatifssurdemande: "Le requérant s'engage à joindre à toutes demandes son certificat d'assurance responsabilité d'un minimum de 2 000 000$, la lettre bilingue distribuée aux citoyens du secteur ciblé et le résumé du scénario. Selon la nature de la demande, la municipalité peut exiger une autorisation écrite des citoyens du secteur affecté par le tournage."
};

const standardEquipmentDisplayNames: DisplayNames<IncidentStandardEquipmentDetailsRow> =
  {
    vdl_equipementmunicipalid: "",
    vdl_adressecompleterecuperation: "Adresse complète",
    vdl_identiquealadressedelivraison: "Identique à l'adresse de livraison",
    vdl_adressecompletelivraison: "Adresse complète",
    vdl_datedelivraison: "Date de livraison",
    vdl_datederecuperation: "Date de récupération",
    vdl_name: "",
    vdl_secteurdeladressederecuperation: "Secteur de l'adresse de récupération",
    vdl_secteurdeladressedelivraison: "Secteur de l'adresse de livraison",
    vdl_remarqueoucommentaire: "Remarque ou commentaire",
    vdl_remarqueoucommentairerecuperation: "Remarque ou commentaire",
    vdl_nomdubatimentrecuperation: "Nom du bâtiment",
    vdl_nomdubatimentlivraison: "Nom du bâtiment",
    products: "Produits",
    _vdl_typedeproduit_value: "Type de produits",
    vdl_horaire: "Horaire de livraison",
    vdl_horairerecuperation: "Horaire de récupération"
  };

const fundraiserDisplayNames: DisplayNames<IncidentFundraiserDetailsRow> = {
  vdl_projetdefinancement: "Projet de financement",
  vdl_collectedargentid: "",
  vdl_erjour: "1er choix",
  vdl_emejour: "2e choix",
  vdl_3emejour: "3e choix",
  vdl_4emejour: "4e choix",
  vdl_5emejour: "5e choix",
  vdl_jemengageasuivrelaformationobligatoire:
    "Je m'engage à suivre la formation obligatoire",
  vdl_jaidejasuivilaformationobligatoireannee:
    "J'ai déjà suivi la formation obligatoire (Année)",
  vdl_nombredejeunesparticipants18ansetmoins:
    "Nombre de jeunes qui participeront",
  vdl_nombredadultesparticipants5min: "Nombre d'adultes qui participeront",
  vdl_chomedeycartier: "Chomedey/Cartier",
  vdl_jeanberauddanieljohnson: "Jean-Béraud/Daniel-Johnson",
  vdl_notredamelavatere: "Notre-Dame/Lavatère",
  vdl_stmartinboisdeboulogne: "St-Martin/Bois-De-Boulogne",
  vdl_engagementaurespectdesproceduresetdirectives:
    "En tant que responsable de l'activité, je m'engage à faire respecter les procédures et directives de la municipalité dont j'ai pris connaissance préalablement et je dégage Ville de Laval de toute responsabilité dans le cadre de cet événement.",
};

const electronicPanelsDisplayNames: DisplayNames<IncidentElectronicPanelsDetailsRow> =
  {
    vdl_soutienpromotionnelid: "",
    vdl_datededebutdelaffichage: "Date de début de l'affichage",
    vdl_datedefindelaffichage: "Date de fin de l'affichage",
    vdl_quoi: "Quoi ?",
    vdl_qui: "Qui ?",
    vdl_quanddatesetheures: "Quand? Date(s) et heure(s)",
    vdl_ou: "Où ?",
    vdl_telephonepourinformationsurlevenement:
      "Téléphone pour information sur l'évènement ?",
  };

const activitiesCalendarDisplayNames: DisplayNames<IncidentActivitiesCalendarDetailsRow> = {
  vdl_soutienpromotionnelid: "",
  vdl_titredelevenement: "Titre de l'évènement",
  vdl_lieudelevenement: "Lieu de l'évènement",
  vdl_adressecompletedulieudelevenement: "Adresse complète du lieu de l'évènement",
  vdl_dateetheurededebut: "Date et heure de début",
  vdl_dateetheuredefin: "Date et heure de fin",
  vdl_commentairessurleshorairesdelevenement: "Commentaires sur les horaires de l'événement",
  vdl_cout: "Coût",
  vdl_adultes: "Adultes",
  vdl_retraites: "Retraités",
  vdl_etudiants: "Etudiants",
  vdl_enfants: "Enfants",
  vdl_prixdefamille: "Prix famille",
  vdl_prixdegroupe: "Prix de groupe",
  vdl_autressvpdetaillez2: "Autres, svp détaillez",
  vdl_stationnementpayant: "Stationnement payant",
  vdl_coutdestationnement: "Coût de stationnement",
  vdl_clientelesvisees: "Clientèle(s) visée(s)",
  vdl_siteinternet: "Site internet",
  vdl_pagefacebook: "Page Facebook",
  vdl_courriel: "Courriel",
  vdl_telephonecalendrier: "Téléphone",
  vdl_descriptionsommairedelevenement: "Description sommaire de l'évènement",
  vdl_autreinformationpertinente: "Autre information pertinente",
  vdl_piecejointe: "Veuillez joindre une photo"
};
const televisionDisplayDisplayNames: DisplayNames<IncidentTelevisionDisplayDetailsRow> =
  {
    vdl_soutienpromotionnelid: "",
    vdl_piecejointe: "Image de promotion",
    vdl_debutdediffusion: "Début de diffusion",
    vdl_findediffusion: "Fin de diffusion",
    vdl_secteur1: "Secteur 1",
    vdl_secteur2: "Secteur 2",
    vdl_secteur3: "Secteur 3",
    vdl_secteur4: "Secteur 4",
    vdl_secteur5: "Secteur 5",
    vdl_secteur6: "Secteur 6"
  };
const websiteDisplayDisplayNames: DisplayNames<IncidentWebsiteDisplayDetailsRow> =
  {
    vdl_soutienpromotionnelid: "",
    vdl_lieninternetdelapageconcernee: "Lien internet de la page concernée",
    vdl_piecejointe: "Pièce jointe",
  };

const prismeDisplayNames: DisplayNames<IncidentPrismeDetailsRow> = {
  vdl_soutienpromotionnelid: "",
  vdl_activiteapromouvoir: "Activité à promouvoir",
  vdl_datedelactivite: "Date de l'activité",
  vdl_nombredepanneaux: "Nombre de panneaux",
  vdl_datededebutdelevenement: "Date de début de l'évènement",
  vdl_datedefindelevenement: "Date de fin de l'évènement",
  vdl_autoroute25bouldelaconcordeestsite3: "Autoroute 25 & boul. de la Concorde Est (Site 3)",
  vdl_monteedumoulinleparcdumoulinsite10: "Montée du moulin & le parc du Moulin (site 10)",
  vdl_bouldeslaurentidesboulsaintmartinsite1: "Boul. des laurentides & boul. Saint Martin(Site 1)",
  vdl_bouldeslaurentidesboulcartiersite2: "Boul. des laurentides & boul. Cartier (Site 2)",
  vdl_boullecorbusierbouldusouvenirsite8: "Boul. le Corbusier & Boul. du Souvenir (Site 8)",
  vdl_bouldelaconcordebouldelavenirsite9: "Boul. de la Concorde & boul. de l'Avenir (Site 9)",
  vdl_boulcurelabelleboullevesquesite4: "Boul. Curé-Labelle & boul. Lévesque (Site 4)",
  vdl_boularthursauvebouldagenaisouestsite6: "Boul. Arthur-Sauvé & boul. Dagenais Ouest (Site 6)",
  vdl_boulsamsonavjosaphatdemerssite11: "Boul. Samson & av. Josaphat-Demers (Site 11)",
  vdl_bouldeslaurentidesbouldagenaisestsite5: "Boul. des Laurentides & boul. Dagenais Est(Site 5)",
  vdl_piecejointe: "Esquisse de l'affiche"
};

const admissibilityDisplayNames: DisplayNames<IncidentAdmissibilityDetailsRow> = {
  vdl_admisibiliteid: "",
  vdl_dorganismeabutnonlucratif: "D'organisme à but non lucratif",
  vdl_dorganismedebienfaisanceenregistre: "D'organisme de bienfaisance enregistré",
  vdl_etesvousunchapitrelavalloisduneinstancepro: "Êtes-vous un chapitre lavallois d'une instance provinciale légalement constituée?",
  vdl_siouiquelestlenometleneqdelorg: "Si oui, quel est le nom et le NEQ de l'organisme?",
  vdl_aucuneassurance: "Aucune assurance",
  vdl_assurancedesadministrateurs: "Assurance accident des administrateurs non rémunérés et bénévoles",
  vdl_assurancedesbiens: "Assurance des biens",
  vdl_assuranceresponsabilitecivile: "Assurance responsabilité civile",
  vdl_assuranceadministrateursetdirigeants: "Assurance administrateurs et dirigeants",
  vdl_nomdelacompagniedassurance: "Nom de la compagnie d'assurance",
  vdl_numerodelapolicedassurance: "Numéro de la police d'assurance",
  vdl_datedecheancedelapolicedassurance: "Date d'échéance de la police d'assurance",
  vdl_missionetobjectif: "Mission et Objectif",
  vdl_realitebesoinsoutien: "Réalité, Besoin, Soutien",
  vdl_pointsdeservicealaval: "Points de service à Laval",
  vdl_siouiaquelendroit: "Si oui, à quel endroit",
  vdl_dansqueldomainedactivitevotreorganismeinter: "Dans quel domaine d'activité votre organisme intervient-il?",
  vdl_siautresprecisez: "Si autres, précisez",
  vdl_principauxserviceouactivites: "Principaux Service ou Activités",
  vdl_desregroupementsetoudesorganismes: "Regroupements et/ou organismes",
  vdl_territoiresdesservisparlorganisme2: "Territoires Desservis par l'Organisme",
  vdl_siailleursprecisez: "Si ailleurs, précisez",
  vdl_nombredeparticipantsauxactivites: "Nombre de participants aux activités",
  vdl_typedeclientele: "Quel type de clientèle votre organisme dessert-il?",
  vdl_prescolaire0a4ans: "Préscolaire (0 à 4 ans)",
  vdl_enfants5a12ans: "Enfants (5 à 12 ans)",
  vdl_adolescents13a17ans: "Adolescents (13 à 17 ans)",
  vdl_adultes18a64ans: "Adultes (18 à 64 ans)",
  vdl_aines65ansetplus65: "Aînés (65 ans et plus)",
  vdl_politiquedeverification: "Politique de vérification",
  vdl_entente: "Entente",
  vdl_codedeconduite: "Code de conduite",
  vdl_formationsurladoptiondecomportementsadequats: "Formation sur l'adoption de comportements adéquats",
  vdl_codedeconduitedelorganisation: "Code de conduite de l'organisation",
  vdl_codeetsignatureduncontratethique: "Code et signature d'un contrat éthique",
  vdl_verificationantecedentjudiciaire: "Vérification antécédents judiciaires",
  vdl_mecanismedegestionderisqueencasdedoute: "Mécanisme de gestion de risque en cas de doute",
  vdl_utilisationdelaplateformejebenevoleca: "Utilisation de la Plateforme Je bénévole.ca ",
  vdl_jeveuxplusdinformationacesujet: "Je veux plus d'information à ce sujet",
  vdl_nombrederencontresduca: "Nombre de rencontres du CA l'année dernière",
  vdl_nombredepresencesalaga: "Nombre de présences à l'AGA",
  vdl_datedeladerniereassembleegeneraleannuelle: "Date de la dernière assemblée générale annuelle",
  vdl_nombredemembresactifsauseindelorganisme: "Nombre de membres actifs au sein de l'organisme",
  vdl_duneconcertation: "D'une concertation (précisez)",
  vdl_dunregroupement: "D'un regroupement (précisez)",
  vdl_dunefederation: "D'une fédération (précisez)",
  vdl_produisezvousannuellementdesprevisionsbudgeta: "Produisez-vous annuellement des prévisions budgétaires?",
  vdl_vosderniersetatsfinanciersontilsfaitlobjet: "Vos derniers états financiers ont-ils fait l'objet d'une vérification?",
  vdl_datedefindevotreexercicefinancierannuel: "Date de fin de votre exercice financier annuel:",
  vdl_audit: "Audit",
  vdl_missiondexamen: "Mission d'examen",
  vdl_verificationcomptable: "Vérification comptable",
  vdl_datedeladeclarationaureq: "Date de la déclaration au REQ",
  vdl_admissibiliterenouvellement: "",
  vdl_resolutionduconseildadministration: "Résolution du conseil d'administration",
  vdl_reglementsgeneraux: "Règlements généraux",
  vdl_procesverbauxdesagaetags: "Procès-verbaux des AGA et AGS",
  vdl_lettrespatentesetcharte: "Lettres patentes et charte",
  vdl_rapportdactivite: "Rapport d'activité",
  vdl_etatsfinanciers: "États financiers",
};

const publicSpaceDisplayNames: DisplayNames<IncidentPublicSpaceDetailsRow> = {
  vdl_espacemunicipalid: "",
  vdl_name: "Nom de l'activité / évènement",
  reservations: "",
};

const publicSpaceReservationDisplayNames: DisplayNames<IncidentPublicSpaceReservationRowBase> =
  {
    vdl_courriel: "Courriel",
    vdl_debutdelareservation: "Début réservation",
    vdl_findelareservation: "Fin réservation",
    vdl_ndetelephoneprincipal: "N° de téléphone principal",
    vdl_prenom: "Prénom",
    vdl_nom: "Nom",
    vdl_nombredeparticipants: "Nombre de participants",
    _vdl_nomdusiteareserver_value: "Nom du site à réserver",
    _vdl_sousplateau_value: "Sous-plateau",
    vdl_titre: "Titre",
  };

const specialEventsGeneralDisplayNames: DisplayNames<IncidentSpecialEventsDetailsGeneralRow> =
  {
    vdl_evenementspecialid: "",
    vdl_lieudelevenement: "Lieu de l'évènement",
    vdl_precisionsurlelieu: "Précision sur le lieu",
    vdl_siautreprecisezlieu: "Si autre précisez",
    vdl_encasdintemperieslevenementest: "En cas d'intempéries, l'évènement est:",
    vdl_siremisle: "Si remis, le",
    dates: "Dates"
  };
const specialEventDisplayNames: DisplayNames<
  Omit<IncidentSpecialEventDetailsRow, keyof IncidentSpecialEventsDetailsGeneralRow>
> = {
  vdl_nomdelevenement: "Nom de l'évènement",
  vdl_naturedelevenement: "Nature de l'évènement",
  vdl_siautreprecisez: "Si autre précisez nature",
  vdl_descriptiondelevenement: "Description de l'évènement",
  vdl_rayonnementdelevenement: "Rayonnement de l'évènement",
  vdl_nouvelledemande: "Nouvelle demande",
  vdl_demanderecurrente: "Demande récurrente",
  vdl_sireccurentdatedeladerniereedition: "Si récurrent, date de la dernière édition",
  vdl_nombredeparticipantsetdevisiteursattendus: "Nombre de participants et de visiteurs attendus",
  vdl_clienteleviseeparlevenement: "Clientèle visée par l'évènement",
  vdl_sispecifiqueprecisez: "Si spécifique, précisez",
  vdl_engagementloisetreglement: "L'organisation s'engage à respecter les lois et la règlementation en vigueur ainsi qu'à tout mettre en œuvre afin de se conformer aux exigences du Cadre de gestion des événements spéciaux de la Ville de Laval - Volet partenaires",
  vdl_engagementequiteetinclusion: "Accessibilité universelle : Dans un but d'équité et dans une approche inclusive, l'organisation s'engage à tout mettre en œuvre afin de permettre à toute personne de réaliser des activités de façon autonome et de vivre une expérience de qualité équivalente",
  vdl_engagementdeveloppementdurable: "L'organisation s'engage à adhérer aux principes du développement durable et à collaborer avec Ville de Laval dans la mise en application de mesures écoresponsables (voir Engagement éco-citoyen en annexe 4 du Cadre de gestion des événements spéciaux",
  vdl_responsabilitedumaterielprete: "Le requérant est responsable du matériel qui lui est prêté et s'engage à remettre les lieux dans l'état qu'ils étaient avant la tenue de l'événement. Tout bris ou perte devra être signalé au répondant municipal et des frais peuvent être applicables.",
  vdl_engagementhabitudessaines: "Saines habitudes de vie : L'organisation s'engage à faciliter l'accès aux participants à une alimentation saine, équilibrée, variée et sécuritaire et à favoriser les transports actifs pour se rendre au site de l'événement"
};

  const specialEventsDateDisplayNames: DisplayNames<IncidentSpecialEventsDateBase> =
  {
    vdl_description: "Description",
    vdl_dateetheurededebut: "Date et heure de début",
    vdl_dateetheuredefin: "Date et heure de fin"
  };
  
export {
  demandDisplayNames,
  infoRequestDisplayNames,
  standardEquipmentDisplayNames,
  fundraiserDisplayNames,
  electronicPanelsDisplayNames,
  activitiesCalendarDisplayNames,
  televisionDisplayDisplayNames,
  websiteDisplayDisplayNames,
  prismeDisplayNames,
  admissibilityDisplayNames,
  publicSpaceDisplayNames,
  publicSpaceReservationDisplayNames,
  specialEventsGeneralDisplayNames,
  specialEventDisplayNames, 
  specialEventsDateDisplayNames,
  filmingDisplayNames
};
