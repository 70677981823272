import { EntityOptionSetChoices } from "../../types";
type ContactEntityChoices = EntityOptionSetChoices<"statecode" | "vdl_fonction" | "vdl_salutation">;

export const contactOptionSetsChoices : ContactEntityChoices = {
    statecode : {
        0: "Actif",
        1: "Inactif",
    },
    vdl_fonction : {
        948300000: "Président",
        948300001: "Vice-Président",
        948300002: "Trésorier",
        948300003: "Secrétaire",
        948300004: "Administrateur",
        948300005: "Employé",
        948300006: "Autre",
    },
    vdl_salutation : {
        948300000: "Monsieur",
        948300001: "Madame",
    }
}