import React from "react";
import { DropDownOptionSetInput } from "../../../types";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

type Props = {
  field: DropDownOptionSetInput;
  fieldName: string;
  handleChange: (field: DropDownOptionSetInput) => void;
  disabled: boolean;
};

const FormLookupInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  
  function handleInputChange(event: SelectChangeEvent) {
    const { value } = event.target;
    let _field = { ...field };
    if (field.required && value !== "") {
      _field.error = "";
    }

    _field.value = value;
    handleChange(_field);
  }
  function handleEmpty() {
    let _field = { ...field };
    _field.value = null;
    handleChange(_field);
  }
  function handleBlur(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) {
    let _field = { ...field };
    _field.error = "";
    if (field.required && (!field.value || field.value === "")) {
      _field.error = "Ce champ est requis";
    }
    handleChange(_field);
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth
      required={field.required}
      disabled={disabled}
      error={!(field.error == "")}
      sx={{maxWidth: "100%"}}
    >
      <InputLabel
        id={fieldName}

      >
        {field.label}
      </InputLabel>
      <Select
        labelId={fieldName}
        name={fieldName}
        value={field.value??""}
        onChange={handleInputChange}
        label={field.label}
        onBlur={handleBlur}
        sx={{
          maxWidth: "100%", // Ensures Select fits within its container
          overflow: "hidden", // Prevents Select from overflowing
        }}
        SelectDisplayProps={{style: {maxWidth: "100%"}}}
        renderValue={(value: string) => <span style={{maxWidth: "50vw", textOverflow: "ellipsis", overflow: "hidden"}}>{value}</span>}
      >
        {field.choices.map((choice, idx) => (
          <MenuItem
            onClick={() =>
              choice.value === field.value ? handleEmpty() : null
            }
            key={idx}
            value={choice.value}
          >
            {choice.value}
          </MenuItem>
        ))}
      </Select>
      {field.error || field.helperText ? (
        <FormHelperText>
          {field.error ? field.error : field.helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default FormLookupInput;
