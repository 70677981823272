import { AccountPatch, AccountRow } from "./models";
import { ErrorType, OperationType, QueryError } from "../../types";
import fetchMsal from "../fetchMsal";

export const getAccount = async (): Promise<AccountRow> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_organisation`;

    const result = await fetchMsal("GET", endpoint);

    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    }

    return await result.json();
  } catch (e) {
    throw QueryError.TransformUnknown(
      e,
      "Organisation",
      OperationType.RETRIEVE
    );
  }
};


export const updateAccount = async (
  account: AccountPatch
): Promise<void> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/update_account`;
    const result = await fetchMsal("PATCH", endpoint, account);
    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    };

  } catch (e) {
    throw QueryError.TransformUnknown(
      e,
      "Organisation",
      OperationType.UPDATE
    );
  }
};