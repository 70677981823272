import React from "react";
import { ContactRow } from "../../../../data/Contact/models";
import {
  CardContent,
  Card,
  Grid,
  Chip,
  useTheme,
  Typography,
  Box,
  styled,
} from "@mui/material";
import {
  dateTimeStringToString,
  getStringFromField,
  getStringFromOptionSet,
} from "../../../../data/common/fieldToString";
import contactDisplayNames from "../../../../data/Contact/displayNames";
import ClickableIcon from "../../../common/buttons/icons/ClickableIcon";
import PrivateComponent from "../../../common/auth/PrivateComponent";
import LinkIcon from "../../../common/buttons/icons/LinkIcon";
import { Edit, Launch } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import CustomChip from "../../../common/misc/CustomChip";
import { Link } from "react-router-dom";
import { contactOptionSetsChoices } from "../../../../data/Contact/optionSetChoices";
import { basePaths } from "../../../../routes/routes";

const CardContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: "1rem",
  gridTemplateColumns: "1fr 1fr 18rem",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr auto",
    gridTemplateRows: "auto auto",
    gridAutoFlow: "column",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr",
    gridTemplateRows: "none",
    gridAutoFlow: "row",
  },
}));

type Props = {
  contact: ContactRow;
  handleSendInvite: (contactid: string) => Promise<void>;
  handleSuspend: (contactid: string) => Promise<void>;
};

const ContactCard = ({ contact, handleSendInvite, handleSuspend }: Props) => {
  const theme = useTheme();
  return (
    <Card elevation={0} sx={{ marginBottom: 2 }}>
      <CardContent>
        <CardContainer>
          <Grid container direction="column">
            <Typography
              variant="h6"
              fontWeight={600}
              color="primary"
              lineHeight={1.4}
              component={Link}
              to={`/contacts/${contact.contactid}`}
              sx={{
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {contact.firstname + " " + contact.lastname}
            </Typography>

            <Typography fontSize={"0.9rem"} fontWeight="600">
              {getStringFromOptionSet(
                contact.vdl_fonction,
                contactOptionSetsChoices.vdl_fonction
              )}
            </Typography>
            <Typography fontSize={"0.9rem"} fontWeight="600">
              {getStringFromField(contact.emailaddress1)}
            </Typography>
            <Typography fontSize={"0.9rem"} fontWeight="600">
              {getStringFromField(contact.telephone1)}
            </Typography>
            <Typography fontSize={"0.9rem"} fontWeight="600">
              {getStringFromField(contact.telephone2)}
            </Typography>
          </Grid>
          <Grid container direction="column" justifyContent="center">
            <Typography>
              <Typography fontSize={"0.9rem"} display="inline" component="span">
                {contactDisplayNames.modifiedon}
                {" : "}
              </Typography>
              <Typography
                fontSize={"0.9rem"}
                display="inline"
                component="span"
                fontWeight="600"
              >
                {dateTimeStringToString(contact.modifiedon)}
              </Typography>
            </Typography>
            <Typography>
              <Typography fontSize={"0.9rem"} display="inline" component="span">
                {contactDisplayNames.createdon}
                {" : "}
              </Typography>
              <Typography
                fontSize={"0.9rem"}
                display="inline"
                component="span"
                fontWeight="600"
              >
                {dateTimeStringToString(contact.createdon)}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            container
            alignItems="center"
            direction="row"
            gap="16px"
            gridRow={{
              xs: "span 1",
              sm: "span 2",
              lg: "span 1",
            }}
            justifyContent="space-between"
          >
            <CustomChip
              color={contact.vdl_accesauportail ? "green" : "grey"}
              text={
                contact.vdl_accesauportail
                  ? "Accès au portail"
                  : "Pas accès au portail"
              }
            />
            <Box display="flex" gap="16px" minWidth="6rem" justifyContent="end">
              {/* <PrivateComponent roleMinimum="Administrateur">
                {contact.vdl_accesauportail ? null : (
                  <ClickableIcon
                    size="medium"
                    tooltip="Envoyer une invitation"
                    icon={<SendIcon />}
                    handleClick={() => handleSendInvite(contact.contactid)}
                    backgroundColor="background.default"
                  />
                )}
              </PrivateComponent>*/}
              <PrivateComponent roleMinimum="Employé">
                {contact.vdl_accesauportail ? null : (
                  <LinkIcon
                    icon={<Edit />}
                    to={`/contacts/${contact.contactid}/${basePaths.edit}`}
                    backgroundColor="background.default"
                    tooltip="Modifier le contact"
                  />
                )}
              </PrivateComponent>
              <LinkIcon
                icon={<Launch />}
                to={`/contacts/${contact.contactid}`}
                backgroundColor="background.default"
                tooltip="Détails du contact"
              />
            </Box>
          </Grid>
        </CardContainer>
      </CardContent>
    </Card>
  );
};

export default ContactCard;
