import { Box, Typography } from "@mui/material";
import ReservationCard from "./ReservationCard";
import { IncidentPublicSpaceReservationRowExisting } from "../../../../../../data/Incident/types/publicSpace/reservation";
import BoxResponsivePadding from "../../../../../common/boxes/BoxResponsivePadding";

type Props = {
  reservations: IncidentPublicSpaceReservationRowExisting[];
};

const ReservationsSection = ({ reservations }: Props) => {

  return (
    <BoxResponsivePadding bgcolor="background.paper">
      <Box display="flex" paddingBottom="16px">
        <Typography
          variant="h6"
          color="primary"
          paddingBottom="4px"
          fontWeight="bold"
        >
          Réservations
        </Typography>
      </Box>
      <Box display="grid" gap="16px">
        {reservations.length > 0 ? (
          reservations.map((reservation, idx) => (
            <ReservationCard
              key={reservation.vdl_reservationid}
              reservation={reservation}
            />
          ))
        ) : (
          <Box bgcolor="background.default" padding="16px">
            <Typography>Il n'y a aucune réservation dans la demande</Typography>
          </Box>
        )}
      </Box>
    </BoxResponsivePadding>
  );
};

export default ReservationsSection;
