import clone from "just-clone";
import { FieldValues, FormFieldsBuilder, FormFields } from "../../../types";

export function buildForm<T extends FormFields>(
  baseForm: T,
  values: FieldValues<T>
) {
  let _fields = clone(baseForm);

  for (const key of Object.keys(values)) {
    if (values.hasOwnProperty(key)) {
      _fields[key].value = values[key as keyof typeof values];
    }
  }
  return _fields;
}

export function buildFormObj<T extends FormFields>(
  baseForm: T,
  values: FormFieldsBuilder<T>
) {
  return buildPartialFormObj(baseForm, values)
}

export function buildPartialFormObj<T extends FormFields>(
  baseForm: T,
  values: Partial<FormFieldsBuilder<T>> | FormFieldsBuilder<T>
) {
  const _fields: T = clone(baseForm);

  Object.keys(baseForm).forEach((key) => {
    const typedKey = key as keyof T;


    _fields[typedKey] = {
      ...clone(_fields[typedKey]),
      ...values[key as keyof typeof values],
    };
  });

  return _fields;
}