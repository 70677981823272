import { Badge, Box, Typography, styled } from "@mui/material";
import CustomChip from "../../../common/misc/CustomChip";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { dateStringToShortString } from "../../../../data/common/fieldToString";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import { Link } from "react-router-dom";
import { basePaths, routes } from "../../../../routes/routes";
import LinkIcon from "../../../common/buttons/icons/LinkIcon";

import useUser from "../../../../hooks/useUserData";
import { IncidentReferenceRow } from "../../../../data/Incident/models";
import {
  canUserDeleteIncident,
  canUserEditIncident,
  isIncidentDeletable,
  isIncidentEditable,
} from "../../../../data/Incident/rules";
import { incidentOptionSetsChoices } from "../../../../data/Incident/optionSetChoices";
import { demandDisplayNames } from "../../../../data/Incident/displayNames";
import ClickableIcon from "../../../common/buttons/icons/ClickableIcon";
import { Delete } from "@mui/icons-material";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { deleteIncident } from "../../../../data/Incident/queries";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { QueryError, ErrorType } from "../../../../types";
import { useConfirm } from "../../../../hooks/useConfirmation";

const CardBox = styled(Box)(({ theme }) => ({
  display: "grid",
  rowGap: "8px",
  columnGap: "16px",
  height: "100%",
  alignItems: "center",
  gridTemplateColumns: "auto 1fr",
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "auto 2.5rem 4rem",
    justifyContent: "space-between",
  },
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "auto auto 8rem 4rem",
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "4fr 3rem 3fr 8rem 4rem",
    gridTemplateRows: "auto auto",
    gridAutoFlow: "column",
    rowGap: "0",
  },
}));

type Props = {
  demand: IncidentReferenceRow;
  redirectHome?: boolean;
};



const DemandeCardNew = ({ demand, redirectHome }: Props) => {
  const { userData } = useUser();
  const queryClient = useQueryClient();
  const { showSnackbar, showErrorSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const deleteMutation = useMutation<void, QueryError>({
    mutationFn: () => deleteIncident(demand.incidentid),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["Demands"] });
      queryClient.invalidateQueries({
        queryKey: ["Demand", demand.incidentid],
      });
      queryClient.invalidateQueries({
        queryKey: ["Demand", demand._parentcaseid_value],
      });

      showSnackbar("Demande supprimée", "success");
    },
    onError: (error: QueryError) => {
      showErrorSnackbar(
        error.type === ErrorType.FORBIDDEN ||
          error.type === ErrorType.UNAUTHORIZED
          ? "Vous n'êtes pas autorisé à supprimer cette demande."
          : "Erreur dans la suppression de la demande.",
        "error",
        error
      );
    },
  });

  const handleDelete = async () => {
    const confirmResult = await confirm({
      title: "Êtes-vous certain?",
      message:
        "Une fois supprimée, la demande ne pourra pas être récupérée. Si elle contient des sous-demandes, elles seront aussi supprimées",
      confirmText: "Continuer",
      cancelText: "Annuler",
    });
    if (!confirmResult) return;

    deleteMutation.mutate();
  };

  const baseDemandPath = `${routes.demands.path}/${demand.incidentid}`;
  return (
    <Box padding="16px" bgcolor="background.paper" borderRadius="4px">
      <CardBox>
        <Box
          sx={{
            gridRow: {},
            gridColumn: {
              xs: "span 2",
              sm: "span 3",
              md: "span 4",
              lg: "span 1",
            },
          }}
        >
          <Typography
            variant="h6"
            fontWeight={600}
            color="primary"
            lineHeight={1.4}
            component={Link}
            to={`${baseDemandPath}/?tab=1`}
            sx={{
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {demand.title}
          </Typography>
        </Box>
        <Box
          sx={{
            gridRow: {
              xs: "3",
              md: "2",
            },
            gridColumn: {
              xs: "span 2",
              sm: "span 1",
            },
          }}
        >
          <Typography
            fontSize={"0.9rem"}
            fontWeight="600"
            display="inline"
            component="span"
          >
            {demand.ticketnumber}
          </Typography>
          <Typography>
            <Typography fontSize={"0.9rem"} display="inline" component="span">
              {`${demandDisplayNames.createdon}: `}
            </Typography>
            <Typography
              fontSize={"0.9rem"}
              display="inline"
              component="span"
              fontWeight="600"
            >
              {dateStringToShortString(demand.createdon)}
            </Typography>
          </Typography>
        </Box>
        <Box
          sx={{
            gridRow: {
              xs: "2",
              sm: "2/span 2",
              md: "2",
              lg: "1 / span 2",
            },
            gridColumn: {
              xs: "2",
            },
          }}
        >
          <LinkIcon
            ariaLabel={`${0} Nouvelles notifications`}
            disabled={0 === 0}
            icon={
              <Badge badgeContent={0} color="primary">
                <NotificationsIcon color="action" />
              </Badge>
            }
            to={`${baseDemandPath}/?tab=0`}
          />
        </Box>
        <Box
          sx={{
            gridRow: {
              xs: "4",
              md: "3",
              lg: "1/span 2",
            },
            gridColumn: {
              xs: "span 2",
              sm: "span 1",
              lg: "3",
            },
            paddingX: {
              lg: "16px",
            },
          }}
        >
          <Typography>
            <Typography fontSize={"0.9rem"} display="inline" component="span">
              {`Créé par: `}
            </Typography>
            <Typography
              fontSize={"0.9rem"}
              display="inline"
              component="span"
              fontWeight="600"
            >
              {demand._vdl_contactprincipal_value_FormattedValue ?? ""}
            </Typography>
          </Typography>
          <Typography>
            <Typography fontSize={"0.9rem"} display="inline" component="span">
              {`Type: `}
            </Typography>
            <Typography
              fontSize={"0.9rem"}
              display="inline"
              component="span"
              fontWeight="600"
            >
              {demand._vdl_typededemande_value_FormattedValue}
            </Typography>
          </Typography>
        </Box>
        <Box
          sx={{
            gridRow: {
              sm: "2",
              md: "2",
              lg: "span 2",
            },
            gridColumn: {
              md: "3",
              lg: "4",
            },
          }}
        >
          <CustomChip
            text={incidentOptionSetsChoices.statuscode[demand.statuscode]}
            status={incidentOptionSetsChoices.statuscode[demand.statuscode]}
          />
        </Box>
        <Box
          display="flex"
          gap="12px"
          alignItems="center"
          sx={{
            gridRow: {
              xs: "5",
              sm: "2/span 2",
              md: "2",
              lg: "span 2",
            },
            gridColumn: {
              xs: "span 2",
              sm: "3/span 1",
              md: "4",
              lg: "5",
            },
            justifyContent: {
              xs: "start",
              sm: "end",
            },
          }}
        >
          {isIncidentEditable(demand) &&
            canUserEditIncident(
              userData,
              demand._vdl_contactprincipal_value
            ) && (
              <LinkIcon
                backgroundColor="background.default"
                icon={<EditIcon />}
                to={`${baseDemandPath}/${basePaths.edit}?tab=1${
                  redirectHome ? "&redirect=accueil" : ""
                }`}
              />
            )}
          {isIncidentDeletable(demand) &&
            canUserDeleteIncident(
              userData,
              demand._vdl_contactprincipal_value
            ) && (
              <ClickableIcon
                backgroundColor="background.default"
                icon={<Delete />}
                handleClick={handleDelete}
              />
            )}
        </Box>
      </CardBox>
    </Box>
  );
};

export default DemandeCardNew;
