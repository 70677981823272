import { User } from "../../types";
import { IncidentFilmingRow, IncidentReferenceRow, IncidentRow, IncidentSpecialEventRow } from "./models";

export const demandEditableStatus = ["En création", "Inutilisée", "Incomplète"];

export const demandEditableStatusValues = [744610001, 948300004, 3];

export const demandEventTypes = [7, 15];

export const isIncidentOfTypeEvent = (
  demand: IncidentRow
): demand is IncidentFilmingRow | IncidentSpecialEventRow => {
  return demandEventTypes.indexOf(demand.idtypedeservice) > -1;
};


export const isIncidentDeletable = (demand: IncidentReferenceRow) =>
  demand.statuscode === 744610001; //En création

export const isIncidentEditable = (demand: IncidentReferenceRow) =>
  demandEditableStatusValues.indexOf(demand.statuscode) > -1;

export const isSubIncident = (demand: IncidentReferenceRow) =>
  demand._parentcaseid_value !== null

export const canUserReadIncident = (
  user: User,
  demandContactId?: string
): boolean => {
  if (
    user._vdl_roledesecurite_value_Formatted === "Administrateur" ||
    user._vdl_roledesecurite_value_Formatted === "Employé"
  )
    return true;

  return demandContactId === user.contactid;
};

export const canUserEditIncident = (
  user: User,
  demandContactId?: string
): boolean => {
  if (user._vdl_roledesecurite_value_Formatted === "Administrateur") return true;

  return demandContactId === user.contactid;
};

export const canUserDeleteIncident = (
  user: User,
  demandContactId?: string
): boolean => {
  if (user._vdl_roledesecurite_value_Formatted === "Administrateur") return true;

  if (user._vdl_roledesecurite_value_Formatted=== "Employé")
    return demandContactId === user.contactid;

  return false;
};
