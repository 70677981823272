import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import { Box } from "@mui/material";
import DataCard from "../components/common/cards/DataCard/DataCard";
import Edit from "@mui/icons-material/Edit";
import DataCardSkeleton from "../components/common/cards/DataCard/DataCardSkeleton";
import { TableFields } from "../types";
import contactDisplayNames from "../data/Contact/displayNames";
import LinkIcon from "../components/common/buttons/icons/LinkIcon";
import useUser from "../hooks/useUserData";
import { RouterRoutes } from "../routes/routes";
import { ContactRow } from "../data/Contact/models";
import {
  getStringFromField,
  getStringFromOptionSet,
} from "../data/common/fieldToString";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import { contactOptionSetsChoices } from "../data/Contact/optionSetChoices";

const fieldsToDisplay: (keyof ContactRow)[] = [
  "vdl_salutation",
  "firstname",
  "lastname",
  "vdl_fonction",
  "emailaddress1",
  "telephone1",
  "telephone2",
];

const ProfilePage = () => {
  const { instance } = useMsal();
  const { userData } = useUser();
  const [profileData, setProfileData] = useState<TableFields>({
    vdl_salutation: {
      displayName: contactDisplayNames.vdl_salutation,
      value: getStringFromOptionSet(
        userData.vdl_salutation,
        contactOptionSetsChoices.vdl_salutation
      ),
    },
    firstname: {
      displayName: contactDisplayNames.firstname,
      value: userData.firstname,
    },
    lastname: {
      displayName: contactDisplayNames.lastname,
      value: userData.lastname,
    },
    vdl_fonction: {
      displayName: contactDisplayNames.vdl_fonction,
      value: getStringFromOptionSet(
        userData.vdl_fonction,
        contactOptionSetsChoices.vdl_fonction
      ),
    },
    emailaddress1: {
      displayName: contactDisplayNames.emailaddress1,
      value: userData.emailaddress1,
    },
    telephone1: {
      displayName: contactDisplayNames.telephone1,
      value: userData.telephone1,
    },
    telephone2: {
      displayName: contactDisplayNames.telephone2,
      value: userData.telephone2??"",
    },
  });
  const [error, setError] = useState<string>();

  if (error || !profileData) return null;

  return (
    <PageTitleLayout title="Mon profil">
      <BoxResponsivePadding>
        <DataCard
          title="Informations personnelles"
          displayFields={profileData}
          emptyTextPlaceHolder="---"
          icon={
            <LinkIcon
              icon={<Edit />}
              to={RouterRoutes.EditProfile}
              backgroundColor="background.default"
            />
          }
        />
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};

export default ProfilePage;

const ProfilePageLoading = () => {
  return (
    <PageTitleLayout isLoading title="Mon profil">
      <BoxResponsivePadding>
        <DataCardSkeleton icon displayFieldsCount={fieldsToDisplay.length} />
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};
