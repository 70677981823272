import React, { useContext } from "react";
import {
  Box,
  Paper,
  styled,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AppDrawerContext } from "../AppDrawer/AppDrawer";

const StyledPaper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "drawerWidth",
})<{ drawerWidth?: number }>(({ theme, drawerWidth }) => ({
  minHeight: `100%`,
  display: "grid",
  gridTemplateRows:"1fr auto",
  backgroundColor: theme.palette.bg.light,
  marginLeft: `${drawerWidth}px`,
  paddingTop: theme.custom.header.height,
  transition: theme.transitions.create("padding", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.down("md")]: {
    marginLeft: 0,
  },
}));

type Props = {
  children: React.ReactNode;
  footer: React.ReactNode;
};

const AppContent = ({ children, footer }: Props) => {
  const theme = useTheme();
  const drawerContext = useContext(AppDrawerContext);
  return (
    <StyledPaper drawerWidth={drawerContext?.width}>
      <Box>
        {children}
      </Box>
    
      {footer}
    </StyledPaper>
  );
};

export default AppContent;
