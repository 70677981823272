import React, { useEffect } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { ISuggestResponse, suggest } from "@esri/arcgis-rest-geocoding";
import { ApiKeyManager } from "@esri/arcgis-rest-request";
import { AddressSuggestion } from "../../../data/common/types";
import { AddressInput } from "../../../types";

type Props = {
  field: AddressInput;
  fieldName: string;
  handleChange: (field: AddressInput) => void;
  disabled: boolean;
};

const FormAddressInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<AddressSuggestion[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const [emptyText, setEmptyText] = React.useState("Compléter votre addresse");

  async function handleValueChange(
    event: any,
    newValue: AddressSuggestion | null
  ) {
    let _field = { ...field };
  
    if (newValue === null) {
      _field.value = null;
    } else {
      if (newValue.text === field.value?.text) {
        _field.value = null;
        setInputValue("");
        _field.error = "Ce champ est requis";
        _field.changed = true;
      } else {
        _field.value = newValue;
        _field.error = "";
        _field.changed = true;
      }

      handleChange(_field);
    }
  }

  useEffect(() => {
    if (inputValue === "") setOptions([]);

    setEmptyText("Compléter l'addresse pour lancer la recherche...");

    const fetchData = async () => {
      try {
        setLoading(true);

        const res: ISuggestResponse = await suggest(inputValue, {
          authentication: ApiKeyManager.fromKey(
            process.env.REACT_APP_ESRI_API_KEY ??
              "AAPK7c5fa65817c147d9a7ab69f8ed0a8d756PnvGTJR8fKlrIzP8zDo_aNO88cRPYdCcCQiuS1d1rweL8K-EjEErAi5Jf2gtV_-"
          ),
          params: {
            category: "Subaddress,Point Address",
            countryCode: "CA",
          },
        });

        const candidates = res.suggestions;

        setOptions(candidates);
        setLoading(false);
      } catch (e) {
        setEmptyText("Erreur dans la récupération des adresses");
      }
    };

    const delay = 1000;
    const debounce = setTimeout(() => {
      if (inputValue) fetchData();
    }, delay);

    return () => {
      clearTimeout(debounce);
    };
  }, [inputValue]);

  return (
    <Autocomplete
      open={open}
      value={field.value}
      fullWidth
      filterOptions={(x: AddressSuggestion[]) => x}
      noOptionsText={emptyText}
      clearIcon={null}
      forcePopupIcon={false}
      loading={loading}
      id={fieldName}
      disabled={disabled}
      loadingText="Chargement..."
      isOptionEqualToValue={(
        option: AddressSuggestion,
        value: AddressSuggestion
      ) => option.text === value.text}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
        setOptions([]);
      }}
      onChange={handleValueChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      getOptionLabel={(option) => option.text}
      renderInput={(params) => (
        <TextField
          {...params}
          label={field.label}
          error={!(field.error === "")}
          helperText={field.error ? field.error : field.helperText}
          required={field.required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default FormAddressInput;
