import { Box, CircularProgress, Fade, styled } from "@mui/material";
import React from "react";
import { LoadingBox } from "../../common/loading/LoadingBox";

type Props = {
    loading: boolean
}


const SimpleFormLoading = ({loading} : Props) => {
  return (
    <Fade in={loading} timeout={600}>
      <LoadingBox>
        <CircularProgress />
      </LoadingBox>
    </Fade>
  );
};

export default SimpleFormLoading;
