import { Box, Grid } from "@mui/material";
import GeneralCard from "./GeneralCard/GeneralCard";
import AccountCard from "./AccountCard/AccountCard";
import { AccountRowNoContacts } from "../../../../data/Account/models";
import AttachmentCard from "./AttachmentCard/AttachmentCard";
import { IncidentGeneralRow } from "../../../../data/Incident/models";

type Props = {
  demand: IncidentGeneralRow;
  account: AccountRowNoContacts;
};

const GeneralTab = ({ demand, account }: Props) => {

  return (
    <Box>
      <Grid container columnGap={"1rem"} rowGap={"1rem"}>
        <Grid item xs={12} height={"100%"} xl>
          <GeneralCard demand={demand} />
        </Grid>
        <Grid item xs={12} xl={4.5}>
          <AccountCard
            account={account}
            primarycontact={demand.vdl_contactprincipal}
          />
          <Grid style={{ marginTop: "1rem" }}>
            <AttachmentCard />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralTab;
