import React, { useState } from "react";
import BoxResponsivePadding from "../../../common/boxes/BoxResponsivePadding";
import {
  IncidentPatch,
  IncidentPublicSpacePatch,
  IncidentPublicSpaceRow,
} from "../../../../data/Incident/models";
import { NewDemandFields } from "../../../../data/Incident/forms/dialog";

import EditDemandLayout from "../EditDemandLayout";

import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import {
  EditDemandPublicSpaceFields,
  editDemandPublicSpaceFields,
} from "../../../../data/Incident/forms/publicSpace/publicSpace";
import { Box, Link, Typography } from "@mui/material";
import ReservationsSection from "./Reservations/ReservationsSection";
import {
  IncidentPublicSpaceReservationEditing,
  IncidentPublicSpaceReservationPatch,
} from "../../../../data/Incident/types/publicSpace/reservation";
import { buildForm } from "../../../form/SimpleForm/buildForm";
import handleSimpleFormSrollToError from "../../../form/SimpleForm/handleSimpleFormSubmitAndScroll";

type Props = {
  demand: IncidentPublicSpaceRow;
  generalFields: NewDemandFields;
  update: (incident: IncidentPatch) => Promise<void>
};

const PublicSpace = ({
  demand,
  generalFields,
  update
}: Props) => {

  const [fieldsGeneral, setFieldsGeneral] =
  useState<NewDemandFields>(generalFields);

  const [fields, setFields] = useState<EditDemandPublicSpaceFields>(
    buildForm(editDemandPublicSpaceFields, {
      vdl_name: demand.vdl_detailsdelademande.vdl_name ?? "",
    })
  );
  const [demandReservations, setDemandReservations] = useState<
    IncidentPublicSpaceReservationEditing[]
  >(demand.vdl_detailsdelademande.reservations);
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      await handleSimpleFormSrollToError(errors[errors.length - 1]);
      return;
    }

    const _demand: IncidentPublicSpacePatch = {
      title: fieldsGeneral.title.value,
      _vdl_contactprincipal_value: fieldsGeneral.vdl_contactprincipal.value,
      idtypedeservice: demand.idtypedeservice,
      vdl_detailsdelademande: {
        vdl_name: fields.vdl_name.value,
        reservations: [],
      },
    };

    const reservationsUpdate: IncidentPublicSpaceReservationPatch[] = [];

    demandReservations.forEach((reservation) => {
      if ("vdl_reservationid" in reservation) {
        if ("delete" in reservation) {
          reservationsUpdate.push({
            vdl_reservationid: reservation.vdl_reservationid,
            delete: true,
          });
        }
      } else {
        reservationsUpdate.push(reservation);
      }
    });

    _demand.vdl_detailsdelademande.reservations = reservationsUpdate;

    await update(_demand)
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_typededemande.vdl_lienhautduformulaireportail}
      parentIncidentId={demand._parentcaseid_value}

    >
      <Box display="grid" gap="16px">
        <BoxResponsivePadding bgcolor="common.white" display="grid" gap="16px">
          <SimpleFields
            title="Activité"
            fields={fields}
            setFields={setFields}
          />
          <Typography>
            Avant d'effectuer une réservation, veuillez consulter la
            disponibilité du site en vous redirigeant vers le lien
            suivant:&nbsp;
            <Link href="https://www.publicationsports.com/cal/index.php?o=36&t=169&p=1232&lg=fr">
              https://www.publicationsports.com/cal/index.php?o=36&t=169&p=1232&lg=fr
            </Link>
            .
          </Typography>
        </BoxResponsivePadding>
        <BoxResponsivePadding bgcolor="common.white">
          <ReservationsSection
            reservations={demandReservations}
            setDemandReservations={setDemandReservations}
          />
        </BoxResponsivePadding>
      </Box>
    </EditDemandLayout>
  );
};

export default PublicSpace;
