import { EntityOptionSetChoices } from "../../types";
type ActivityTypeOptionSetChoices = EntityOptionSetChoices<"vdl_categorie">;

export const activityTypeOptionSetChoices : ActivityTypeOptionSetChoices = {
    vdl_categorie : {
        948300003: "Événement sur la voie publique",
        948300000: "Sécurité et prévention",
        948300001: "Diffusion sonore et éclairage",
        948300002: "Divers",
        948300004: "Soutien aux événements écoresponsables",
        948300005: "Utilisation d'édifice, parc et espace vert",
    },

}