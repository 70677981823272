import { useRef, useState } from "react";
import Logo from "../components/common/images/laval-logo.svg";
import SideImage from "../components/common/images/sidelogo.svg";
import { styled } from "@mui/material/styles";
import {
  Box,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { QueryError } from "../types";
import { AccountTypeRow } from "../data/AccountType/models";
import { getAccounTypes } from "../data/AccountType/queries";
import { useMsal } from "@azure/msal-react";
import Steps from "../components/pageSpecific/NewAccount/Steps";
import QueryErrorMessageInner from "../components/common/error/QueryErrorMessageInner";

const Container = styled("div")(({ theme }) => ({
  fontFamily: "Roboto, sans-serif",
  margin: 0,
  padding: 0,
  minHeight: "100vh",
  display: "flex",
  flexDirection: "row",
  ["@media (max-width:1200px)"]: {
    flexDirection: "column",
  },
}));

const LeftSection = styled("div")(({ theme }) => ({
  position: "relative",
  flex: 1,
  display: "flex",
  ["@media (min-width:1600px)"]: {
    flex: "1 1 50%",
  },
  ["@media (min-width:1200px) and (max-width:1600px)"]: {
    flex: "1 1 auto",
    maxWidth: "calc(100vw - 700px)",
  },
  ["@media (max-width:1200px)"]: {
    display: "none",
  },
}));

const RightSection = styled("div")(({ theme }) => ({
  flex: "1 1 50%",
  backgroundColor: theme.palette.common.white,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px 30px",
  ["@media (min-width:1200px) and (max-width:1600px)"]: {
    flex: "0 0 800px",
  },
  ["@media (max-width:1200px)"]: {
    width: "100%",
    padding: "20px 8px",
    boxSizing: "border-box",
  },
}));

const LavalLogo = styled("img")(({ theme }) => ({
  width: "15vw",
  [theme.breakpoints.down("sm")]: { width: "30vw" },
}));

const Image = styled("img")(() => ({
  width: "100%",
  maxHeight: "100vh",
  objectFit: "cover",
  position: "sticky",
  top: 0,
}));

const NewAccount = () => {
  const rightSectionRef = useRef<HTMLDivElement>(null);

  const { data, error, isLoading, isFetching } = useQuery<
    AccountTypeRow[],
    QueryError
  >({
    queryKey: ["AccountTypes"],
    queryFn: () => getAccounTypes(),
  });
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  return (
    <Container>
      <LeftSection>
        <Image src={SideImage} />
      </LeftSection>
      <RightSection ref={rightSectionRef}>
        <LavalLogo src={Logo} />
        <Typography variant="h4" fontWeight="bold" mt={3} textAlign="center">
          Portail des organismes
        </Typography>
        {isLoading || isFetching ? (
          <NewAccountLoading />
        ) : data && activeAccount ? (
          <Steps accountTypes={data} email={activeAccount.username} />
        ) : (
          <QueryErrorMessageInner error={error} />
        )}
      </RightSection>
    </Container>
  );
};

const NewAccountLoading = () => {
  const steps = ["Mes informations", "Mon organisation", "Validation"];
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      width="100%"
      justifyContent="center"
      flexDirection="row"
      marginTop="24px"
      gap="16px"
    >
      <Box width="100%" maxWidth="600px">
        <Stepper activeStep={-1} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                sx={{
                  ".MuiStepLabel-iconContainer svg": { fontSize: "2rem" },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Skeleton
        variant="rectangular"
        style={{
          borderRadius: "4px / 6.7px",
          width: "100%",
          height: "3.5rem",
        }}
      />
      {Array(6)
        .fill(0)
        .map((val, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            style={{
              borderRadius: "4px / 6.7px",
              flexBasis: "calc(50% - 8px)",
              flexGrow: "1",
              height: "3.5rem",
              minWidth: "260px",
            }}
          />
        ))}
    </Box>
  );
};
export default NewAccount;
