import React from "react";
import { MultipleOptionSetInput, OptionSetInput } from "../../../types";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { isNumber } from "../../../utils/isNumber";

type Props = {
  field: MultipleOptionSetInput;
  fieldName: string;
  handleChange: (field: MultipleOptionSetInput) => void;
  disabled: boolean;
};

const FormMultipleOptionSetInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    let _field = { ...field };
    if (field.required && value !== "") {
      _field.error = "";
    }
    let _temp: number[] = [];
    if (typeof value === "string") {
      value.split(",").forEach(e => {
        if (isNumber(e)) {
          _temp.push(parseInt(e, 10));
        }
      });
    } else {
      value.forEach(e => {
        if (isNumber(e)) {
          _temp.push(parseInt(e, 10));
        }
      });
    }

    _field.value = _temp;
    handleChange(_field);
  };

  function handleBlur(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) {
    let _field = { ...field };
    _field.error = "";
    if (field.required && (!field.value || field.value.length === 0)) {
      _field.error = "Ce champ est requis";
    }
    handleChange(_field);
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth
      required={field.required}
      disabled={disabled}
      error={!(field.error == "")}
      sx={{ maxWidth: "100%" }}
    >
      <InputLabel id={fieldName}>{field.label}</InputLabel>
      <Select
        multiple
        labelId={fieldName}
        name={fieldName}
        value={field.value.map((v) => v.toString())}
        onChange={handleSelectChange}
        label={field.label}
        onBlur={handleBlur}
        sx={{
          maxWidth: "100%", // Ensures Select fits within its container
          overflow: "hidden", // Prevents Select from overflowing
        }}
        SelectDisplayProps={{ style: { maxWidth: "100%" } }}
        renderValue={(selected) => (
          <span
            style={{
              maxWidth: "50vw",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {selected
              .map((item) => {
                if (isNumber(item) && field.choices[parseInt(item, 10)]) {
                  return field.choices[parseInt(item, 10)];
                }
              })
              .join(", ")}
          </span>
        )}
      >
        {Object.entries(field.choices).map(([key, val]) => (
          <MenuItem key={key} value={key}>
            <Checkbox checked={field.value.indexOf(parseInt(key, 10)) > -1} />
            <ListItemText primary={val} />
          </MenuItem>
        ))}
      </Select>
      {field.error || field.helperText ? (
        <FormHelperText>
          {field.error ? field.error : field.helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default FormMultipleOptionSetInput;
