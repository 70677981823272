import contactDisplayNames from "../displayNames";
import {
  FormFields,
  FormSection,
  PhoneInput,
  TextInput,
  DropDownLookUpInput,
  RadioInput,
  EmailInput,
  OptionSetInput,
} from "../../../types";
import {
  vdl_accessPortailChoices,
  vdl_fonctionChoices,
  vdl_salutationsChoices,
} from "./optionSetChoices";
import { contactOptionSetsChoices } from "../optionSetChoices";

export type NewUserFields = {
  vdl_salutation: OptionSetInput;
  firstname: TextInput;
  lastname: TextInput;
  vdl_fonction: OptionSetInput;
  emailaddress1: EmailInput;
  telephone1: PhoneInput;
  telephone2: PhoneInput;
};


const newUserFields: NewUserFields = {
  vdl_salutation: {
    type: "options",
    error: "",
    value: null,
    choices: contactOptionSetsChoices.vdl_salutation,
    required: true,
    fillLine: true,
    label: contactDisplayNames.vdl_salutation,
  },
  firstname: {
    type: "text",
    value: "",
    error: "",
    required: true,
    label: contactDisplayNames.firstname,
  },
  lastname: {
    type: "text",
    value: "",
    error: "",
    required: true,
    label: contactDisplayNames.lastname,
  },
  vdl_fonction: {
    type: "options",
    choices: contactOptionSetsChoices.vdl_fonction,
    value: null,
    error: "",
    required: true,
    label: contactDisplayNames.vdl_fonction,
  },
  emailaddress1: {
    type: "email",
    value: "",
    error: "",
    required: true,
    label: contactDisplayNames.emailaddress1,
  },
  telephone1: {
    type: "phone",
    value: "",
    error: "",
    required: true,
    label: contactDisplayNames.telephone1,
  },
  telephone2: {
    type: "phone",
    value: "",
    error: "",
    required: false,
    label: contactDisplayNames.telephone2,
  },
};

export {
  newUserFields,
};
