import { Box } from "@mui/material";
import { IncidentSpecialEventRow } from "../../../../../../data/Incident/models";
import { specialEventDisplayNames } from "../../../../../../data/Incident/displayNames";
import DisplayFieldCard from "../../../../../common/cards/DisplayFieldCard";
import {
  dateStringToShortString,
  dateTimeStringToString,
  getStringFromMultipleOptionSet,
  getStringFromOptionSet,
  timeStringToString,
} from "../../../../../../data/common/fieldToString";
import SpecialEvents from "../SpecialEvents";
import { incidentOptionSetsChoices } from "../../../../../../data/Incident/optionSetChoices";

type Props = {
  demand: IncidentSpecialEventRow;
};

const SpecialEvent = ({ demand }: Props) => {
  const details = demand.vdl_detailsdelademande;

  return (
    <Box display="grid" gap="16px">
      <SpecialEvents details={demand.vdl_detailsdelademande} />
      <DisplayFieldCard
        title="Informations sur l'évènement"
        fields={[
          {
            label: specialEventDisplayNames.vdl_nomdelevenement,
            value: details.vdl_nomdelevenement,
            type: "string",
          },
          {
            label: specialEventDisplayNames.vdl_naturedelevenement,
            value: getStringFromMultipleOptionSet(details.vdl_naturedelevenement, incidentOptionSetsChoices.vdl_naturedelevenement),
            type: "string",
          },
          {
            label: specialEventDisplayNames.vdl_siautreprecisez,
            value: details.vdl_siautreprecisez,
            type: "string",
          },
          {
            label: specialEventDisplayNames.vdl_descriptiondelevenement,
            value: details.vdl_descriptiondelevenement,
            type: "string",
          },
          {
            label: specialEventDisplayNames.vdl_rayonnementdelevenement,
            value: getStringFromMultipleOptionSet(details.vdl_rayonnementdelevenement, incidentOptionSetsChoices.vdl_rayonnementdelevenement),
            type: "string",
          },
          {
            label: specialEventDisplayNames.vdl_nouvelledemande,
            value: details.vdl_nouvelledemande,
            type: "check",
          },
          {
            label: specialEventDisplayNames.vdl_demanderecurrente,
            value: details.vdl_demanderecurrente,
            type: "check",
          },
          {
            label:
              specialEventDisplayNames.vdl_sireccurentdatedeladerniereedition,
            value: dateStringToShortString(
              details.vdl_sireccurentdatedeladerniereedition
            ),
            type: "string",
          },
          {
            label:
              specialEventDisplayNames.vdl_nombredeparticipantsetdevisiteursattendus,
            value: getStringFromOptionSet(
              details.vdl_nombredeparticipantsetdevisiteursattendus,
              incidentOptionSetsChoices.vdl_nombredeparticipantsetdevisiteursattendus
            ),
            type: "string",
          },
          {
            label: specialEventDisplayNames.vdl_clienteleviseeparlevenement,
            value: getStringFromMultipleOptionSet(details.vdl_clienteleviseeparlevenement, incidentOptionSetsChoices.vdl_clienteleviseeparlevenement),
            type: "string",
          },
          {
            label: specialEventDisplayNames.vdl_sispecifiqueprecisez,
            value: details.vdl_sispecifiqueprecisez,
            type: "string",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Engagements de l'organisateur"
        fields={[
          {
            label: specialEventDisplayNames.vdl_engagementloisetreglement,
            value: details.vdl_engagementloisetreglement,
            type: "check",
          },
          {
            label: specialEventDisplayNames.vdl_engagementequiteetinclusion,
            value: details.vdl_engagementequiteetinclusion,
            type: "check",
          },
          {
            label: specialEventDisplayNames.vdl_engagementdeveloppementdurable,
            value: details.vdl_engagementdeveloppementdurable,
            type: "check",
          },
          {
            label: specialEventDisplayNames.vdl_responsabilitedumaterielprete,
            value: details.vdl_responsabilitedumaterielprete,
            type: "check",
          },
          {
            label: specialEventDisplayNames.vdl_engagementhabitudessaines,
            value: details.vdl_engagementhabitudessaines,
            type: "check",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
    </Box>
  );
};

export default SpecialEvent;
