import SimpleFormWithSections from "../components/form/SimpleForm/SimpleFormWithSections";
import SectionLoading from "../components/common/loading/SectionLoading";
import { FormFields, QueryError } from "../types";
import useUser from "../hooks/useUserData";
import { useSnackbar } from "../hooks/useSnackbar";
import { useNavigate, useParams } from "react-router-dom";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import { EditContactFields, editContactForm } from "../data/Contact/forms/edit";
import { ContactPatch, ContactRow } from "../data/Contact/models";
import { buildFormObj } from "../components/form/SimpleForm/buildForm";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getContact, updateContact } from "../data/Contact/queries";
import QueryErrorMessage from "../components/common/error/QueryErrorMessage";
import { getRoles } from "../data/Role/queries";
import { RoleRow } from "../data/Role/models";

const EditContact = () => {
  const { id } = useParams();
  const { showSnackbar, showErrorSnackbar } = useSnackbar();
  const { userHasRole } = useUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  
  const contactQuery = useQuery<ContactRow, QueryError>({
    queryKey: ["Contact", id],
    queryFn: () => getContact(id),
  });

  const roleQuery = useQuery<RoleRow[], QueryError>({
    queryKey: ["Roles"],
    queryFn: () => getRoles(),
  });

  const error = contactQuery.error ?? roleQuery.error ?? null;
  const isLoading =
    contactQuery.isLoading ||
    contactQuery.isFetching ||
    roleQuery.isLoading ||
    roleQuery.isFetching;

  const mutation = useMutation({
    mutationFn: ({
      contactid,
      contact,
    }: {
      contactid: string;
      contact: ContactPatch;
    }) => updateContact(contactid, contact),
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ["Contact", id] });
      queryClient.invalidateQueries({ queryKey: ["Account"] });
      navigate(`/contacts/${variables.contactid}`);
      showSnackbar("Contact modifié", "success");
    },
    onError: (error: QueryError) => {
      showErrorSnackbar(
        "Erreur dans la mise à jour du contact.",
        "error",
        error
      );
    },
  });

  function roleDisabled(fields: FormFields): boolean {
    try {
      return !(
        fields.vdl_accesauportail.value === "Oui" &&
        userHasRole("Administrateur")
      );
    } catch (e) {
      return false;
    }
  }

  if (isLoading)
    return (
      <PageTitleLayout title="Modifier un contact">
        <SectionLoading />
      </PageTitleLayout>
    );
  if (error || !contactQuery.data || !roleQuery.data || !id)
    return <QueryErrorMessage error={error} />;

  const handleSuccess = async (fields: EditContactFields) => {
    let _contact: ContactPatch = {
      firstname: fields.firstname.value,
      lastname: fields.lastname.value,
      telephone1: fields.telephone1.value,
      telephone2: fields.telephone2.value,
      vdl_salutation: fields.vdl_salutation.value,
      vdl_fonction: fields.vdl_fonction.value??948300006,
    };
    if (userHasRole("Administrateur")) {
      _contact.vdl_accesauportail = fields.vdl_accesauportail.value === "Oui";
      if (fields.vdl_roledesecurite.value) {
        _contact._vdl_roledesecurite_value = fields.vdl_roledesecurite.value;
      }
    }

    mutation.mutate({ contact: _contact, contactid: id });
  };

  return (
    <PageTitleLayout title="Modifier un contact">
      <SimpleFormWithSections
        maxWidth={900}
        sectionsBackground="common.white"
        isFormLoading={mutation.isPending}
        fields={buildFormObj(editContactForm.fields, {
          vdl_salutation: { value: contactQuery.data.vdl_salutation },
          firstname: { value: contactQuery.data.firstname },
          lastname: { value: contactQuery.data.lastname },
          vdl_fonction: { value: contactQuery.data.vdl_fonction },
          telephone1: { value: contactQuery.data.telephone1 },
          telephone2: { value: contactQuery.data.telephone2 ?? "" },
          vdl_accesauportail: {
            value: contactQuery.data.vdl_accesauportail ? "Oui" : "Non",
            disabledCondition: () => !userHasRole("Administrateur"),
          },
          vdl_roledesecurite: {
            value: contactQuery.data._vdl_roledesecurite_value,
            disabledCondition: roleDisabled,
            choices: roleQuery.data.map((role) => {
              return {
                value: role.vdl_roleid,
                label: role.vdl_nomdurole
              }
            })
          },
        })}
        handleSuccess={handleSuccess}
        submitText={"Enregistrer"}
        sections={editContactForm.sections}
        columns={{ minWidth: 220, count: 2 }}
      />
    </PageTitleLayout>
  );
};

export default EditContact;
