import { Grid, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import useUser from "../hooks/useUserData";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import PrivateComponent from "../components/common/auth/PrivateComponent";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes/routes";
import ContactCard from "../components/pageSpecific/Contacts/ContactCard/ContactCard";
import { useSnackbar } from "../hooks/useSnackbar";
import ErrorMessage from "../components/common/error/ErrorMessage";
import CustomLinkButton from "../components/common/buttons/CustomLinkButton";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import ContactCardSkeleton from "../components/pageSpecific/Contacts/ContactCard/ContactCardSkeleton";
import { useQuery } from "@tanstack/react-query";
import { AccountRow } from "../data/Account/models";
import { getAccount } from "../data/Account/queries";
import { QueryError } from "../types";
import QueryErrorMessage from "../components/common/error/QueryErrorMessage";

const Contacts = () => {
  const theme = useTheme();
  const { data, error, isLoading, isFetching } = useQuery<
    AccountRow,
    QueryError
  >({
    queryKey: ["Account"],
    queryFn: () => getAccount(),
  });
  const { userData } = useUser();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const handleSendInvite = async (contactid: string) => {
    //const result = await sendInvite(contactid);
    //if (result.status === "success") {
    //  showSnackbar("Invitation envoyée", "success");
    //} else {
    //  showSnackbar("Erreur dans l'inivation", "error");
    //}
  };

  const handleSuspend = async (contactid: string) => {
    //const result = await sendInvite(contactid);
    //if (result.status === "success") {
    //  showSnackbar("Invitation envoyée", "success");
    //} else {
    //  showSnackbar("Erreur dans l'inivation", "error");
    //}
  };

  if (isLoading || isFetching) return <ContactsPageLoading />;

  if (error || !data) return <QueryErrorMessage error={error} />;

  return (
    <PageTitleLayout
      title="Contacts"
      sideTitleComponent={
        <PrivateComponent roleMinimum={routes.newContact.minimumRole}>
          <CustomLinkButton type="submit" primary to={routes.newContact.path}>
            Nouveau contact
          </CustomLinkButton>
        </PrivateComponent>
      }
    >
      <BoxResponsivePadding>
        {data.contacts.map((contact) => (
          <ContactCard
            key={contact.contactid}
            handleSendInvite={handleSendInvite}
            handleSuspend={handleSuspend}
            contact={contact}
          />
        ))}
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};

export default Contacts;

const ContactsPageLoading = () => {
  return (
    <PageTitleLayout isLoading title="Contacts">
      <BoxResponsivePadding>
        <Grid container gap={2}>
          <Grid item xs={12} xl>
            {Array(2)
              .fill(0)
              .map((i, idx) => (
                <ContactCardSkeleton icon key={idx} />
              ))}
          </Grid>
        </Grid>
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};
