import { useState, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { msalInstance } from "../providers/MsalProvider";
import { loginRequest } from "../auth/authConfig";
import { ErrorType, QueryError } from "../types";


const fetchMsal = async (method: string, endpoint: string, data?: any) => {
  try {
    const activeAccount = msalInstance.getActiveAccount();
    let token;
    try {
      token = (await msalInstance.acquireTokenSilent(loginRequest)).idToken;
    } catch (error) {
      await msalInstance.acquireTokenRedirect(loginRequest);
      return;
    }
    if (!activeAccount || !token) throw Error("Utilisateur non-authentifié");
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    const subscriptionKey = `${
      process.env.REACT_APP_APIM_SUBSCRIPTION_KEY ?? ""
    }`;
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", bearer);
    headers.append("Ocp-Apim-Subscription-Key", subscriptionKey);
    
    //const fullEndpoint = `${endpoint}?subscription-key=${subscriptionKey}`;

    if (data) headers.append("Content-Type", "application/json");

    let options = {
      method: method,
      headers: headers,
      body: data ? JSON.stringify(data) : null,
    };

    const res = await fetch(endpoint, options);

    if(res.status === 403) {
      throw new QueryError(
        ErrorType.FORBIDDEN,
        await res.text()
      );
    } 
    if(res.status === 401) {
      throw new QueryError(
        ErrorType.UNAUTHORIZED,
        await res.text()
      );
    }
    if(res.status === 404) {
      throw new QueryError(
        ErrorType.NOT_FOUND,
        await res.text()
      );
    }
    return res;
  } catch (e) {
    throw e;
  }
};

export default fetchMsal;
