import { DisplayNames } from "../../types";
import { ContactRow } from "./models";

const contactDisplayNames : DisplayNames<ContactRow> = {
  firstname: "Prénom",
  lastname: "Nom",
  emailaddress1: "Courriel",
  vdl_fonction: "Fonction au sein de l'organisation",
  vdl_salutation: "Titre",
  telephone1: "N° de téléphone principal",
  _vdl_roledesecurite_value: "Rôle de sécurité",
  _parentcustomerid_value: "",
  telephone2: "Autre téléphone",
  contactid: "",
  fullname: "Nom complet",
  vdl_accesauportail: "Accès au portail",
  createdon: "Date de création",
  modifiedon: "Dernière modification",
  statecode: "Statut",
  _vdl_roledesecurite_value_Formatted: "Rôle de sécurité"
};
export default contactDisplayNames;
