import React, { useEffect, useState } from "react";
import SimpleFields from "../../../../form/SimpleForm/SimpleFields";
import {
  EditDemandSpecialEventsGeneralFields,
  editDemandSpecialEventsGeneralSections,
} from "../../../../../data/Incident/forms/specialEvents/specialEventsGeneral";
import { Box, Typography } from "@mui/material";
import CustomButton from "../../../../common/buttons/CustomButton";
import DatesDialog from "./DatesDialog/DatesDialog";
import {
  IncidentSpecialEventsDateBase,
  IncidentSpecialEventsDateEditing,
} from "../../../../../data/Incident/types/specialEvents/dates";
import DateCard from "./DateCard";

type Props = {
  fields: EditDemandSpecialEventsGeneralFields;
  setFields: React.Dispatch<
    React.SetStateAction<EditDemandSpecialEventsGeneralFields>
  >;
  dates: IncidentSpecialEventsDateEditing[];
  setDates: React.Dispatch<
    React.SetStateAction<IncidentSpecialEventsDateEditing[]>
  >;
};

function SpecialEvents({
  fields,
  setFields,
  dates,
  setDates,
}: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const addDate = (newDate: IncidentSpecialEventsDateBase) => {
    setDates((prev) => [...prev, newDate]);
    setDialogOpen(false);
  };

  function deleteDate(idx: number) {
    setDates((prev) => {
      if ("vdl_datesid" in prev[idx]) {
        return prev.map((item, index) =>
          index === idx ? { ...item, delete: true } : item
        );
      }
      return prev.filter((_, index) => {
        return index !== idx;
      });
    });
  }
  return (
    <>
      {dialogOpen && (
        <DatesDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          addDate={addDate}
        />
      )}
      <Box display="grid" gap="16px">
        <SimpleFields
          fields={fields}
          setFields={setFields}
          sections={editDemandSpecialEventsGeneralSections}
          sectionsBackground="background.paper"
        />
        <Box
          display="grid"
          gap="16px"
          bgcolor="background.paper"
          padding="16px"
          mb="16px"
        >
          <Box display="flex" columnGap="16px" rowGap="4px" flexWrap="wrap">
            <Typography variant="h5" color="primary" paddingBottom="4px">
              Dates
            </Typography>
            <CustomButton primary onClick={() => setDialogOpen(true)}>
              Ajouter
            </CustomButton>
            <Typography flexBasis="100%">Ajouter toutes les dates pertinentes de l'événement tel que le montage et le démontage.</Typography>
          </Box>
          <Box display="grid" gap="16px">
            {dates.length > 0 ? (
              dates.map((date, idx) => {
                if (!("delete" in date)) {
                  let key: string;
                  if ("vdl_datesid" in date) {
                    key = date.vdl_datesid + date.vdl_description;
                  } else {
                    key =
                      date.vdl_dateetheurededebut +
                      date.vdl_dateetheuredefin +
                      date.vdl_description +
                      idx;
                  }
                  return (
                    <DateCard
                      date={date}
                      deleteDate={() => deleteDate(idx)}
                      key={key}
                    />
                  );
                }
              })
            ) : (
              <Box bgcolor="background.default" padding="16px">
                <Typography>Il n'y a aucune date dans la demande</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SpecialEvents;
