import { Box, Typography } from "@mui/material";
import { QueryError } from "../../../types";
import useUser from "../../../hooks/useUserData";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";

type Props = {
  error?: QueryError | null | undefined;
};

const QueryErrorMessageInner = ({ error }: Props) => {
  const userData = useContext(UserContext);

  const location = useLocation();
  return (
    <>
      <Box>
        <Typography variant="h6" color="primary" fontWeight="bold">
          Informations sur l'erreur:
        </Typography>
        <Typography>
          <Typography component="span" fontWeight="bold">
            {"Url: "}
          </Typography>
          <Typography component="span" sx={{ wordBreak: "break-all" }}>
            {location.pathname ?? "---"}
          </Typography>
        </Typography>
        {error ? (
          <>
            <Typography>
              <Typography component="span" fontWeight="bold">
                {"Entité: "}
              </Typography>
              <Typography component="span" sx={{ wordBreak: "break-all" }}>
                {error.entity ?? "---"}
              </Typography>
            </Typography>
            <Typography>
              <Typography component="span" fontWeight="bold">
                {"Opération: "}
              </Typography>
              <Typography component="span">
                {error.operation ? error.operation.toString() : "---"}
              </Typography>
            </Typography>
            {error.record ? (
              <Typography>
                <Typography component="span" fontWeight="bold">
                  {"Enregistrement: "}
                </Typography>
                <Typography component="span" sx={{ wordBreak: "break-all" }}>
                  {error.record}
                </Typography>
              </Typography>
            ) : null}
            <Typography>
              <Typography component="span" fontWeight="bold">
                {"Code d'erreur: "}
              </Typography>
              <Typography component="span" sx={{ wordBreak: "break-all" }}>
                {error.type ?? "---"}
              </Typography>
            </Typography>
            <Typography>
              <Typography component="span" fontWeight="bold">
                {"Message: "}
              </Typography>
              <Typography component="span" sx={{ wordBreak: "break-all" }}>
                {error.message ?? "---"}
              </Typography>
            </Typography>
          </>
        ) : (
          <Typography>
            <Typography component="span" fontWeight="bold">
              {"Détails: "}
            </Typography>
            <Typography component="span">Erreur inconnue.</Typography>
          </Typography>
        )}
      </Box>
      <Box>
        <Typography variant="h6" color="primary" fontWeight="bold">
          Informations sur l'utilisateur:
        </Typography>
        {userData ? (
          <>
            <Typography>
              <Typography component="span" fontWeight="bold">
                {"Courriel: "}
              </Typography>
              <Typography component="span" sx={{ wordBreak: "break-all" }}>
                {userData.userData.emailaddress1 ?? "---"}
              </Typography>
            </Typography>
            <Typography>
              <Typography component="span" fontWeight="bold">
                {"Accès au portail: "}
              </Typography>
              <Typography component="span">
                {userData.userData.vdl_accesauportail ? "Oui" : "Non"}
              </Typography>
            </Typography>
            <Typography>
              <Typography component="span" fontWeight="bold">
                {"Organisation: "}
              </Typography>
              <Typography component="span" sx={{ wordBreak: "break-all" }}>
                {userData.userData._parentcustomerid_value ?? "---"}
              </Typography>
            </Typography>
          </>
        ) : (
          <Typography component="span" fontWeight="bold">
            {"Impossible de récupérer les informations. "}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default QueryErrorMessageInner;
