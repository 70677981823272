import timeout from "../../../utils/timeout";

const handleSimpleFormSrollToError = async (key: string) => {
  await timeout(500);
  const scrollTo = document.getElementById("s-form-" + key);
  if (scrollTo) {
    scrollTo.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }
  await timeout(300);
  return;
};

export default handleSimpleFormSrollToError;
