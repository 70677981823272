import {
    Box,
  } from "@mui/material";
  import {
    IncidentWebSiteDisplayRow,
  } from "../../../../../data/Incident/models";
  import { websiteDisplayDisplayNames } from "../../../../../data/Incident/displayNames";
  import DisplayFieldCard from "../../../../common/cards/DisplayFieldCard";
  
  type Props = {
    demand: IncidentWebSiteDisplayRow;
  };
  
  const WebsiteDisplay = ({ demand }: Props) => {
    const details = demand.vdl_detailsdelademande;
    return (
      <Box display="grid" gap="16px">
        <DisplayFieldCard
        title="Informations spécifiques"
          fields={[
            {
              label: websiteDisplayDisplayNames.vdl_lieninternetdelapageconcernee,
              value: details.vdl_lieninternetdelapageconcernee,
              type: "string",
            },{
              label: websiteDisplayDisplayNames.vdl_piecejointe,
              value: details.vdl_piecejointe,
              type: "file",
            },
          ]}
          columns={1}
          minColWidth={300}
        />
      </Box>
    );
  };
  
  export default WebsiteDisplay;
  