import { GetResponse, PatchResponse, PostResponse } from "../common/types";
import fetchWithMsal from "../fetchWithMsal";
import {
  IncidentParentRow,
  IncidentGeneralRow,
  IncidentRow,
  IncidentPatch,
  IncidentPost,
  IncidentStatusPatch,
} from "./models";

import { errorHandler } from "../../components/common/error/errorHandler";
import fetchMsal from "../fetchMsal";
import { ErrorType, OperationType, QueryError } from "../../types";

export const getIncidents = async (): Promise<IncidentParentRow[]> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_incidents/`;
    const result = await fetchMsal("GET", endpoint);
    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    }

    return await result.json();
  } catch (e) {
    throw QueryError.TransformUnknown(
      e,
      "Demande",
      OperationType.RETRIEVEMULTIPLE
    );
  }
};

export const getIncident = async (
  incidentid: string | undefined
): Promise<IncidentRow> => {
  try {
    if (!incidentid)
      throw new QueryError(
        ErrorType.NOT_FOUND,
        "Requête invalide, vérifiez l'URL."
      );

    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_incident/${incidentid}`;
    const result = await fetchMsal("GET", endpoint);
    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    }

    return await result.json();
  } catch (e) {
    throw QueryError.TransformUnknown(
      e,
      "Demande",
      OperationType.RETRIEVE,
      incidentid
    );
  }
};

export const updateIncident = async (
  incidentid: string,
  incident: IncidentPatch
): Promise<void> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/update_incident/${incidentid}`;
    const result = await fetchMsal("PATCH", endpoint, incident);
    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    };

  } catch (e) {
    throw QueryError.TransformUnknown(
      e,
      "Demande",
      OperationType.UPDATE,
      incidentid
    );
  }
};

export const updateStatusIncident = async (
  incidentid: string,
  incident: IncidentStatusPatch
): Promise<void> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/update_status_incident/${incidentid}`;
    const result = await fetchMsal("PATCH", endpoint, incident);
    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    };

  } catch (e) {
    throw QueryError.TransformUnknown(
      e,
      "Demande",
      OperationType.UPDATE,
      incidentid
    );
  }
};

export const createIncident = async (
  incident: IncidentPost
): Promise<string | undefined> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/create_incident/`;
    const result = await fetchMsal("POST", endpoint, incident);
    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    };

    return await result.text();
  } catch (e) {
    throw QueryError.TransformUnknown(e, "Demande", OperationType.CREATE);
  }
};

export const deleteIncident = async (
  incidentid: string,
): Promise<void> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/delete_incident/${incidentid}`;
    const result = await fetchMsal("DELETE", endpoint);
    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    };

  } catch (e) {
    throw QueryError.TransformUnknown(
      e,
      "Demande",
      OperationType.DELETE,
      incidentid
    );
  }
};