import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig, signUpRequestBuilder } from "../auth/authConfig";
import {
  MsalAuthenticationTemplate,
  MsalProvider as Provider,
} from "@azure/msal-react";
import AuthenticationError from "../components/common/auth/AuthenticationError";
import {
  InteractionType,
} from "@azure/msal-browser";
import AppLoading from "../components/common/loading/AppLoading";

export const msalInstance = new PublicClientApplication(msalConfig);

const MsalProvider = ({ children }: IChildrenProps) => {

  msalInstance.initialize().then(() => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: EventMessage) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload
      ) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;

        msalInstance.setActiveAccount(account);
      }
    });
  });

  return (
    <Provider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={signUpRequestBuilder(inviteEmail(window.location))}
        loadingComponent={AppLoading}
        errorComponent={AuthenticationError}
      >
        {children}
      </MsalAuthenticationTemplate>
    </Provider>
  );
};
const inviteEmail = (location: globalThis.Location): string | undefined => {
  const isInvite = location.pathname.startsWith("/invite");
  const params = new URLSearchParams(location.search);
  const emailParam = params.get("emailaddress");
  const emailaddress = emailParam === null ? undefined : emailParam;

  if (isInvite && emailaddress) return emailaddress;
  return undefined;
};

export default MsalProvider;
