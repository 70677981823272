import React, { useEffect, useState } from "react";
import SimpleFormWithSections from "../components/form/SimpleForm/SimpleFormWithSections";
import { Box } from "@mui/material";
import SectionLoading from "../components/common/loading/SectionLoading";
import useUser from "../hooks/useUserData";
import { useSnackbar } from "../hooks/useSnackbar";
import { useNavigate } from "react-router-dom";
import { RouterRoutes } from "../routes/routes";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import {
  editProfileForm,
  EditProfileFields,
} from "../data/Contact/forms/editProfile";
import { ContactPatch } from "../data/Contact/models";
import ErrorMessage from "../components/common/error/ErrorMessage";
import { buildFormObj } from "../components/form/SimpleForm/buildForm";
import { updateContact } from "../data/Contact/queries";
import { Query, useMutation } from "@tanstack/react-query";
import { QueryError } from "../types";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import QueryErrorMessage from "../components/common/error/QueryErrorMessage";

const EditProfile = () => {
  const { userData, updateUser } = useUser();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { showSnackbar, showErrorSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: ({
      contactid,
      contact,
    }: {
      contactid: string;
      contact: ContactPatch;
    }) => updateContact(contactid, contact),
    onSuccess: async () => {
      await updateUser();
      setIsLoading(false);
      navigate("/" + RouterRoutes.MyProfile)
    },
    onError: (error: QueryError) => {
      showErrorSnackbar(
        "Erreur dans la mise à jour du profil.",
        "error",
        error
      );
      setIsLoading(false);
    },
  });
  const handleSuccess = async (fields: EditProfileFields) => {
    const contact: ContactPatch = {
      firstname: fields.firstname.value,
      lastname: fields.lastname.value,
      telephone1: fields.telephone1.value,
      telephone2: fields.telephone2.value,
      vdl_salutation: fields.vdl_salutation.value,
      vdl_fonction: fields.vdl_fonction.value ?? undefined,
    };
    setIsLoading(true);
    mutation.mutate({ contact: contact, contactid: userData.contactid });
  };

  if (isLoading)
    return (
      <PageTitleLayout title="Modifier mon profil">
        <SectionLoading />
      </PageTitleLayout>
    );

  if (mutation.error) return <QueryErrorMessage error={mutation.error} />;
  return (
    <PageTitleLayout title="Modifier mon profil">
      <BoxResponsivePadding>
        <SimpleFormWithSections
          maxWidth={900}
          fields={buildFormObj(editProfileForm.fields, {
            vdl_salutation: { value: userData.vdl_salutation ?? null },
            firstname: { value: userData.firstname },
            lastname: { value: userData.lastname },
            vdl_fonction: { value: userData.vdl_fonction },
            telephone1: { value: userData.telephone1 },
            telephone2: { value: userData.telephone2 ?? "" },
          })}
          title="Informations personnelles"
          handleSuccess={handleSuccess}
          sectionsBackground="common.white"
          submitText={"Enregistrer"}
          columns={{ minWidth: 220, count: 2 }}
        />
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};

export default EditProfile;
