import { Dayjs } from "dayjs";
import {
  DateInput,
  DateTimeInput,
  FormFields,
  TextInput,
} from "../../../../types";
import { specialEventsDateDisplayNames } from "../../displayNames";

export type EditDemandSpecialEventsDateFields = {
  vdl_description: TextInput;
  vdl_dateetheurededebut: DateTimeInput;
  vdl_dateetheuredefin: DateTimeInput;
};

const editDemandSpecialEventsDateFields: EditDemandSpecialEventsDateFields = {
  vdl_description: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    maxLength: 500,
    label: specialEventsDateDisplayNames.vdl_description,
  },
  vdl_dateetheurededebut: {
    type: "datetime",
    error: "",
    value: null,
    required: true,
    fillLine: true,
    disablePast: true,
    label: specialEventsDateDisplayNames.vdl_dateetheurededebut,
  },
  vdl_dateetheuredefin: {
    type: "datetime",
    error: "",
    value: null,
    required: true,
    fillLine: true,
    disablePast: true,
    label: specialEventsDateDisplayNames.vdl_dateetheuredefin,
    minDate: (fields: FormFields) =>
      (fields.vdl_dateetheurededebut.value as Dayjs)
        ? (fields.vdl_dateetheurededebut.value as Dayjs)
        : undefined,
  },
};

export { editDemandSpecialEventsDateFields };
