import React, { useEffect, useState } from "react";
import { FormField, FormFields, FormSection } from "../../../types";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import FormFieldWrapper from "./FormFieldWrapper";
import SimpleFormSectionTitle from "./SimpleFormSectionTitle";
import CustomButton from "../../common/buttons/CustomButton";
import SimpleFormLoading from "./SimpleFormLoading";
import timeout from "../../../utils/timeout";
import SimpleFormSections from "./SimpleFormSections";
import BoxResponsivePadding from "../../common/boxes/BoxResponsivePadding";
import handleSimpleFormSubmit from "./handleSimpleFormSubmit";

export type BaseFormProps<T extends FormFields> = {
  fields: T;
  submitText: string;
  cancelText?: string;
  handleCancel?: () => void;
  title?: string;
  maxWidth?: number;
  columns?: {
    count: number;
    minWidth: number;
  };
  sections?: FormSection<T>[];
  sectionsBackground?: string;
  isFormLoading?: boolean;
  removeFormPadding?: boolean;
  updateRefs?: boolean;
};

type Props<T extends FormFields> = BaseFormProps<T> & {
  setFields: React.Dispatch<React.SetStateAction<T>>;
  handleSuccess: (fields: T) => Promise<void> | void;
};

function SimpleFormBase<T extends FormFields>({
  fields,
  setFields,
  isFormLoading,
  submitText,
  cancelText,
  handleCancel,
  handleSuccess,
  title,
  maxWidth,
  columns,
  sections,
  sectionsBackground,
  removeFormPadding,
  updateRefs,
}: Props<T>) {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (key: string, field: FormField) => {
    let updatedFields: FormFields | undefined;
    if (field.onChangeEvent) {
      updatedFields = field.onChangeEvent({ ...fields, [key]: field });
    }
    setFields((prevState) => {
      return {
        ...prevState,
        [key]: field,
        ...updatedFields,
      };
    });
  };

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      setIsLoading(true);

      const errorsCount = handleSimpleFormSubmit(fields, setFields).length;
      if (errorsCount > 0) {
        setErrorMessage(errorsCount + " champ(s) en erreur");
        return;
      }
      await handleSuccess(fields);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      component="form"
      position="relative"
      paddingBottom="2rem"
      display="grid"
      gap="16px"
      maxWidth={maxWidth ? `${maxWidth}px` : "100%"}
      bgcolor={!sections ? sectionsBackground : undefined}
      borderRadius="4px / 6.7px"
      width="100%"
      sx={
        !removeFormPadding
          ? {
              padding: "20px",
              "@media (max-width:600px)": {
                padding: "15px",
              },
            }
          : undefined
      }
    >
      <SimpleFormLoading loading={isFormLoading || isLoading} />
      {title ? (
        <Typography variant="h5" color="primary">
          {title}
        </Typography>
      ) : null}
      {!errorMessage || errorMessage == "" ? null : (
        <Alert
          severity="error"
          onClose={() => {
            setErrorMessage(undefined);
          }}
        >
          {errorMessage}
        </Alert>
      )}

      <SimpleFormSections
        formData={fields}
        handleChange={handleChange}
        columns={columns}
        sections={sections}
        sectionsBackground={sectionsBackground}
        updateRefs={updateRefs}
      />
      <Box display="flex" justifyContent="flex-end" gap="20px">
        {cancelText ? (
          <CustomButton secondary onClick={handleCancel}>
            {cancelText}
          </CustomButton>
        ) : null}
        <CustomButton primary type="submit" onClick={onSubmit}>
          {submitText}
        </CustomButton>
      </Box>
    </Box>
  );
}

export default SimpleFormBase;
