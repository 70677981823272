import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DescriptionIcon from "@mui/icons-material/Description";
import { Role } from "../types";
import EditAccount from "../pages/EditAccount.page";
import NewContact from "../pages/NewContact.page";
import EditContact from "../pages/EditContact.page";
import EditProfile from "../pages/EditProfile.page";
import ProfilePage from "../pages/MyProfile.page";
import Documents from "../pages/Documents.page";
import Contacts from "../pages/Contacts.page";
import AccountPage from "../pages/Account.page";
import NewDemands from "../pages/NewDemands.page";
import Demands from "../pages/Demands.page";
import Demand from "../pages/Demand.page";
import EditDemand from "../pages/EditDemand.page";
import ContactPage from "../pages/Contact.page";


const basePaths = {
    demands: "demandes",
    demandeDetails: "demandes/:id",
  
    account: "compte",
  
    contacts: "contacts",
    contactDetails: "contacts/:id",
  
    profile: "profil",
  
    documents: "documents",
  
    new: "creer",
    edit: "modifier",
  };
  
  type BaseRoute = {
    path: string;
    component: JSX.Element;
    nav?: {
      label: string;
      icon: JSX.Element;
    };
  };
  
  type ProtectedRoute = BaseRoute & {
    minimumRole: Role;
  };
  
  type NotProtectedRoute = BaseRoute & {
      minimumRole?: undefined;
    };
  
  type CustomRoutes = {
    demands: NotProtectedRoute;
    newDemand: NotProtectedRoute;
    account: NotProtectedRoute;
    contacts: NotProtectedRoute;
    documents: NotProtectedRoute;
    profile: NotProtectedRoute;
    editProfile: NotProtectedRoute;
    contactDetails: NotProtectedRoute;
    editContact: ProtectedRoute;
    newContact: ProtectedRoute;
    editAccount: ProtectedRoute;
    demandDetails: NotProtectedRoute;
    editDemand: NotProtectedRoute;
  };
  
  const routes: CustomRoutes = {
    demands: {
      nav: {
        label: "Demandes",
        icon: <HomeIcon />,
      },
      path: `/${basePaths.demands}`,
      component: <Demands/>
    },
    newDemand: {
      nav: {
        label: "Nouvelle Demande",
        icon: <AddIcon />,
      },
      path: `/${basePaths.demands}/${basePaths.new}`,
      component: <NewDemands/>
    },
    account: {
      nav: {
        label: "Mon organisation",
        icon: <ApartmentIcon />,
      },
      path: `/${basePaths.account}`,
      component: <AccountPage/>
    },
    contacts: {
      nav: {
        label: "Contacts",
        icon: <AccountBoxIcon />,
      },
      path: `/${basePaths.contacts}`,
      component: <Contacts/>
    },
    documents: {
      nav: {
        label: "Documents",
        icon: <DescriptionIcon />,
      },
      path: `/${basePaths.documents}`,
      component: <Documents/>
    },
    profile: {
      path: `/${basePaths.profile}`,
      component: <ProfilePage/>
    },
    editProfile: {
      path: `/${basePaths.profile}/${basePaths.edit}`,
      component: <EditProfile/>
    },
    contactDetails: {
      path: `/${basePaths.contactDetails}`,
      component: <ContactPage/>,
    },
    editContact: {
      path: `/${basePaths.contactDetails}/${basePaths.edit}`,
      component: <EditContact/>,
      minimumRole: "Employé",
    },
    newContact: {
      path: `/${basePaths.contacts}/${basePaths.new}`,
      component: <NewContact/>,
      minimumRole: "Employé",
    },
    editAccount: {
      path: `/${basePaths.account}/${basePaths.edit}`,
      component: <EditAccount/>,
      minimumRole: "Employé",
    },
    demandDetails: {
      path: `/${basePaths.demandeDetails}`,
      component: <Demand/>,
    },
    editDemand: {
      path: `/${basePaths.demandeDetails}/${basePaths.edit}`,
      component: <EditDemand/>,
    },
  };
  

const paths = {
  demandes: "demandes",
  demandeDetail: "demandes/:id",

  compte: "compte",

  contacts: "contacts",
  contactDetail: "contacts/:id",

  monProfil: "profil",

  //messagerie: "messagerie",

  documents: "documents",

  nouveau: "creer",
  modifier: "modifier",
};

const RouterRoutes = {
  Home: "/",
  Fallback: "*",
  MyProfile: paths.monProfil,
  EditProfile: `/${paths.monProfil}/${paths.modifier}`,
  EditContact: `/${paths.contactDetail}/${paths.modifier}`,
  EditDemand: `/${paths.demandeDetail}/${paths.modifier}`,
  Demands: `/${paths.demandes}`,
  DemandDetail: paths.demandeDetail,
  Account: paths.compte,
  EditAccount: `/${paths.compte}/${paths.modifier}`,
  Contacts: paths.contacts,
  NewContact: `/${paths.contacts}/${paths.nouveau}`,
  ContactDetail: `/${paths.contactDetail}`,
  //Messages: paths.messagerie,
  Documents: paths.documents,
  NewDemand: `/${paths.demandes}/${paths.nouveau}`,
  Edit: `${paths.modifier}`,
};

export { basePaths, routes, paths, RouterRoutes };
