import React, { useEffect, useState } from "react";
import {
  FormFields,
  DropDownChoice,
  DropDownChoiceWithLabel,
  QueryError,
} from "../types";
import SimpleFormWithSections from "../components/form/SimpleForm/SimpleFormWithSections";
import { NewContactFields, newContactForm } from "../data/Contact/forms/new";
import { Box } from "@mui/material";
import useUser from "../hooks/useUserData";
import { useNavigate } from "react-router-dom";
import { RouterRoutes } from "../routes/routes";
import { useSnackbar } from "../hooks/useSnackbar";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import { ContactPatch, ContactPost } from "../data/Contact/models";
import ErrorMessage from "../components/common/error/ErrorMessage";
import { createContact, updateContact } from "../data/Contact/queries";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { RoleRow } from "../data/Role/models";
import { getRoles } from "../data/Role/queries";
import QueryErrorMessage from "../components/common/error/QueryErrorMessage";
import { buildPartialFormObj } from "../components/form/SimpleForm/buildForm";

const NewContact = () => {
  const [formFields, setFormFields] = useState<NewContactFields>(
    newContactForm.fields
  );
  const navigate = useNavigate();
  const { showSnackbar, showErrorSnackbar } = useSnackbar();
  const { userData, userHasRole } = useUser();
  const queryClient = useQueryClient();
  
  const roleQuery = useQuery<RoleRow[], QueryError>({
    queryKey: ["Roles"],
    queryFn: () => getRoles(),
  });
  
  const mutation = useMutation<
  string | undefined,
  QueryError,
  ContactPost
>({
    mutationFn: (contact) => createContact(contact),
    onSuccess: async (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ["Account"] });
      navigate(-1);
      showSnackbar("Contact créé", "success");
    },
    onError: (error: QueryError) => {
      showErrorSnackbar(
        "Erreur dans la création du contact.",
        "error",
        error
      );
    },
  });

  function roleDisabled(fields: FormFields): boolean {
    try {
      return !(
        fields.vdl_accesauportail.value === "Oui" &&
        userHasRole("Administrateur")
      );
    } catch (e) {
      return false;
    }
  }

  if (roleQuery.isLoading)
    return <PageTitleLayout title="Nouveau contact" loadingSpinner={true} />;

  if (roleQuery.error || !roleQuery.data)
    return <QueryErrorMessage error={roleQuery.error} />;

  const handleSuccess = async (fields: NewContactFields) => {
    let _contact: ContactPost = {
      firstname: fields.firstname.value,
      lastname: fields.lastname.value,
      emailaddress1: fields.emailaddress1.value,
      telephone1: fields.telephone1.value,
      vdl_fonction: fields.vdl_fonction.value ?? 948300006,
      vdl_salutation: fields.vdl_salutation.value,
    };
    if (userHasRole("Administrateur")) {
      _contact.vdl_accesauportail = fields.vdl_accesauportail.value === "Oui";
      if (fields.vdl_roledesecurite.value) {
        _contact._vdl_roledesecurite_value = fields.vdl_roledesecurite.value;
      }
    }
    mutation.mutate(_contact);
  };

  return (
    <PageTitleLayout title="Nouveau contact">
      <BoxResponsivePadding>
        <SimpleFormWithSections
          maxWidth={900}
          sectionsBackground="common.white"
          isFormLoading={mutation.isPending}
          fields={buildPartialFormObj(newContactForm.fields, {
            vdl_accesauportail: {
              value: undefined,
              disabledCondition: () => !userHasRole("Administrateur"),
            },
            vdl_roledesecurite: {
              value: null,
              disabledCondition: roleDisabled,
              choices: roleQuery.data.map((role) => {
                return {
                  value: role.vdl_roleid,
                  label: role.vdl_nomdurole,
                };
              }),
            },
          })}
          sections={newContactForm.sections}
          handleSuccess={handleSuccess}
          submitText={"Créer"}
          columns={{ minWidth: 220, count: 2 }}
          removeFormPadding
        />
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};

export default NewContact;
