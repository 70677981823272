import {  Card, CardContent, Grid, Typography, useTheme } from "@mui/material";
import DisplayField from "../../../../common/cards/DisplayField";
import { infoRequestDisplayNames } from "../../../../../data/Incident/displayNames";
import { IncidentInfoRequestRow } from "../../../../../data/Incident/models";

type Props = {
  demand: IncidentInfoRequestRow;
};

const InfoRequest = ({ demand }: Props) => {
  return (
    <Card elevation={0} sx={{ bgcolor: "white" }}>
      <CardContent>
        <Grid container>
          <Grid item xs display="grid" gap="8px">
            <Typography variant="h6" fontWeight="bold" color="primary" paddingBottom="8px">
              {demand._vdl_typededemande_value_FormattedValue}
            </Typography>
            <DisplayField
              displayName={infoRequestDisplayNames.vdl_sujetdelademande}
              value={demand.vdl_detailsdelademande.vdl_sujetdelademande??""}
            />
            <DisplayField
              displayName={infoRequestDisplayNames.vdl_description}
              value={demand.vdl_detailsdelademande.vdl_description??""}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default InfoRequest;
