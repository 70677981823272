import React, { useState } from "react";
import BoxResponsivePadding from "../../../common/boxes/BoxResponsivePadding";

import EditDemandLayout from "../EditDemandLayout";

import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import { fileToBase64 } from "../../../../data/common/fileToBase64";
import { dynamicsFileToFileType } from "../../../../data/common/buildFile";
import useTabs from "../../../../hooks/useTabs";
import {buildForm} from "../../../form/SimpleForm/buildForm";
import { NewDemandFields } from "../../../../data/Incident/forms/dialog";
import { IncidentPatch, IncidentWebSiteDisplayRow, IncidentWebsiteDisplayPatch } from "../../../../data/Incident/models";
import { EditDemandWebsiteDisplayFields, editDemandWebsiteDisplayFields } from "../../../../data/Incident/forms/websiteDisplay";
import handleSimpleFormSrollToError from "../../../form/SimpleForm/handleSimpleFormSubmitAndScroll";


type Props = {
  demand: IncidentWebSiteDisplayRow;
  generalFields: NewDemandFields;
  update: (incident: IncidentPatch) => Promise<void>;
};

const WebsiteDisplay = ({
  demand,
  generalFields,
  update,
}: Props) => {
  const [fields, setFields] = useState<EditDemandWebsiteDisplayFields>(
    buildForm(editDemandWebsiteDisplayFields, {
      vdl_lieninternetdelapageconcernee:
        demand.vdl_detailsdelademande.vdl_lieninternetdelapageconcernee ?? "",
      vdl_piecejointe: dynamicsFileToFileType(demand.vdl_detailsdelademande.vdl_piecejointe)
    })
  );
  const [fieldsGeneral, setFieldsGeneral] =
  useState<NewDemandFields>(generalFields);
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      await handleSimpleFormSrollToError(errors[errors.length - 1]);
      return;
    }

    const _demand: IncidentWebsiteDisplayPatch = {
      title: fieldsGeneral.title.value,
      _vdl_contactprincipal_value: fieldsGeneral.vdl_contactprincipal.value,
      idtypedeservice: demand.idtypedeservice,
      vdl_detailsdelademande: {
        vdl_lieninternetdelapageconcernee:
          fields.vdl_lieninternetdelapageconcernee.value,
      },
    };
    if (fields.vdl_piecejointe.changed) {
      if (fields.vdl_piecejointe.value) {
        const pieceJointeToBase = await fileToBase64(
          fields.vdl_piecejointe.value
        );
        if (pieceJointeToBase) {
          _demand.vdl_detailsdelademande.vdl_piecejointe = {
            filename: fields.vdl_piecejointe.value.name,
            content: pieceJointeToBase,
          };
        }
      }
    }


    await update(_demand)
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_typededemande.vdl_lienhautduformulaireportail}
      parentIncidentId={demand._parentcaseid_value}

    >
      <BoxResponsivePadding bgcolor="common.white">
        <SimpleFields fields={fields} setFields={setFields} />
      </BoxResponsivePadding>
    </EditDemandLayout>
  );
};

export default WebsiteDisplay;
