export type ParsedAddress = {
  street: string;
  apartment?: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;
};

export type AddressToParse = {
  street?: string;
  apartment?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  country?: string;
};

export function parseAddress(address: string): ParsedAddress | null {
  try {
    const parts = address.split(",").map((part) => part.trim());

    if (parts.length === 6) {
      const [street, apartment, city, province, postalCode, country] = parts;
      return {
        street,
        apartment,
        city,
        province,
        postalCode,
        country,
      };
    }

    if (parts.length === 5) {
      const [street, city, province, postalCode, country] = parts;
      return {
        street,
        city,
        province,
        postalCode,
        country,
      };
    }
    return null;
  } catch (error) {
    return null;
  }
}

export function buildAddress(address: AddressToParse): string {
  const { street, apartment, city, province, postalCode, country } = address;

  const addressParts = [
    street,
    apartment,
    city,
    province,
    postalCode,
    country,
  ].filter(part => part && part.trim() !== "");

  return addressParts.join(", ");
}
