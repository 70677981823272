import { Box } from "@mui/material";
import { IncidentAdmissibilityRow } from "../../../../../data/Incident/models";
import DisplayFieldCard from "../../../../common/cards/DisplayFieldCard";
import {
  dateStringToShortString,
  getStringFromMultipleOptionSet,
  getStringFromOptionSet,
} from "../../../../../data/common/fieldToString";
import { admissibilityDisplayNames } from "../../../../../data/Incident/displayNames";
import { globalOptionSetsChoices } from "../../../../../data/globalOptionSetChoices";

type Props = {
  demand: IncidentAdmissibilityRow;
};

const Admissibility = ({ demand }: Props) => {

  const details = demand.vdl_detailsdelademande;
  return (
    <Box display="grid" gap="16px">
      <DisplayFieldCard
        title="Admissiblité de l'organisme"
        fields={[
          {
            label: "Avez-vous un statut juridique :",
            type: "comment",
          },
          {
            label: admissibilityDisplayNames.vdl_dorganismeabutnonlucratif,
            value: details.vdl_dorganismeabutnonlucratif,
            type: "check",
          },
          {
            label:
              admissibilityDisplayNames.vdl_dorganismedebienfaisanceenregistre,
            value: details.vdl_dorganismedebienfaisanceenregistre,
            type: "check",
          },
          {
            label:
              admissibilityDisplayNames.vdl_etesvousunchapitrelavalloisduneinstancepro,
            value: details.vdl_etesvousunchapitrelavalloisduneinstancepro,
            type: "check",
          },
          {
            label: admissibilityDisplayNames.vdl_siouiquelestlenometleneqdelorg,
            value: details.vdl_siouiquelestlenometleneqdelorg,
            type: "string",
          },
          {
            label: admissibilityDisplayNames.vdl_datedeladeclarationaureq,
            value: dateStringToShortString(
              details.vdl_datedeladeclarationaureq
            ),
            type: "string",
          },
          {
            label:
              "Possédez-vous une police d'assurance couvrant les aspects suivants :",
            type: "comment",
            paddingTop: 6,
          },
          {
            label: admissibilityDisplayNames.vdl_aucuneassurance,
            value: details.vdl_aucuneassurance,
            type: "check",
          },
          {
            label: admissibilityDisplayNames.vdl_assurancedesadministrateurs,
            value: details.vdl_assurancedesadministrateurs,
            type: "check",
          },
          {
            label: admissibilityDisplayNames.vdl_assurancedesbiens,
            value: details.vdl_assurancedesbiens,
            type: "check",
          },
          {
            label: admissibilityDisplayNames.vdl_assuranceresponsabilitecivile,
            value: details.vdl_assuranceresponsabilitecivile,
            type: "check",
          },
          {
            label:
              admissibilityDisplayNames.vdl_assuranceadministrateursetdirigeants,
            value: details.vdl_assuranceadministrateursetdirigeants,
            type: "check",
          },
          {
            label: admissibilityDisplayNames.vdl_nomdelacompagniedassurance,
            value: details.vdl_nomdelacompagniedassurance,
            type: "string",
            paddingTop: 6,
          },
          {
            label: admissibilityDisplayNames.vdl_numerodelapolicedassurance,
            value: details.vdl_numerodelapolicedassurance,
            type: "string",
          },
          {
            label:
              admissibilityDisplayNames.vdl_datedecheancedelapolicedassurance,
            value: dateStringToShortString(
              details.vdl_datedecheancedelapolicedassurance
            ),
            type: "string",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Mission de l'organisme"
        fields={[
          {
            label: admissibilityDisplayNames.vdl_missionetobjectif,
            value: details.vdl_missionetobjectif,
            type: "string",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="La ou les raisons de la demande"
        fields={[
          {
            label: admissibilityDisplayNames.vdl_realitebesoinsoutien,
            value: details.vdl_realitebesoinsoutien,
            type: "string",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Offre de service de l'organisme"
        fields={[
          {
            label: admissibilityDisplayNames.vdl_pointsdeservicealaval,
            value: details.vdl_pointsdeservicealaval,
            type: "check",
          },
          {
            label: admissibilityDisplayNames.vdl_siouiaquelendroit,
            value: details.vdl_siouiaquelendroit,
            type: "string",
          },
          {
            label:
              admissibilityDisplayNames.vdl_dansqueldomainedactivitevotreorganismeinter,
            value: getStringFromOptionSet(
              details.vdl_dansqueldomainedactivitevotreorganismeinter,
              globalOptionSetsChoices.vdl_domainedactivite
            ),
            type: "string",
          },
          {
            label: admissibilityDisplayNames.vdl_siautresprecisez,
            value: details.vdl_siautresprecisez,
            type: "string",
          },
          {
            label: admissibilityDisplayNames.vdl_principauxserviceouactivites,
            value: details.vdl_principauxserviceouactivites,
            type: "string",
          },
          {
            label:
              admissibilityDisplayNames.vdl_desregroupementsetoudesorganismes,
            value: details.vdl_desregroupementsetoudesorganismes,
            type: "string",
          },
          {
            label:
              admissibilityDisplayNames.vdl_territoiresdesservisparlorganisme2,
            value: getStringFromMultipleOptionSet(
              details.vdl_territoiresdesservisparlorganisme2,
              globalOptionSetsChoices.vdl_territoiresdesservisparlorganisme
            ),
            type: "string",
          },
          {
            label: admissibilityDisplayNames.vdl_siailleursprecisez,
            value: details.vdl_siailleursprecisez,
            type: "string",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Clientèle désservie"
        fields={[
          {
            label:
              admissibilityDisplayNames.vdl_nombredeparticipantsauxactivites,
            value: details.vdl_nombredeparticipantsauxactivites,
            type: "string",
          },
          {
            label: admissibilityDisplayNames.vdl_typedeclientele,
            value: getStringFromOptionSet(
              details.vdl_typedeclientele,
              globalOptionSetsChoices.vdl_typedeclientele
            ),
            type: "string",
          },
          {
            label:
              "Dans le cas d'individus, quelle est la répartition en pourcentage de votre clientèle selon les groupes d'âge suivants ?",
            type: "comment",
            paddingTop: 6,
          },
          {
            label: admissibilityDisplayNames.vdl_prescolaire0a4ans,
            value: details.vdl_prescolaire0a4ans,
            type: "string",
          },
          {
            label: admissibilityDisplayNames.vdl_enfants5a12ans,
            value: details.vdl_enfants5a12ans,
            type: "string",
          },
          {
            label: admissibilityDisplayNames.vdl_adolescents13a17ans,
            value: details.vdl_adolescents13a17ans,
            type: "string",
          },
          {
            label: admissibilityDisplayNames.vdl_adultes18a64ans,
            value: details.vdl_adultes18a64ans,
            type: "string",
          },
          {
            label: admissibilityDisplayNames.vdl_aines65ansetplus65,
            value: details.vdl_aines65ansetplus65,
            type: "string",
          },

          {
            label:
              "Dans la mesure où vous œuvrez avec une clientèle vulnérable, quelles sont les mesures préventives que votre organisation met en place pour protéger la clientèle?",
            type: "comment",
            paddingTop: 6,
          },
          {
            label: admissibilityDisplayNames.vdl_politiquedeverification,
            value: details.vdl_politiquedeverification,
            type: "check",
          },
          {
            label: admissibilityDisplayNames.vdl_entente,
            value: details.vdl_entente,
            type: "check",
          },
          {
            label: admissibilityDisplayNames.vdl_codedeconduite,
            value: details.vdl_codedeconduite,
            type: "check",
          },
          {
            label:
              admissibilityDisplayNames.vdl_formationsurladoptiondecomportementsadequats,
            value: details.vdl_formationsurladoptiondecomportementsadequats,
            type: "check",
          },
          {
            label: admissibilityDisplayNames.vdl_codedeconduitedelorganisation,
            value: details.vdl_codedeconduitedelorganisation,
            type: "check",
          },
          {
            label:
              admissibilityDisplayNames.vdl_codeetsignatureduncontratethique,
            value: details.vdl_codeetsignatureduncontratethique,
            type: "check",
          },
          {
            label:
              admissibilityDisplayNames.vdl_verificationantecedentjudiciaire,
            value: details.vdl_verificationantecedentjudiciaire,
            type: "check",
          },
          {
            label:
              admissibilityDisplayNames.vdl_mecanismedegestionderisqueencasdedoute,
            value: details.vdl_mecanismedegestionderisqueencasdedoute,
            type: "check",
          },
          {
            label:
              admissibilityDisplayNames.vdl_utilisationdelaplateformejebenevoleca,
            value: details.vdl_utilisationdelaplateformejebenevoleca,
            type: "check",
          },
          {
            label: admissibilityDisplayNames.vdl_jeveuxplusdinformationacesujet,
            value: details.vdl_jeveuxplusdinformationacesujet,
            type: "check",
          },
        ]}
        columns={1}
        minColWidth={300}
      />

      <DisplayFieldCard
        title="Vie associative, démocratie et gouvernance"
        fields={[
          {
            label: admissibilityDisplayNames.vdl_nombrederencontresduca,
            value: details.vdl_nombrederencontresduca,
            type: "string",
          },
          {
            label: admissibilityDisplayNames.vdl_nombredepresencesalaga,
            value: details.vdl_nombredepresencesalaga,
            type: "string",
          },
          {
            label:
              admissibilityDisplayNames.vdl_datedeladerniereassembleegeneraleannuelle,
            value: dateStringToShortString(
              details.vdl_datedeladerniereassembleegeneraleannuelle
            ),
            type: "string",
          },
          {
            label:
              admissibilityDisplayNames.vdl_nombredemembresactifsauseindelorganisme,
            value: details.vdl_nombredemembresactifsauseindelorganisme,
            type: "string",
          },
          {
            label: "Votre organisme est-il membre?",
            type: "comment",
            paddingTop: 6,
          },
          {
            label: admissibilityDisplayNames.vdl_duneconcertation,
            value: details.vdl_duneconcertation,
            type: "string",
          },
          {
            label: admissibilityDisplayNames.vdl_dunregroupement,
            value: details.vdl_dunregroupement,
            type: "string",
          },
          {
            label: admissibilityDisplayNames.vdl_dunefederation,
            value: details.vdl_dunefederation,
            type: "string",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Gestion Financière"
        fields={[
          {
            label:
              admissibilityDisplayNames.vdl_produisezvousannuellementdesprevisionsbudgeta,
            value: details.vdl_produisezvousannuellementdesprevisionsbudgeta,
            type: "check",
          },
          {
            label:
              admissibilityDisplayNames.vdl_datedefindevotreexercicefinancierannuel,
            value: dateStringToShortString(
              details.vdl_datedefindevotreexercicefinancierannuel
            ),
            type: "string",
          },
          {
            label:
              admissibilityDisplayNames.vdl_vosderniersetatsfinanciersontilsfaitlobjet,
            value: details.vdl_vosderniersetatsfinanciersontilsfaitlobjet,
            type: "check",
          },
          {
            label: "Si oui, de quel type?",
            type: "comment",
            paddingTop: 6,
          },
          {
            label: admissibilityDisplayNames.vdl_audit,
            value: details.vdl_audit,
            type: "check",
          },
          {
            label: admissibilityDisplayNames.vdl_missiondexamen,
            value: details.vdl_missiondexamen,
            type: "check",
          },
          {
            label: admissibilityDisplayNames.vdl_verificationcomptable,
            value: details.vdl_verificationcomptable,
            type: "check",
          },
        ]}
        columns={1}
        minColWidth={300}
      />
      <DisplayFieldCard
        title="Documents"
        fields={[
          {
            label:
              admissibilityDisplayNames.vdl_resolutionduconseildadministration,
            value: details.vdl_resolutionduconseildadministration,
            type:  "file"
          },
          {
            label:
              admissibilityDisplayNames.vdl_reglementsgeneraux,
            value: details.vdl_reglementsgeneraux,
            type:  "file"
          },
          {
            label:
              admissibilityDisplayNames.vdl_procesverbauxdesagaetags,
            value: details.vdl_procesverbauxdesagaetags,
            type:  "file"
          },
          {
            label:
              admissibilityDisplayNames.vdl_lettrespatentesetcharte,
            value: details.vdl_lettrespatentesetcharte,
            type:  "file"
          },
          {
            label:
              admissibilityDisplayNames.vdl_rapportdactivite,
            value: details.vdl_rapportdactivite,
            type:  "file"
          },
          {
            label:
              admissibilityDisplayNames.vdl_etatsfinanciers,
            value: details.vdl_etatsfinanciers,
            type:  "file"
          },
        ]}
        columns={1}
        minColWidth={300}
      />
    </Box>
  );
};

export default Admissibility;
