import React, { useEffect, useState } from "react";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import { Box, Typography, useTheme } from "@mui/material";
import ProgramCard from "../components/pageSpecific/NewDemands/ProgramCard/ProgramCard";
import ProgramCardSkeletton from "../components/pageSpecific/NewDemands/ProgramCard/ProgramCardSkeletton";
import useUser from "../hooks/useUserData";
import { AccountRow } from "../data/Account/models";
import NewDemandDialog from "../components/pageSpecific/NewDemands/ProgramCard/NewDemandDialog/NewDemandDialog";
import { ProgramRow } from "../data/Program/models";
import ErrorMessage from "../components/common/error/ErrorMessage";
import CustomButton from "../components/common/buttons/CustomButton";
import { ServiceRow } from "../data/Service/models";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import { QueryError } from "../types";
import { useQuery } from "@tanstack/react-query";
import { getPrograms } from "../data/Program/queries";
import { getAccount } from "../data/Account/queries";
import QueryErrorMessage from "../components/common/error/QueryErrorMessage";

const NewDemands = () => {

  const programQuery = useQuery<
    ProgramRow[],
    QueryError
  >({
    queryKey: ["Programs"],
    queryFn: () => getPrograms(),
  });
  const accountQuery = useQuery<
    AccountRow,
    QueryError
  >({
    queryKey: ["Account"],
    queryFn: () => getAccount(),
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<ServiceRow>();
  const { userData } = useUser();


  const handleTypeClick = (type: ServiceRow) => {
    if (selectedType !== type) setSelectedType(type);
    setDialogOpen(true);
  };

  const isLoading = programQuery.isFetching || accountQuery.isLoading;
  const error = programQuery.error??accountQuery.error;

  if (isLoading) return <NewDemandsLoading />;

  if (error || !programQuery.data || !accountQuery.data) return <QueryErrorMessage error={error} />;

  const contactProgram = programQuery.data.find(
    (program) =>
      program.vdl_name === "Vous ne trouvez pas ce que vous cherchez ?"
  );

  return (
    <PageTitleLayout title="Nouvelle demande">
      <BoxResponsivePadding>
        {selectedType ? (
          <NewDemandDialog
            selectedType={selectedType}
            open={dialogOpen}
            setOpen={setDialogOpen}
            contacts={accountQuery.data.contacts}
          />
        ) : null}

        <Box display="grid" gap="16px">
          {programQuery.data.map((program) => {
            if (
              program.vdl_programmeid !== contactProgram?.vdl_programmeid &&
              program.services.length > 0
            ) {
              return (
                <ProgramCard
                  key={program.vdl_programmeid}
                  program={program}
                  handleTypeClick={handleTypeClick}
                />
              );
            }
          })}
          {contactProgram && contactProgram.services[0]? (
            <Box
              bgcolor="primary.main"
              padding="1rem"
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
              gap="16px"
            >
              <Typography color="common.white" variant="h6" fontSize="bold">
                {contactProgram.vdl_name}
              </Typography>
              <CustomButton
                onClick={() => handleTypeClick(contactProgram.services[0])}
                sx={{ color: "common.white", border: "1px solid white" }}
              >
                {contactProgram.services[0].vdl_name}
              </CustomButton>
            </Box>
          ) : null}
        </Box>
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};

export default NewDemands;

const NewDemandsLoading = () => {
  const theme = useTheme();
  return (
    <PageTitleLayout title={"Nouvelle demande"} isLoading>
      <Box
        sx={{
          ...theme.custom.pageCardContainer,
        }}
        display="grid"
        gap="16px"
      >
        {Array(4)
          .fill(0)
          .map((i, idx) => (
            <ProgramCardSkeletton key={idx} />
          ))}
      </Box>
    </PageTitleLayout>
  );
};
