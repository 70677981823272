import React, { useState } from "react";
import EditDemandLayout from "../EditDemandLayout";
import SimpleFields from "../../../form/SimpleForm/SimpleFields";
import handleSimpleFormSubmit from "../../../form/SimpleForm/handleSimpleFormSubmit";
import useTabs from "../../../../hooks/useTabs";
import { dynamicsFileToFileType } from "../../../../data/common/buildFile";
import { validateDateString } from "../../../../data/common/validateDateString";
import { fileToBase64 } from "../../../../data/common/fileToBase64";
import {buildForm} from "../../../form/SimpleForm/buildForm";
import { IncidentPatch, IncidentTelevisionDisplayPatch, IncidentTelevisionDisplayRow } from "../../../../data/Incident/models";
import { NewDemandFields } from "../../../../data/Incident/forms/dialog";
import { EditDemandTelevisionDisplayFields, editDemandTelevisionDisplayFields, editDemandTelevisionDisplaySections } from "../../../../data/Incident/forms/televisionDisplay";
import handleSimpleFormSrollToError from "../../../form/SimpleForm/handleSimpleFormSubmitAndScroll";

type Props = {
  demand: IncidentTelevisionDisplayRow;
  generalFields: NewDemandFields;
  update: (incident: IncidentPatch) => Promise<void>
};

const TelevisionDisplay = ({
  demand,
  generalFields,
  update
}: Props) => {

  const [fieldsGeneral, setFieldsGeneral] =
  useState<NewDemandFields>(generalFields);

  const [fields, setFields] = useState<EditDemandTelevisionDisplayFields>(
    buildForm(editDemandTelevisionDisplayFields, {
      vdl_piecejointe: dynamicsFileToFileType(demand.vdl_detailsdelademande.vdl_piecejointe),
      vdl_debutdediffusion: validateDateString(demand.vdl_detailsdelademande.vdl_debutdediffusion),
      vdl_findediffusion: validateDateString(demand.vdl_detailsdelademande.vdl_findediffusion),
      vdl_secteur1: demand.vdl_detailsdelademande.vdl_secteur1 ?? false,
      vdl_secteur2: demand.vdl_detailsdelademande.vdl_secteur2 ?? false,
      vdl_secteur3: demand.vdl_detailsdelademande.vdl_secteur3 ?? false,
      vdl_secteur4: demand.vdl_detailsdelademande.vdl_secteur4 ?? false,
      vdl_secteur5: demand.vdl_detailsdelademande.vdl_secteur5 ?? false,
      vdl_secteur6: demand.vdl_detailsdelademande.vdl_secteur6 ?? false,
    })
  );
  const { activeTab, changeTab } = useTabs();

  const onSubmit = async () => {
    const errors = handleSimpleFormSubmit(fields, setFields);

    if (errors.length > 0) {
      if (activeTab !== 1) changeTab(1);
      await handleSimpleFormSrollToError(errors[errors.length - 1]);
      return;
    }

 
    const _demand: IncidentTelevisionDisplayPatch = {
      title: fieldsGeneral.title.value,
      _vdl_contactprincipal_value: fieldsGeneral.vdl_contactprincipal.value,
      idtypedeservice: demand.idtypedeservice,
      vdl_detailsdelademande: {
        vdl_debutdediffusion: fields.vdl_debutdediffusion.value?.toISOString(),
        vdl_findediffusion: fields.vdl_findediffusion.value?.toISOString(),
        vdl_secteur1: fields.vdl_secteur1.value,
        vdl_secteur2: fields.vdl_secteur2.value,
        vdl_secteur3: fields.vdl_secteur3.value,
        vdl_secteur4: fields.vdl_secteur4.value,
        vdl_secteur5: fields.vdl_secteur5.value,
        vdl_secteur6: fields.vdl_secteur6.value,
      },
    };
    if (fields.vdl_piecejointe.changed) {
      if (fields.vdl_piecejointe.value) {
        const pieceJointeToBase = await fileToBase64(
          fields.vdl_piecejointe.value
        );
        if (pieceJointeToBase) {
          _demand.vdl_detailsdelademande.vdl_piecejointe = {
            filename: fields.vdl_piecejointe.value.name,
            content: pieceJointeToBase,
          };
        }
      }
    }

    await update(_demand)
  };

  return (
    <EditDemandLayout
      fieldsGeneral={fieldsGeneral}
      setFieldsGeneral={setFieldsGeneral}
      onSubmit={onSubmit}
      topLink={demand.vdl_typededemande.vdl_lienhautduformulaireportail}
      parentIncidentId={demand._parentcaseid_value}

    >
      <SimpleFields
        updateRefs
        fields={fields}
        setFields={setFields}
        sections={editDemandTelevisionDisplaySections}
        sectionsBackground="common.white"
      />
    </EditDemandLayout>
  );
};

export default TelevisionDisplay;
