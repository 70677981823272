import React, { useEffect } from "react";
import "./App.css";
import ThemeProvider from "./theme/theme";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import { UserProvider } from "./providers/UserProvider";
import MsalProvider from "./providers/MsalProvider";
import NewAccount from "./pages/NewAccount.page";
import { SnackbarProvider } from "./providers/SnackbarProvider";
import ConfirmationProvider from "./providers/ConfirmationProvider";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 10000,
        gcTime: 10000,
        refetchOnWindowFocus: false,
        retry: 2
      },
    },
    queryCache: new QueryCache({
      onSuccess(data, query) {
        console.log(query.queryKey);
      },
      onError: (error) => {
        console.log(error);
      },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={true} />
      <BrowserRouter>
        <MsalProvider>
          <ThemeProvider>
            <ConfirmationProvider>
              <SnackbarProvider>
                <UserProvider noInfoComponent={<NewAccount />}>
                  <AppRoutes />
                </UserProvider>
              </SnackbarProvider>
            </ConfirmationProvider>
          </ThemeProvider>
        </MsalProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;

