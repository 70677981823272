import { Box, Typography } from "@mui/material";
import BoxResponsivePadding from "../../../../common/boxes/BoxResponsivePadding";
import ClickableIcon from "../../../../common/buttons/icons/ClickableIcon";
import {
  IncidentPublicSpaceReservationRowExisting,
  IncidentPublicSpaceReservationRowNew,
} from "../../../../../data/Incident/types/publicSpace/reservation";
import {
  dateStringToLongWithDayString,
} from "../../../../../data/common/fieldToString";
import { Delete } from "@mui/icons-material";
import { SiteRow } from "../../../../../data/Plateau/models.ts";

type Props = {
  reservation:
    | IncidentPublicSpaceReservationRowExisting //Existantes
    | IncidentPublicSpaceReservationRowNew; //Nouvelles
  deleteReservation(): void;
};
const ReservationCard = ({ reservation, deleteReservation  }: Props) => {
  return (
    <BoxResponsivePadding
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="background.default"
      rowGap="16px"
      columnGap="24px"
    >
      <Typography fontWeight="bold">
        {reservation._vdl_nomdusiteareserver_value_Formatted}
      </Typography>
      <Typography>{reservation._vdl_sousplateau_value_Formatted}</Typography>

      <Box>
        <Typography fontWeight="bold">{`${dateStringToLongWithDayString(
          reservation.vdl_debutdelareservation
        )}`}</Typography>
        <Typography fontWeight="bold">{`au ${dateStringToLongWithDayString(
          reservation.vdl_findelareservation
        )}`}</Typography>
      </Box>
      <Box>
        <ClickableIcon
          backgroundColor="background.paper"
          icon={<Delete />}
          handleClick={deleteReservation}
        />
      </Box>
    </BoxResponsivePadding>
  );
};

export default ReservationCard;
