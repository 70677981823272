import React, { createContext, useContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Popover,
  Slide,
  SlideProps,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { QueryError } from "../types";
import QueryErrorMessage from "../components/common/error/QueryErrorMessage";
import QueryErrorMessageInner from "../components/common/error/QueryErrorMessageInner";

interface SnackbarContextType {
  showSnackbar: (
    message: string,
    severity?: "success" | "error" | "info" | "warning"
  ) => void;
  showErrorSnackbar: (
    message: string,
    severity: "error",
    queryError?: QueryError
  ) => void;
}

export const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

type Props = {
  children: React.ReactNode;
};

export const SnackbarProvider = ({ children }: Props) => {
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [queryError, setQueryError] = useState<QueryError | undefined>();
  const [severity, setSeverity] = useState<
    "success" | "error" | "info" | "warning"
  >("success");

  const showSnackbar = (
    message: string,
    severity: "success" | "error" | "info" | "warning" = "success"
  ) => {
    setOpenDialog(false);
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const showErrorSnackbar = (
    message: string,
    severity: "error",
    queryError?: QueryError
  ) => {
    setOpenDialog(false);
    setMessage(message);
    setSeverity(severity);
    setQueryError(queryError);
    setOpen(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setOpen(false);
    setMessage("");
    setQueryError(undefined);
  };

  const action = queryError ? (
    <React.Fragment>
      <Button
        color="primary"
        size="small"
        onClick={() => setOpenDialog(!openDialog)}
      >
        ERREUR
      </Button>
      <IconButton
        size="small"
        aria-label="fermer alerte"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignContent="start"
          padding="8px 4px 0px 8px"
        >
          <Box padding="8px">
            <QueryErrorMessageInner error={queryError} />
          </Box>
          <Box paddingTop="4px">
            <IconButton
              aria-label="fermer dialogue"
              onClick={() => setOpenDialog(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  ) : null;

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar: showSnackbar,
        showErrorSnackbar: showErrorSnackbar,
      }}
    >
      {children}
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity={severity}
          sx={{ width: "100%" }}
          onClose={handleClose}
          action={action}
        >
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}
