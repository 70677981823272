import { ErrorType, OperationType, QueryError } from "../../types";
import fetchMsal from "../fetchMsal";
import { ActivityTypes } from "./models";

const getActivityTypes = async (): Promise<ActivityTypes> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_typesactivite`;

    const result = await fetchMsal("GET", endpoint);

    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    }

    return await result.json();
  } catch (e) {
    throw QueryError.TransformUnknown(
      e,
      "Type d'activité",
      OperationType.RETRIEVEMULTIPLE,
    );
  }
};

export { getActivityTypes };
