import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  useTheme,
  Skeleton,
  styled,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import SectionLoading from "../../common/loading/SectionLoading";
import CenteredBox from "../../common/boxes/CenteredBox";

type Props = {
  children?: React.ReactNode;
  isLoading?: boolean;
  title: string;
  loadingSpinner?: Boolean;
  globalLoading?: Boolean;
  underTitleComponent?: JSX.Element;
  sideTitleComponent?: JSX.Element;
};

const PageTitleLayout = ({
  children,
  title,
  isLoading,
  underTitleComponent,
  sideTitleComponent,
  loadingSpinner,
  globalLoading,
}: Props) => {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: "bg.light" }}>
      <PageTitleTopSection
        title={title}
        isLoading={isLoading}
        underTitleComponent={underTitleComponent}
        sideTitleComponent={sideTitleComponent}
      />
      {loadingSpinner ? (
        <Box paddingTop={"1rem"}>
          <SectionLoading />
        </Box>
      ) : (
        <Box maxWidth={2000}>{children}</Box>
      )}
      {globalLoading ? (
        <Box
          position="fixed"
          top={0}
          left={0}
          height="100%"
          width="100%"
          bgcolor="RGBA(255,255,255,0.4)"
          zIndex={1000}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding="32px"
            borderRadius="50%"
            minHeight="180px"
            minWidth="180px"
          >
            <CircularProgress id="circularProgress" color="primary" />
            <Typography
              sx={{
                padding: "8px",
                background:
                  "linear-gradient(transparent 0%, white 20%, white 80%, transparent 100%);",
                borderRadius: "20px",
              }}
            >
              Chargement...
            </Typography>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

type TopSectionProps = {
  title: string;
  underTitleComponent?: JSX.Element;
  sideTitleComponent?: JSX.Element;
  isLoading?: boolean;
};

const PageTitleTopSection = ({
  title,
  underTitleComponent,
  sideTitleComponent,
  isLoading,
}: TopSectionProps) => {
  const theme = useTheme();
  const [isSticky, setIsSticky] = useState(false);
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleScroll = () => {
    const newIsSticky = window.scrollY > 70;
    setIsSticky(newIsSticky);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "secondary.main",
        color: "#fafafa",
        position: "sticky",
        top: "60px",
        zIndex: 10,
        transition: "all 0.2s ease-in",
        display: "flex",
        flexDirection: "column",
        padding: `${isSticky ? "30px" : "60px"} 10px ${isSticky ? "20px" : "30px"} 30px`,
        justifyContent: "center",
        gap: "6px",
        [theme.breakpoints.down("sm")]: {
          padding: `${isSticky ? "16px" : "20px"} 15px ${isSticky ? "10px" : "20px"} 26px`,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          padding: 0,
          alignItems: "center",
          rowGap: "6px",
          columnGap: "20px",
        }}
      >
        <Typography variant="h4">
          {isLoading ? <Skeleton width={"325px"} height={"60px"} /> : title}
        </Typography>
        <Box
          sx={{
            opacity: onlySmallScreen && isSticky ? 0 : 1,
            maxHeight: onlySmallScreen && isSticky ? 0 : "500px",
            overflow: "hidden",
            transition: "all 0.2s ",
          }}
        >
          {sideTitleComponent ? sideTitleComponent : null}
        </Box>
      </Box>
      <Box
        sx={{
          opacity: isSticky ? 0 : 1,
          maxHeight: isSticky ? 0 : "500px",
          overflow: "hidden",
          transition: "all 0.2s",
        }}
      >
        {underTitleComponent ? underTitleComponent : null}
      </Box>
    </Box>
  );
};

export default PageTitleLayout;
