import fetchWithMsal from "../fetchWithMsal";
import { ContactRow, ContactPatch, ContactPost, CreateUser } from "./models";
import { GetResponse, PatchResponse, PostResponse } from "../common/types";
import { ErrorType, OperationType, QueryError } from "../../types";
import { errorHandler } from "../../components/common/error/errorHandler";
import fetchMsal from "../fetchMsal";

export const getContact = async (contactid?: string): Promise<ContactRow> => {
  try {
    if (!contactid)
      throw new QueryError(
        ErrorType.NOT_FOUND,
        "Url invalide, impossible de récupérer le contact.",
      );

    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_contact/${contactid}`;

    const result = await fetchMsal("GET", endpoint);

    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    }

    return await result.json();
  } catch (e) {
    throw QueryError.TransformUnknown(e, "Contact", OperationType.RETRIEVE, contactid)
  }
};

export const getUser = async (): Promise<ContactRow | null> => {
  try {

    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_user/`;

    const result = await fetchMsal("GET", endpoint);

    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status === 204) {
      return null
    }

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    }

    return await result.json();
  } catch (e) {
    throw QueryError.TransformUnknown(e, "Utilisateur", OperationType.RETRIEVE)
  }
};

export const createUser = async (contactAccount : CreateUser): Promise<string> => {
  try {

    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/create_user/`;

    const result = await fetchMsal("POST", endpoint, contactAccount);

    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    }

    return await result.text();
  } catch (e) {
    throw QueryError.TransformUnknown(e, "Utilisateur", OperationType.CREATE)
  }
};

export const createContact = async (
  contact: ContactPost
): Promise<string | undefined> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/create_contact/`;
    const result = await fetchMsal("POST", endpoint, contact);
    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    };

    return await result.text();
  } catch (e) {
    throw QueryError.TransformUnknown(e, "Contact", OperationType.CREATE);
  }
};

export const updateContact = async (
  contactid: string,
  contact: ContactPatch
): Promise<void> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/update_contact/${contactid}`;
    const result = await fetchMsal("PATCH", endpoint, contact);
    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    };

  } catch (e) {
    throw QueryError.TransformUnknown(
      e,
      "Contact",
      OperationType.UPDATE,
      contactid
    );
  }
};

export const sendInvite = async (contactid: string): Promise<PatchResponse> => {
  try {
    const data = {
      targetId: contactid,
    };
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/send_invite`;
    const result = await fetchWithMsal("POST", endpoint, data);
    if (result.StatusCode === 200) {
      return { status: "success" };
    } else {
      throw new Error("Erreur dans l'exécution");
    }
  } catch (e) {
    return errorHandler(e, "Erreur dans la modification du contact");
  }
};

/*
const setValue = <K extends keyof IContact>(key: K, value: IContact[K]) => {
      const _user: IContact = Object.assign({}, tempData);
      _user[key] = value;
      return _user;
    };
    */
