import { Box } from "@mui/material";
import { IncidentTelevisionDisplayRow } from "../../../../../data/Incident/models";
import { televisionDisplayDisplayNames } from "../../../../../data/Incident/displayNames";
import DisplayFieldCard from "../../../../common/cards/DisplayFieldCard";
import { dateStringToShortString } from "../../../../../data/common/fieldToString";

type Props = {
  demand: IncidentTelevisionDisplayRow;
};

const TelevisionDisplay = ({ demand }: Props) => {
  const details = demand.vdl_detailsdelademande;
  return (
    <Box display="grid" gap="16px">
      <DisplayFieldCard
        title="Informations spécifiques"
        fields={[
          {
            label: televisionDisplayDisplayNames.vdl_piecejointe,
            value: details.vdl_piecejointe,
            type: "file",
          },
          {
            label: televisionDisplayDisplayNames.vdl_debutdediffusion,
            value: dateStringToShortString(details.vdl_debutdediffusion),
            type: "string",
          },
          {
            label: televisionDisplayDisplayNames.vdl_findediffusion,
            value: dateStringToShortString(details.vdl_findediffusion),
            type: "string",
          },
          {
            label: televisionDisplayDisplayNames.vdl_secteur1,
            value: details.vdl_secteur1,
            type: "check",
          },
          {
            label: televisionDisplayDisplayNames.vdl_secteur2,
            value: details.vdl_secteur2,
            type: "check",
          },
          {
            label: televisionDisplayDisplayNames.vdl_secteur3,
            value: details.vdl_secteur3,
            type: "check",
          },
          {
            label: televisionDisplayDisplayNames.vdl_secteur4,
            value: details.vdl_secteur4,
            type: "check",
          },
          {
            label: televisionDisplayDisplayNames.vdl_secteur5,
            value: details.vdl_secteur5,
            type: "check",
          },
          {
            label: televisionDisplayDisplayNames.vdl_secteur6,
            value: details.vdl_secteur6,
            type: "check",
          },
        ]}
        columns={1}
        minColWidth={280}
      />
    </Box>
  );
};

export default TelevisionDisplay;
