import { AccountInfo } from "@azure/msal-browser";
import { ContactRow } from "./data/Contact/models";
import dayjs, { Dayjs } from "dayjs";
import 'dayjs/locale/fr'; 
import { DateView } from "@mui/x-date-pickers";
import {
  Address,
  AddressSuggestion,
  AddressSuggestionExisting,
  AddressSuggestionSelect,
  AddressSuggestionUnion,
  Base64File,
} from "./data/common/types";
import { FormLabel, FormLabelProps } from "@mui/material";
import { FC } from "react";

export type FieldNameValue = {
  displayName: string;
  value?: string | null;
};

export type TableFields = {
  [key: string]: FieldNameValue;
};

export type EntityOptionSetChoices<Keys extends string> = {
  [key in Keys]: OptionSetChoices;
};
export type OptionSetChoices = { [key: number]: string };

export type DisplayFieldWithSpan = {
  span?: number;
  label: string;
  paddingTop?: number;
} & (
  | {
      value: string | undefined | null;
      type: "string";
    }
  | {
      value?: boolean | null;
      type: "check";
    }
  | {
      value?: Base64File | null;
      type: "file";
    }
  | {
      type: "comment";
    }
);

export type DisplayNames<T> = Record<keyof T, string>;

export type Role = "Administrateur" | "Employé" | "Usager";

export type NewUser = {
  newUser: true;
  msalInfo: AccountInfo;
};
export type User = {
  newUser: false;
  msalInfo: AccountInfo;
} & ContactRow;

export type RequiredCondition = {
  validator: (fields: FormFields) => boolean;
  error?: string;
};

export type BaseInput<T = undefined> = {
  label: string;
  error: string;
  helperText?: string | null;
  required?: boolean;
  fillLine?: boolean;
  disabledCondition?: (fields: FormFields) => boolean;
  requiredCondition?: RequiredCondition;
  onChangeEvent?: (fields: FormFields) => FormFields | undefined;
  fieldRef?: HTMLElement;
  span?: number;
};

export type TextInput = {
  type: "text";
  value: string;
  maxLength?: number;
  validator?: (value: string) => string | undefined;
} & BaseInput;

export type NumberInput = {
  type: "number";
  value: number | null;
  max?: number;
  min?: number;
  step?: number;
} & BaseInput;

export type CurrencyInput = {
  type: "currency";
  value: string;
  max?: number;
  min?: number;
} & BaseInput;

export type DateTimeInput = {
  type: "datetime";
  value: Dayjs | null;
  shouldDisableDate?: (day: dayjs.Dayjs) => boolean;
  shouldDisabledMonth?: (day: dayjs.Dayjs) => boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: Dayjs | ((fields: FormFields) => Dayjs | undefined) | undefined;
  maxDate?: Dayjs | ((fields: FormFields) => Dayjs | undefined) | undefined;
  views?: DateView[];
  format?: string;
} & BaseInput;

export type TimeInput = {
  type: "time";
  value: Dayjs | null;
} & BaseInput;

export type DateInput = {
  type: "date";
  value: Dayjs | null;
  shouldDisableDate?: (day: dayjs.Dayjs) => boolean;
  shouldDisabledMonth?: (day: dayjs.Dayjs) => boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: Dayjs | ((fields: FormFields) => Dayjs | undefined) | undefined;
  maxDate?: Dayjs | ((fields: FormFields) => Dayjs | undefined) | undefined;
  views?: DateView[];
  format?: string;
} & BaseInput;

export type MultilineInput = {
  type: "multiline";
  value: string;
  maxLength?: number;
  rows?: {
    min: number;
    max: number;
  };
  validator?: (value: string) => string | undefined;
} & BaseInput;

export type PhoneInput = {
  type: "phone";
  value: string;
} & BaseInput;

export type EmailInput = {
  type: "email";
  value: string;
} & BaseInput;

export type AutoCompleteInput = {
  type: "autocomplete";
  choices: DropDownChoice[];
  value: string;
  freeSolo?: boolean;
  maxLength?: number;
  validator?: (value: string) => string | undefined;
} & BaseInput;

export type AddressInput = {
  type: "address";
  value: null | AddressSuggestion;
  changed?: boolean;
} & BaseInput;

export type DropDownOptionSetInput = {
  type: "optionset";
  choices: DropDownChoice[];
  value: string | null;

  //validator?: (value: string) => string | undefined;
} & BaseInput;

export type DropDownMultipleInput = {
  type: "multipleoptionset";
  choices: DropDownChoice[];
  value: string[];
  //validator?: (value: string) => string | undefined;
} & BaseInput;

export type OptionSetInput = {
  type: "options";
  choices: OptionSetChoices;
  value: number | null;
  clearable?: boolean;
  //validator?: (value: string) => string | undefined;
} & BaseInput;

export type MultipleOptionSetInput = {
  type: "multipleoptions";
  choices: OptionSetChoices;
  value: number[];
  //validator?: (value: string) => string | undefined;
} & BaseInput;

export type DropDownLookUpInput = {
  type: "lookup";
  choices: DropDownChoiceWithLabel[];
  value: string | null;
  //validator?: (value: string) => string | undefined;
} & BaseInput;

export type DropDownChoiceWithLabel = { label: string; value: string };
export type DropDownChoice = { value: string };

export type ObjectDropDownInput<T> = {
  type: "dropdownobj";
  choices: {
    items: [T];
    displayField: string;
  };
  value?: T | undefined;
  //validator?: (value: string) => string | undefined;
} & BaseInput;

export type CheckBoxInput = {
  type: "checkbox";
  value: boolean;
  reversed?: boolean;
} & BaseInput;

export type SectionCheckBoxInput = {
  type: "sectioncheckbox";
  value: boolean;
  span?: undefined;
  label?: undefined;
  fillLine?: undefined;
  disabledCondition?: undefined;
  required?: undefined;
  onChangeEvent?: undefined;
  error?: undefined;
  fieldRef?: undefined;
  requiredCondition?: undefined;
};

export type RadioInput = {
  type: "radio";
  choices: DropDownChoice[];
  value?: string;
} & BaseInput;

export type FileExtension =
  | ".gif"
  | ".png"
  | ".PNG"
  | ".jpeg"
  | ".jpg"
  | ".pdf"
  | ".docx"
  | ".doc";

export type FileInput = {
  type: "file";
  value: File | null;
  accept?: FileExtension[];
  changed?: boolean;
  maxSize?: number;
} & BaseInput;

export type MultipleFilesInput = {
  type: "multiplefiles";
  value: File[];
  accept?: FileExtension[];
  maxLength: number;
  changed?: boolean;
  maxSize?: number;
  labelComponent?: ({ label }: { label: string }) => JSX.Element;
} & BaseInput;

export type CommentInput = {
  type: "comment";
  fillLine?: boolean;
  value: string;
  span?: number;
  removeMarginTop?: boolean;
  disabledCondition?: undefined;
  required?: undefined;
  onChangeEvent?: undefined;
  error?: undefined;
  fieldRef?: undefined;
  requiredCondition?: undefined;
  helperText?: undefined;
};
/*
export type CustomInput<ValueType> = PartialCustomInput<ValueType> & {
  component?: React.ComponentType<PartialCustomInput<ValueType>>;
};

export type PartialCustomInput<ValueType> = {
  type: "custom";
  value?: ValueType;
  validator?: (value: ValueType) => string | undefined;
} & BaseInput;
*/

export type FormField =
  | DropDownOptionSetInput
  | OptionSetInput
  | MultipleOptionSetInput
  | DropDownLookUpInput
  | TextInput
  | NumberInput
  | CheckBoxInput
  | PhoneInput
  | EmailInput
  | RadioInput
  | MultilineInput
  | DateTimeInput
  | DateInput
  | AutoCompleteInput
  | CurrencyInput
  | FileInput
  | CommentInput
  | AddressInput
  | MultipleFilesInput
  | TimeInput
  | DropDownMultipleInput;
//| SectionCheckBoxInput;

export type FormFields = {
  [key: string]: FormField;
};
export type FormFieldsPartial = {
  [key: string]: Partial<FormField>;
};

export type FormSection<T extends FormFields> = {
  fields: (keyof T)[];
  title?: string;
  description?: string;
  activeRule?: {
    field: {
      [K in keyof T]: K extends string
        ? T[K] extends { type: "sectioncheckbox" }
          ? K
          : never
        : never;
    }[keyof T];
  };
  columns?: {
    count: number;
    minWidth: number;
  };
};

export type FieldValues<T extends FormFields> = {
  [K in keyof T as T[K]["type"] extends "comment" ? never : K]: T[K]["value"];
};

export type FormFieldsBuilder<T extends FormFields> = {
  [K in keyof T as T[K]["type"] extends "comment" ? never : K]: Partial<
    T[K]
  > & { value: T[K]["value"] };
};

export enum ErrorType {
  NOT_FOUND = 404,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  INTERNAL = 500,
  UNKNOWN = 999,
}

export enum OperationType {
  CREATE = "Création",
  UPDATE = "Mise à jour",
  DELETE = "Supprimer",
  RETRIEVE = "Récupération",
  RETRIEVEMULTIPLE = "Récupération multiple",
}

export class QueryError extends Error {
  type: ErrorType;
  entity: string | undefined;
  operation: OperationType | undefined;
  record: string | undefined;

  constructor(
    type: ErrorType,
    message: string,
    entity?: string | undefined,
    operation?: OperationType,
    record?: string,
    error?: Error
  ) {
    const options = error ? { cause: error } : undefined;
    super(message ?? "Erreur inconnue", options);
    this.name = this.constructor.name;
    this.type = type ?? ErrorType.UNKNOWN;
    this.entity = entity;
    this.operation = operation;
    this.record = record;
    Object.setPrototypeOf(this, QueryError.prototype);
  }
  static TransformUnknown = (
    e: unknown,
    entity?: string,
    operation?: OperationType,
    record?: string
  ) => {
    if (e instanceof QueryError) {
      return new QueryError(
        e.type,
        e.message,
        e.entity ?? entity,
        e.operation ?? operation,
        e.record ?? record
      );
    }
    if (typeof e === "string")
      return new QueryError(ErrorType.UNKNOWN, e, entity, operation, record);
    if (e instanceof Error)
      return new QueryError(
        ErrorType.UNKNOWN,
        "Erreur inconnue",
        entity,
        operation,
        record,
        e
      );
    return new QueryError(
      ErrorType.UNKNOWN,
      "Erreur inconnue",
      entity,
      operation,
      record
    );
  };
}

export type TabContent = {
  label: string;
  component: React.ReactNode;
};
