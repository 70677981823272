import {
  CheckBoxInput,
  FormFields,
  FormSection,
  PhoneInput,
  TextInput,
  DropDownOptionSetInput,
  DropDownLookUpInput,
  RadioInput,
} from "../../../types";
import {demandDisplayNames} from "../displayNames";

export type NewDemandFields = {
  title: TextInput;
  vdl_contactprincipal: DropDownLookUpInput;
};

const newDemandFields: NewDemandFields = {
  title: {
    type: "text",
    error: "",
    value: "",
    required: true,
    fillLine: true,
    label: demandDisplayNames.title,
  },
  vdl_contactprincipal: {
    type: "lookup",
    error: "",
    value: null,
    choices: [],
    required: true,
    fillLine: true,
    label: demandDisplayNames.vdl_contactprincipal,
  },
};

export type NewDemandWithTypeFields =  {
  vdl_typededemande: DropDownLookUpInput
} & NewDemandFields;

const newDemandWithTypeFields: NewDemandWithTypeFields = {
  vdl_typededemande: {
    type: "lookup",
    error: "",
    value: null,
    choices: [],
    required: true,
    fillLine: true,
    label: demandDisplayNames.vdl_typededemande,
  },
  ...newDemandFields
};

export { newDemandFields, newDemandWithTypeFields };
