import { Box, Typography } from "@mui/material";
import { HistoryStatusRow } from "../../../../../../data/History/models";

type Props = {
  activity: HistoryStatusRow;
};

const ActivityCardStatus = ({ activity }: Props) => {
  return (
    <Box>
      <Typography color="text.secondary">
        <Typography color="text.secondary" component="span">
          Le statut de la requête a été modifié pour
        </Typography>
        <Typography color="text.secondary" component="span" fontWeight="bold">
          {" " + activity.vdl_nouvelleraisondestatut}
        </Typography>
      </Typography>
    </Box>
  );
};

export default ActivityCardStatus;
