import { errorHandler } from "../../components/common/error/errorHandler";
import { ErrorType, OperationType, QueryError } from "../../types";
import { GetResponse } from "../common/types";
import fetchMsal from "../fetchMsal";
import usefetchWithMsal from "../fetchWithMsal";
import { AccountTypeRow } from "./models";

const getAccounTypes = async (): Promise<AccountTypeRow[]> => {
  try {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/get_typeorganisation`;

    const result = await fetchMsal("GET", endpoint);

    if (!result) throw new QueryError(ErrorType.UNKNOWN, "Erreur inconnue.");

    if (result.status !== 200) {
      throw new QueryError(ErrorType.INTERNAL, await result.text());
    }

    return await result.json();

  } catch (e) {
    throw QueryError.TransformUnknown(
        e,
        "Type d'organisation",
        OperationType.RETRIEVEMULTIPLE
      );
  }
};

export { getAccounTypes };
