import React, { useEffect, useState } from "react";
import PageTitleLayout from "../components/layouts/PageLayouts/PageTitleLayout";
import { Box, Grid, useTheme } from "@mui/material";
import DataCard from "../components/common/cards/DataCard/DataCard";
import Edit from "@mui/icons-material/Edit";
import DataCardSkeleton from "../components/common/cards/DataCard/DataCardSkeleton";
import { QueryError, TableFields } from "../types";
import accountDisplayNames from "../data/Account/displayNames";
import LinkIcon from "../components/common/buttons/icons/LinkIcon";
import useUser from "../hooks/useUserData";
import Add from "@mui/icons-material/Add";
import AccountContactsCard from "../components/pageSpecific/Account/AccountContactsCard/AccountContactsCard";
import AccountContactsCardSkeleton from "../components/pageSpecific/Account/AccountContactsCard/AccountContactsCardSkeleton";
import AccountAdressCard from "../components/pageSpecific/Account/AccountAdressCard/AccountAdressCard";
import { ContactRow } from "../data/Contact/models";
import { AccountRow } from "../data/Account/models";
import PrivateComponent from "../components/common/auth/PrivateComponent";
import { RouterRoutes } from "../routes/routes";
import { getStringFromField, getStringFromOptionSet } from "../data/common/fieldToString";
import ErrorMessage from "../components/common/error/ErrorMessage";
import BoxResponsivePadding from "../components/common/boxes/BoxResponsivePadding";
import { useQuery } from "@tanstack/react-query";
import { getAccount } from "../data/Account/queries";
import QueryErrorMessage from "../components/common/error/QueryErrorMessage";
import { globalOptionSetsChoices } from "../data/globalOptionSetChoices";

type AccountDisplayData = {
  accountInfo: TableFields;
  address1: TableFields;
  sameAddress?: boolean;
  address2: TableFields;
  contacts: ContactRow[];
};

const AccountPage = () => {
  const theme = useTheme();
  const { data, error, isLoading, isFetching } = useQuery<
    AccountRow,
    QueryError
  >({
    queryKey: ["Account" ],
    queryFn: () => getAccount(),
  });

  if (isLoading || isFetching) return <AccountPageLoading />;

  if (error || !data) return <QueryErrorMessage error={error} />;

  return (
    <PageTitleLayout title="Mon organisation">
      <Box
        sx={{
          ...theme.custom.pageCardContainer,
        }}
      >
        <Grid container columnGap={"1rem"} rowGap={"1rem"}>
          <Grid
            item
            xs={12}
            height={"100%"}
            xl
            container
            rowGap={"0.5rem"}
            sx={{ backgroundColor: "#ffffff" }}
          > 
            <DataCard
              title="Informations générales"
              displayFields={{
                name: {
                  displayName: accountDisplayNames.name,
                  value: data.name
                },
                vdl_autresnomorganisme: {
                  displayName: accountDisplayNames.vdl_autresnomorganisme,
                  value: data.vdl_autresnomorganisme
                },
                _vdl_typedorganisation_value_Formatted: {
                  displayName: accountDisplayNames._vdl_typedorganisation_value_Formatted,
                  value: data._vdl_typedorganisation_value_Formatted
                },
                vdl_neq: {
                  displayName: accountDisplayNames.vdl_neq,
                  value: data.vdl_neq
                },
                telephone1: {
                  displayName: accountDisplayNames.telephone1,
                  value: data.telephone1
                },
                emailaddress1: {
                  displayName: accountDisplayNames.emailaddress1,
                  value: data.emailaddress1,
                },
                websiteurl: {
                  displayName: accountDisplayNames.websiteurl,
                  value: data.websiteurl
                },
                vdl_pagefacebook: {
                  displayName: accountDisplayNames.vdl_pagefacebook,
                  value: data.vdl_pagefacebook
                }
              }}
              emptyTextPlaceHolder="---"
              icon={
                <PrivateComponent roleMinimum="Employé">
                  <LinkIcon
                    icon={<Edit />}
                    to={RouterRoutes.EditAccount}
                    backgroundColor="background.default"
                  />
                </PrivateComponent>
              }
            /> 
            <AccountAdressCard
              title1="Adresse du siège social"
              title2="Adresse de correspondance"
              adress1={{
                vdl_secteursiegesocial: {
                  displayName: accountDisplayNames.vdl_secteursiegesocial,
                  value: getStringFromOptionSet(data.vdl_secteursiegesocial, globalOptionSetsChoices.vdl_secteursiegesocial)
                },
                address1_line1: {
                  displayName: accountDisplayNames.address1_line1,
                  value: data.address1_line1
                },
                address1_line2: {
                  displayName: accountDisplayNames.address1_line2,
                  value: data.address1_line2
                },
                address1_city: {
                  displayName: accountDisplayNames.address1_city,
                  value: data.address1_city
                },
                address1_stateorprovince: {
                  displayName: accountDisplayNames.address1_stateorprovince,
                  value: data.address1_stateorprovince
                },
                address1_postalcode: {
                  displayName: accountDisplayNames.address1_postalcode,
                  value: data.address1_postalcode
                }
              }}
              adress2={{
                address2_line1: {
                  displayName: accountDisplayNames.address2_line1,
                  value: data.address2_line1
                },
                address2_line2: {
                  displayName: accountDisplayNames.address2_line2,
                  value: data.address2_line2
                },
                address2_city: {
                  displayName: accountDisplayNames.address2_city,
                  value: data.address2_city
                },
                address2_stateorprovince: {
                  displayName: accountDisplayNames.address2_stateorprovince,
                  value: data.address2_stateorprovince
                },
                address2_postalcode: {
                  displayName: accountDisplayNames.address2_postalcode,
                  value: data.address2_postalcode
                }
              }}
              sameAddress={
                data.vdl_adresseidentique ? true : false
              }
            />
          </Grid>
          <Grid item xs={12} xl={4.5}>
            <AccountContactsCard
              icon={
                <PrivateComponent roleMinimum="Employé">
                  <LinkIcon
                    icon={<Add />}
                    to={RouterRoutes.NewContact}
                    backgroundColor="background.default"
                  />
                </PrivateComponent>
              }
              title={"Contacts"}
              contacts={data.contacts}
            />
          </Grid>
        </Grid>
      </Box>
    </PageTitleLayout>
  );
};

export default AccountPage;

const AccountPageLoading = () => {
  return (
    <PageTitleLayout isLoading title="Mon organisation">
      <BoxResponsivePadding>
        <Grid container gap={2}>
          <Grid item xs={12} xl>
            <DataCardSkeleton icon displayFieldsCount={8} />
          </Grid>
          <Grid item xs={12} xl={4.5}>
            <AccountContactsCardSkeleton contactsCount={3} />
          </Grid>
        </Grid>
      </BoxResponsivePadding>
    </PageTitleLayout>
  );
};
