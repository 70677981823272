import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import DemandeCardNew from "../DemandCard/DemandeCardNew";
import { IncidentParentRow } from "../../../../data/Incident/models";

type Props = {
  demand: IncidentParentRow;
};

const DemandRegroupment = ({ demand }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  return (
    <Accordion
      disableGutters
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={{
        backgroundColor: "background.default",
        boxShadow: "none",
        borderRadius: "4px",
        '&:before': {
          display: 'none',
      }
      }}
    >
      <AccordionSummary
        sx={{
          padding: 0,
          marginTop: 0,
          "& .MuiAccordionSummary-content": { marginTop: 0 },
        }}
      >
        <Box width="100%">
          <DemandeCardNew demand={demand} redirectHome />
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: "grid",
          gap: "16px",
          borderRadius: "4px",
        }}
      >
        {demand.incident_parent_incident.map((d, index) => (
          <DemandeCardNew key={index} demand={d} redirectHome />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default DemandRegroupment;
