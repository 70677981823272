import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { NewUserFields } from "../../../data/Contact/forms/newUser";
import { getStringFromOptionSet } from "../../../data/common/fieldToString";
import { contactOptionSetsChoices } from "../../../data/Contact/optionSetChoices";
import { globalOptionSetsChoices } from "../../../data/globalOptionSetChoices";
import { NewAccountDisplayFields } from "../../../data/Account/forms/newAccount";

type Props = {
  accountFields: NewAccountDisplayFields;
  contactFields: NewUserFields;
};

const Step3 = ({ contactFields, accountFields }: Props) => {
  return (
    <Grid container mt={2}>
      {/* Section - Mes informations */}
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "#005EA6",
            color: "white",
            padding: "0.5rem",
          }}
        >
          <Typography variant="h6" gutterBottom style={{ margin: 0 }}>
            Mes informations
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ backgroundColor: "#F4F4F4", padding: "1rem" }}
        >
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            Titre
          </Typography>
          <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
            {getStringFromOptionSet(
              contactFields.vdl_salutation.value,
              contactOptionSetsChoices.vdl_salutation
            )}
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            Prénom
          </Typography>
          <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
            {contactFields.firstname.value}
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            Nom
          </Typography>
          <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
            {contactFields.lastname.value}
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            Fonction au sein de l'organisation
          </Typography>
          <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
            {getStringFromOptionSet(
              contactFields.vdl_fonction.value,
              contactOptionSetsChoices.vdl_fonction
            )}
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            Courriel
          </Typography>
          <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
            {contactFields.emailaddress1.value}
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            N° de téléphone principal
          </Typography>
          <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
            {contactFields.telephone1.value}
          </Typography>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            Autre téléphone
          </Typography>
          <Typography variant="body2">
            {contactFields.telephone2.value !== ""
              ? contactFields.telephone2.value
              : "---"}
          </Typography>
        </Grid>
      </Grid>

      {/* Section - Mon organisation */}
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "#005EA6",
            color: "white",
            padding: "0.5rem",
          }}
        >
          <Typography variant="h6" gutterBottom style={{ margin: 0 }}>
            Mon organisation
          </Typography>
        </Grid>

        {/* Informations générales */}
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: "#F4F4F4", padding: "1rem" }}
            >
              <Typography
                variant="h6"
                mb={-2}
                style={{ color: "#005EA6", fontWeight: "bold" }}
              >
                Informations générales
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ backgroundColor: "#F4F4F4", padding: "1rem" }}
            >
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Nom officiel de l'organisation
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
                {accountFields.name.value}
              </Typography>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Type d'organisation
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
                {
                  accountFields.vdl_typedorganisation.choices.find(
                    (choice) =>
                      choice.value === accountFields.vdl_typedorganisation.value
                  )?.label
                }
              </Typography>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Numéro de téléphone
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
                {accountFields.telephone1.value}
              </Typography>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Site internet
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
                {accountFields.websiteurl.value.length > 0
                  ? accountFields.websiteurl.value
                  : "---"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ backgroundColor: "#F4F4F4", padding: "1rem" }}
            >
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Autres noms utilisés au Québec
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
                {accountFields.vdl_autresnomorganisme.value.length > 0
                  ? accountFields.vdl_autresnomorganisme.value
                  : "---"}
              </Typography>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                NEQ ou UAI
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
                {accountFields.vdl_neq.value.length > 0
                  ? accountFields.vdl_neq.value
                  : "---"}
              </Typography>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Courriel
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
                {accountFields.emailaddress1.value}
              </Typography>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Page Facebook
              </Typography>
              <Typography variant="body2" style={{ marginBottom: "0.5rem" }}>
                {accountFields.vdl_pagefacebook.value.length > 0
                  ? accountFields.vdl_pagefacebook.value
                  : "---"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Section - Adresse du siège social */}
      <Grid
        item
        xs={12}
        md={6}
        style={{ backgroundColor: "#F4F4F4", padding: "1rem" }}
      >
        <Typography
          variant="h6"
          mb={2}
          style={{ color: "#005EA6", fontWeight: "bold" }}
          gutterBottom
        >
          Adresse du siège social
        </Typography>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Secteur où est situé le siège social
        </Typography>
        <Typography variant="body2">
          {getStringFromOptionSet(
            accountFields.vdl_secteursiegesocial.value,
            globalOptionSetsChoices.vdl_secteursiegesocial
          )}
        </Typography>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Adresse
        </Typography>
        <Typography variant="body2">
          {accountFields.siegeSocialAddress.value?.text}
          {/* {accountFields.registeredAddressSearch.value} */}
        </Typography>
      </Grid>

      {/* Section - Adresse de correspondance */}
      <Grid
        item
        xs={12}
        md={6}
        style={{ backgroundColor: "#F4F4F4", padding: "1rem" }}
      >
        <Typography
          variant="h6"
          mb={2}
          style={{ color: "#005EA6", fontWeight: "bold" }}
          gutterBottom
        >
          Adresse de correspondance
        </Typography>

        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Adresse
        </Typography>
        <Typography variant="body2">
          {accountFields.vdl_adresseidentique.value
            ? "Utilise l'adresse du siège social"
            : ""}
        </Typography>
        <Typography variant="body2">
          {accountFields.vdl_adresseidentique.value
            ? accountFields.siegeSocialAddress.value?.text
            : accountFields.correspondanceAddress.value?.text}
          {/* {accountFields.mailingAddressSearch.value} */}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Step3;
