import React from "react";
import { DropDownLookUpInput } from "../../../types";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

type Props = {
  field: DropDownLookUpInput;
  fieldName: string;
  handleChange: (field: DropDownLookUpInput) => void;
  disabled: boolean;
};

const FormLookupInput = ({
  field,
  fieldName,
  handleChange,
  disabled,
}: Props) => {
  
  function handleInputChange(event: SelectChangeEvent) {
    const { value } = event.target;
    let _field = { ...field };
    if (field.required && value === "") {
      _field.error = "";
    }
    _field.value = value;
    handleChange(_field);
  }
  function handleEmpty() {
    let _field = { ...field };
    _field.value = null;
    handleChange(_field);
  }
  function handleBlur(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) {
    let _field = { ...field };
    _field.error = "";
    if (field.required && (!field.value || field.value === "")) {
      _field.error = "Ce champ est requis";
    }
    handleChange(_field);
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth
      required={field.required}
      disabled={disabled}
      error={!(field.error == "")}
    >
      <InputLabel id={fieldName}>{field.label}</InputLabel>
      <Select
        labelId={fieldName}
        name={fieldName}
        value={field.value??""}
        onChange={handleInputChange}
        label={field.label}
        onBlur={handleBlur}
      >
        {field.choices.map((choice, idx) => (
          <MenuItem
            onClick={() =>
              choice.value === field.value ? handleEmpty() : null
            }
            key={idx}
            value={choice.value}
          >
            {choice.label}
          </MenuItem>
        ))}
      </Select>
      {field.error || field.helperText ? (
        <FormHelperText component={Box} dangerouslySetInnerHTML={{__html: field.helperText??""}}>
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default FormLookupInput;
