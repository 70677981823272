import React, { useEffect, useRef } from "react";
import {
  CheckBoxInput,
  FormField,
  PhoneInput,
  TextInput,
  DropDownOptionSetInput,
  DropDownLookUpInput,
  RadioInput,
  MultilineInput,
  DateTimeInput,
  DateInput,
  NumberInput,
  AutoCompleteInput,
  CurrencyInput,
  FileInput,
  TimeInput,
  AddressInput,
  MultipleFilesInput,
  DropDownMultipleInput,
  EmailInput,
  FormFields,
  OptionSetInput,
  MultipleOptionSetInput,
} from "../../../types";
import FormTextInput from "../FormField/FormTextInput";
import FormDropDownInput from "../FormField/FormDropDownOptionSetInput";
import { Box } from "@mui/material";
import FormCheckBoxInput from "../FormField/FormCheckBoxInput";
import FormPhoneInput from "../FormField/FormPhoneInput";
import { Lookup } from "../../../data/common/types";
import FormDropDownOptionSetInput from "../FormField/FormDropDownOptionSetInput";
import FormDropDownLookUpInput from "../FormField/FormDropDownLookUpInput";
import FormRadioInput from "../FormField/FormRadioInput";
import FormMultilineInput from "../FormField/FormMultilineInput";
import FormDateTimeInput from "../FormField/FormDateTimeInput";
import FormDateInput from "../FormField/FormDateInput";
import FormNumberInput from "../FormField/FormNumberInput";
import FormAutoCompleteInput from "../FormField/FormAutoCompleteInput";
import FormCurrencyInput from "../FormField/FormCurrencyInput";
import FormFileInput from "../FormField/FormFileInput";
import FormComment from "../FormField/FormComment";
import FormTimeInput from "../FormField/FormTimeInput";
import FormAddressInput from "../FormField/FormAddressInput";
import FormMultipleFilesInput from "../FormField/FormMultipleFilesInput";
import FormDropDownMultipleOptionSetInput from "../FormField/FormDropDownMultipleOptionSetInput";
import FormEmailInput from "../FormField/FormEmailInput";
import dayjs, { Dayjs } from "dayjs";
import FormOptionSetInput from "../FormField/FormOptionSetInput";
import FormMultipleOptionSetInput from "../FormField/FormMultipleOptionSetInput";

type Props = {
  field: FormField;
  fields: FormFields;
  fieldName: string;
  handleChange: (key: string, field: FormField) => void;
  columns?: {
    count: number;
    minWidth: number;
  };
  disabledConditionResult?: boolean;
  updateRefs?: boolean;
};

function FormFieldWrapper({
  fieldName,
  field,
  fields,
  handleChange,
  disabledConditionResult,
  columns,
  updateRefs,
}: Props) {
  // const inputRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //  if (inputRef.current && updateRefs) {
  //    let _field = { ...field };
  //    _field.fieldRef = inputRef.current;
  //    handleChange(fieldName, _field);
  //  }
  //}, [inputRef]);

  function dateHandle(
    validator: Dayjs | ((fields: FormFields) => Dayjs | undefined) | undefined
  ): Dayjs | undefined {
    try {
      let date: Dayjs | undefined = undefined;
      if (validator !== undefined) {
        if (typeof validator === "function") {
          date = validator(fields);
        } else {
          date = validator;
        }
      }
      if (!dayjs.isDayjs(date)) return undefined;

      return date;
    } catch (error) {
      return undefined;
    }
  }

  function renderField(field: FormField) {
    switch (field.type) {
      case "number":
        return (
          <FormNumberInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: NumberInput) =>
              handleChange(fieldName, field)
            }
          />
        );
      case "text":
        return (
          <FormTextInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: TextInput) => handleChange(fieldName, field)}
          />
        );

      case "optionset":
        return (
          <FormDropDownOptionSetInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: DropDownOptionSetInput) =>
              handleChange(fieldName, field)
            }
          />
        );
      case "options":
        return (
          <FormOptionSetInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: OptionSetInput) =>
              handleChange(fieldName, field)
            }
          />
        );
      case "multipleoptions":
        return (
          <FormMultipleOptionSetInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: MultipleOptionSetInput) =>
              handleChange(fieldName, field)
            }
          />
        );
      case "multipleoptionset":
        return (
          <FormDropDownMultipleOptionSetInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: DropDownMultipleInput) =>
              handleChange(fieldName, field)
            }
          />
        );
      case "lookup":
        return (
          <FormDropDownLookUpInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: DropDownLookUpInput) =>
              handleChange(fieldName, field)
            }
          />
        );
      case "checkbox":
        return (
          <FormCheckBoxInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: CheckBoxInput) =>
              handleChange(fieldName, field)
            }
          />
        );
      case "phone":
        return (
          <FormPhoneInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: PhoneInput) => handleChange(fieldName, field)}
          />
        );
      case "email":
        return (
          <FormEmailInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: EmailInput) => handleChange(fieldName, field)}
          />
        );
      case "radio":
        return (
          <FormRadioInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: RadioInput) => handleChange(fieldName, field)}
          />
        );
      case "multiline":
        return (
          <FormMultilineInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: MultilineInput) =>
              handleChange(fieldName, field)
            }
          />
        );
      case "datetime":
        return (
          <FormDateTimeInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: DateTimeInput) =>
              handleChange(fieldName, field)
            }
            minDate={dateHandle(field.minDate)}
            maxDate={dateHandle(field.maxDate)}
          />
        );
      case "date":
        return (
          <FormDateInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: DateInput) => handleChange(fieldName, field)}
            minDate={dateHandle(field.minDate)}
            maxDate={dateHandle(field.maxDate)}
          />
        );
      case "autocomplete":
        return (
          <FormAutoCompleteInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: AutoCompleteInput) =>
              handleChange(fieldName, field)
            }
          />
        );
      case "currency":
        return (
          <FormCurrencyInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: CurrencyInput) =>
              handleChange(fieldName, field)
            }
          />
        );
      case "file":
        return (
          <FormFileInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: FileInput) => handleChange(fieldName, field)}
          />
        );
      case "multiplefiles":
        return (
          <FormMultipleFilesInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: MultipleFilesInput) =>
              handleChange(fieldName, field)
            }
          />
        );
      case "time":
        return (
          <FormTimeInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: TimeInput) => handleChange(fieldName, field)}
          />
        );
      case "comment":
        return <FormComment field={field} fieldName={fieldName} />;
      case "address":
        return (
          <FormAddressInput
            disabled={disabledConditionResult ? disabledConditionResult : false}
            field={field}
            fieldName={fieldName}
            handleChange={(field: AddressInput) =>
              handleChange(fieldName, field)
            }
          />
        );
      default:
        return null;
    }
  }
  return (
    <Box
      id={"s-form-" + fieldName}
      minWidth={columns ? `${columns.minWidth}px` : "250px"}
      maxWidth={"100%"}
      display="flex"
      alignItems="center"
      flexBasis={
        field.fillLine || !columns
          ? "100%"
          : `calc((100% / ${columns.count} - ${16}px / ${columns.count} * ${
              columns.count - 1
            }) * ${field.span ? field.span : 1})`
      }
      flexGrow={1}
    >
      {renderField(field)}
    </Box>
  );
}

function moviePropsAreEqual(prev: Props, next: Props) {
  if (
    (prev.field.type === "date" && next.field.type === "date") ||
    (prev.field.type === "datetime" && next.field.type === "datetime")
  ) {
    if (
      typeof prev.field.minDate === "function" &&
      typeof next.field.minDate === "function"
    ) {
      if (prev.field.minDate(prev.fields) !== next.field.minDate(next.fields)) {
        return false;
      }
    }
    if (
      typeof prev.field.maxDate === "function" &&
      typeof next.field.maxDate === "function"
    ) {
      if (prev.field.maxDate(prev.fields) !== next.field.maxDate(next.fields)) {
        return false;
      }
    }
  }

  if (
    (prev.field.type === "multiplefiles" &&
      next.field.type === "multiplefiles") ||
    (prev.field.type === "multipleoptionset" &&
      next.field.type === "multipleoptionset") ||
      (prev.field.type === "multipleoptions" &&
      next.field.type === "multipleoptions")
  ) {
    if (prev.field.value.length !== next.field.value.length) return false;
  }

  let result =
    prev.field.value === next.field.value &&
    prev.field.error === next.field.error &&
    prev.field.helperText === next.field.helperText &&
    prev.disabledConditionResult === next.disabledConditionResult &&
    prev.columns === next.columns;
  return result;
}

const MemorizedFormFieldWrapper = React.memo(
  FormFieldWrapper,
  moviePropsAreEqual
);

export default MemorizedFormFieldWrapper;
