import React from 'react'
import { ActivityRow } from '../../../../data/Activity/models';
import { ActivityTypes } from '../../../../data/ActivityType/models';
import ActivityCard from './ActivityCard';
import { Box } from '@mui/material';

type Props = {
    activites: ActivityRow[];
    activityTypes: ActivityTypes;
    isEditable: boolean;
  };

const ActivityCards = ({activites, activityTypes, isEditable} : Props) => {
  return (
    <Box display="grid" gap="16px">
            {activites.map((activity) => {
              const activityType = activity._vdl_typedactivite_value
                ? activityTypes[activity._vdl_typedactivite_value]
                : null;

              if (activityType) {
                return (
                  <ActivityCard
                    key={activity.vdl_activite_evenementid}
                    activity={activity}
                    activityType={
                        activityTypes[activity._vdl_typedactivite_value]
                    }
                    isEditable={isEditable}
                  />
                );
              }
            })}
          </Box>
  )
}

export default ActivityCards